/* eslint-disable no-nested-ternary */
import moment from 'moment';
import { CADENCE } from '@odaia/domain/src';

export const getTrendValue = (historic, current) => ({
  status:
    (current - historic) / historic > 0
      ? 2
      : (current - historic) / historic < 0
      ? 0
      : 1,
  value: historic
    ? (current - historic) / historic
    : !historic && !current
    ? 0
    : null,
  type: !historic && current > historic ? 'DIRECTION_ONLY' : 'TREND_CHANGE',
});

export const formatTimestamp = ({ timestamp, cadence, nextWeek = true }) => {
  if (!timestamp) return '';
  if (cadence === CADENCE.DAY) {
    return moment(timestamp).format('MMM D');
  }
  if (cadence === CADENCE.WEEK) {
    if (nextWeek) {
      const deltaFromFriday = 5 - moment(timestamp).day();
      const endDayNextWeek = moment(timestamp).add(deltaFromFriday + 7, 'day');
      const startDayNextWeek = moment(timestamp).add(
        3 + deltaFromFriday,
        'day'
      );
      if (startDayNextWeek.format('MMM') !== endDayNextWeek.format('MMM')) {
        return `${startDayNextWeek.format('MMM')} ${startDayNextWeek.format(
          'D'
        )}-${endDayNextWeek.format('MMM')} ${endDayNextWeek.format('D')}`;
      }
      return `${startDayNextWeek.format('MMM')} ${startDayNextWeek.format(
        'D'
      )}-${endDayNextWeek.format('D')}`;
    }
    const endDay = moment(timestamp).format('D');
    const startDay = moment(timestamp).subtract(4, 'day').format('MMM D');
    return `${startDay}-${endDay}`;
  }
  if (cadence === CADENCE.MONTH) {
    return moment(timestamp).format('MMM YYYY');
  }
  if (cadence === CADENCE.QUARTER) {
    const quarter = moment(timestamp).utc().quarter();
    return `Q${quarter}, ${moment(timestamp).format('YYYY')}`;
  }
  if (cadence === CADENCE.PREV_QUARTER) {
    const prevQuarterTimestamp = moment(
      moment(timestamp).subtract(3, 'months')
    );
    return `Q${prevQuarterTimestamp.utc().quarter()}, ${moment(
      prevQuarterTimestamp
    ).format('YYYY')}`;
  }
  return timestamp;
};

export const formatChartLabels = (cadence, labels) => {
  if (cadence === CADENCE.WEEK) {
    return labels?.map((timestamp) => moment(timestamp).format('MM/DD'));
  }
  if (cadence === CADENCE.MONTH) {
    return labels?.map((timestamp) => moment(timestamp).format('MMM YYYY'));
  }
  if (cadence === CADENCE.QUARTER) {
    return labels?.map((timestamp) => {
      const quater = moment(timestamp).utc().quarter();
      return quater === 1
        ? `Q${quater} ${moment(timestamp).format('YYYY')}`
        : `Q${quater}`;
    });
  }
  return labels;
};

export const formatLastContactedDate = (lastContactedDate) => {
  if (!lastContactedDate) return 'Never Contacted';
  return moment(lastContactedDate).format('MMM D, YYYY');
};
