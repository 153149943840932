import {
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, Button, Drawer, styled, Typography } from '@mui/material';
import {
  GridColumnMenuProps,
  GridSortDirection,
  GridSortItem,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { AdditionalProps, Table } from '@odaia/shared-components/src/table';
import { EntityError } from './EntityError';
import { EntityOverviewContext } from '../data/entityOverviewContext';
import {
  filterRowsBySearchValue,
  isEmptyItem,
  sortWithNullValuesAtEnd,
} from './tableHelpers';
import { EntityOverviewDrawer } from './drawer';
import { findRowDataInfo } from './drawer/drawerHelpers';
import {
  trackEntityOverviewColumnSorting,
  trackEntityOverviewEntitySelected,
} from '../../../trackers/mixpanel';
import { EntityTableHeader } from './EntityTableHeader';
import { EntityTableCell } from './EntityTableCell';
import {
  ENTITY_TYPES_ENUM,
  ERROR_TYPES_ENUM,
  HEADER_LABEL_DEFAULT_SIZE,
  SCORE_COLUMN_WIDTH,
  TABLE_HEADER_TYPES_ENUM,
  SEGMENT_COLUMN_WIDTH,
  BADGE_COLUMN_WIDTH,
  TREND_STATUSES_ENUM,
  LABEL_COLUMN_WIDTH,
} from '../constants';

const TABLE_PAGE_SIZE = 25;

const TableWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  width: '100%',
  height: '100%',
}));

const getCellValue = (type, rowData) => {
  switch (type) {
    case TABLE_HEADER_TYPES_ENUM.SCORE:
      return rowData?.maptualFractionalScore;
    case TABLE_HEADER_TYPES_ENUM.LABEL:
      return rowData?.name;
    case TABLE_HEADER_TYPES_ENUM.BEHAVIOURAL_SEGMENT:
      return rowData?.map((segment) => segment.displayName).join(', ');
    case TABLE_HEADER_TYPES_ENUM.CUSTOMER_SEGMENT:
      return rowData?.join(', ');
    case TABLE_HEADER_TYPES_ENUM.NUMERICAL_BADGE: {
      if (!rowData || !rowData.length) {
        return null;
      }

      const regex = /(?<number>\d+)/;
      const match = rowData[0]?.match(regex);

      if (match?.groups?.number) {
        return Number(match.groups.number);
      }

      return null;
    }
    default:
      return Number(rowData[rowData.length - 1]);
  }
};

export const buildRows = (headers, items) => {
  const rows = [];

  items?.forEach((item, index) => {
    if (!item.every(isEmptyItem)) {
      const headerToItemMap = {};

      for (let i = 0; i < headers?.length; i++) {
        headerToItemMap[i] = item[i];
      }

      headerToItemMap.id = index;
      rows.push(headerToItemMap);
    }
  });

  return rows;
};

const getSortingPriority = (status: TREND_STATUSES_ENUM): number => {
  switch (status) {
    case TREND_STATUSES_ENUM.CANNOT_PREDICT:
      return 5;
    case TREND_STATUSES_ENUM.DECREASING_OUT_OF_RANGE:
      return 4;
    case TREND_STATUSES_ENUM.DECREASING:
      return 3;
    case TREND_STATUSES_ENUM.NO_CHANGE:
      return 2;
    case TREND_STATUSES_ENUM.INCREASING_OUT_OF_RANGE:
      return 1;
    case TREND_STATUSES_ENUM.INCREASING:
      return 0;
    default:
      return 2;
  }
};

export const buildColumns = (headers) => {
  const columns = [];

  headers?.forEach(
    (
      { label, subLabel, type, graph, chart, tooltipText, tooltipList },
      index
    ) => {
      const { titlePrefix: chartTitlePrefix, labels: chartLabels } =
        chart || {};

      let headerColumn = {
        field: `${index}`,
        maxHeight: '100%',
        renderHeader: () =>
          EntityTableHeader({
            label,
            subLabel,
            type,
            tooltipText,
            tooltipList,
          }),
        label,
        sortingOrder: ['desc', 'asc', null],
        valueGetter: ({ data }) => getCellValue(type, data),
        renderCell: ({ row }) =>
          EntityTableCell({
            row,
            cell: row[index],
            type,
            graph,
            chartLabels,
            label,
            subLabel,
            chartTitlePrefix,
          }),
      };

      const labelWidth = label.length > HEADER_LABEL_DEFAULT_SIZE ? 180 : 116;
      switch (type) {
        case TABLE_HEADER_TYPES_ENUM.SCORE:
          headerColumn = {
            ...headerColumn,
            minWidth: SCORE_COLUMN_WIDTH,
            sortComparator: (value1, value2) => value1 - value2,
          };
          break;

        case TABLE_HEADER_TYPES_ENUM.LABEL:
          headerColumn = {
            ...headerColumn,
            width: LABEL_COLUMN_WIDTH,
            minWidth: LABEL_COLUMN_WIDTH,
            sortComparator: (value1, value2) => value1.localeCompare(value2),
          };
          break;
        case TABLE_HEADER_TYPES_ENUM.CUSTOMER_SEGMENT:
        case TABLE_HEADER_TYPES_ENUM.BEHAVIOURAL_SEGMENT:
          headerColumn = {
            ...headerColumn,
            sortComparator: (value1, value2) => value1.localeCompare(value2),
            width: SEGMENT_COLUMN_WIDTH,
            minWidth: SEGMENT_COLUMN_WIDTH,
          };
          break;

        case TABLE_HEADER_TYPES_ENUM.NUMERICAL_BADGE:
          headerColumn = {
            ...headerColumn,
            sortComparator: (v1, v2, cellParams1) => {
              const sortModel = cellParams1.api.getSortModel();
              const sortColumn = sortModel.find(
                (sm: GridSortItem) => sm.field === cellParams1.field
              );

              return sortWithNullValuesAtEnd(v1, v2, sortColumn?.sort);
            },
            width: BADGE_COLUMN_WIDTH,
            minWidth: BADGE_COLUMN_WIDTH,
          };
          break;
        case TABLE_HEADER_TYPES_ENUM.DRAWER:
          headerColumn = {
            ...headerColumn,
            width: 50,
            sortable: false,
          };
          break;
        default:
          headerColumn = {
            ...headerColumn,
            width: labelWidth,
            minWidth: labelWidth,
            sortComparator: (value1, value2, cellParams1, cellParams2) => {
              const sortModel = cellParams1.api.getSortModel();
              if (!sortModel) {
                return null;
              }
              const row1 = cellParams1.api.getRow(cellParams1.id);
              const cell1 = row1[cellParams1.field];

              const row2 = cellParams2.api.getRow(cellParams2.id);
              const cell2 = row2[cellParams2.field];

              const sortItem = sortModel[0];

              if (sortItem.mode === SortMode.TREND) {
                const trendStatusPriority1 = getSortingPriority(
                  cell1.trend?.status
                );
                const trendStatusPriority2 = getSortingPriority(
                  cell2.trend?.status
                );

                return (
                  trendStatusPriority2 - trendStatusPriority1 ||
                  (cell1.trend?.value ?? 0) - (cell2.trend?.value ?? 0)
                );
              }

              return value1 - value2;
            },
          };
          break;
      }

      columns.push(headerColumn);
    }
  );

  return columns;
};

const StyledDatagrid = styled(Table, {
  shouldForwardProp: (prop) => prop !== 'dynamicRowStyles',
})(({ theme: { themeColors }, dynamicRowStyles }) => ({
  ...dynamicRowStyles,
  border: '0',
  boxShadow: 'none',

  '.MuiDataGrid-root': {
    overflow: 'visible',
  },

  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },

  '.MuiDataGrid-iconSeparator': {
    display: 'none',
  },

  '.MuiDataGrid-columnHeaders': {
    color: themeColors.neutral80,
    borderColor: themeColors.borderLowContrast,
    height: 'fit-content',
    maxHeight: '100%',
  },
  '.MuiDataGrid-container--top [role="row"]': {
    background: themeColors.mainBackground,
  },
  '.MuiDataGrid-iconButtonContainer': {
    width: 28,
  },

  '.MuiDataGrid-columnHeaderDraggableContainer': {
    alignItems: 'end',
  },
  '.MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
  },
  '.MuiDataGrid-row--borderBottom': {
    '.MuiDataGrid-columnHeader, .MuiDataGrid-filler': {
      borderBottom: `1px solid ${themeColors.borderLowContrast}`,
    },
  },
  '.MuiDataGrid-virtualScroller': {
    '&::-webkit-scrollbar': {
      width: 14,
      height: 14,
    },
    '&::-webkit-scrollbar-track': {
      background: themeColors.generalScrollbarTrackColor,
      border: 'none',
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-corner': {
      background: themeColors.generalScrollbarTrackColor,
    },
    '&::-webkit-scrollbar-thumb': {
      background: themeColors.neutral30,
      borderRadius: 100,
      border: `3px solid ${themeColors.generalScrollbarTrackColor}`,
      '&:hover': {
        background: themeColors.neutral80,
      },
    },
  },

  '.MuiDataGrid-footerContainer': {
    border: 'none',
    justifyContent: 'left',
  },
  '.MuiTablePagination-spacer': {
    width: 0,
    flex: 'none',
  },
  '.MuiDataGrid-row:hover': {
    background: 'none',
    '>.MuiDataGrid-cell.MuiDataGrid-cell--pinnedLeft, >.MuiDataGrid-cell.MuiDataGrid-cell--pinnedRight':
      {
        background: themeColors.mainBackground,
      },
  },
  '.MuiDataGrid-cell--pinnedLeft, .MuiDataGrid-columnHeader--pinnedLeft, .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-columnHeader--pinnedRight':
    {
      background: themeColors.mainBackground,
      boxShadow: 'none',
      borderLeft: 'none',
      borderRight: 'none',
    },
  '.MuiDataGrid-columnHeaderTitleContainer': {
    gap: 0,
    paddingBottom: 6,
    display: 'grid',
    gridTemplateColumns: '1fr 28px',
  },
  '.MuiDataGrid-columnHeader': {
    '.MuiDataGrid-menuIcon': {
      width: 0,
      margin: 0,
    },
    '&:hover': {
      '.MuiDataGrid-menuIcon': {
        width: 0,
        margin: 0,
      },
    },
  },
}));

const StyledDrawer = styled(Drawer)(({ theme: { themeColors } }) => ({
  '.MuiDrawer-paper': {
    backgroundColor: themeColors.modalBackgroundColor,
  },
})) as typeof Drawer;

const ErrorContainer = styled(Box)({
  marginTop: 16,
});

const DataGridColumnsInitialState = {
  pagination: {
    paginationModel: { pageSize: TABLE_PAGE_SIZE, page: 0 },
  },
};

enum SortMode {
  VALUE = 'value',
  TREND = 'trend',
}

interface CustomGridSortItem extends GridSortItem {
  mode: SortMode;
}
interface CustomColumnMenuProps extends GridColumnMenuProps {
  headers: object[];
  handleSortModel: (mode: object) => void;
  currentModel: CustomGridSortItem;
}

const CustomColumnMenuWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  fontSize: 16,
  color: themeColors.tertiaryColor,
  '.MuiButtonBase-root': {
    color: themeColors.menuTextColor,
    fontSize: 16,
    fontWeight: 'normal',
    display: 'flex',
    gap: 8,
    padding: 8,
    svg: {
      width: 20,
      height: 20,
      fill: themeColors.listIconColor,
    },
    '&.active': {
      background: themeColors.buttonActiveBackgroundColor,
      color: themeColors.buttonActiveContentColor,
    },
  },
}));

const ColumnMenuButton = styled(Button)({
  width: '100%',
  padding: '8px 16px',
  textAlign: 'left',
  justifyContent: 'left',
});

interface CustomMenuSortOption {
  label: (label: string) => string;
  sort: GridSortDirection;
  mode: SortMode;
  icon: ReactElement;
}

const CUSTOM_MENU_OPTIONS_SETTINGS: CustomMenuSortOption[] = [
  {
    label: (label: string) => `${label} (Descending)`,
    sort: 'desc',
    mode: SortMode.VALUE,
    icon: <ArrowDownward />,
  },
  {
    label: (label: string) => `${label} (Ascending)`,
    sort: 'asc',
    mode: SortMode.VALUE,
    icon: <ArrowUpward />,
  },
  {
    label: (label: string) => `${label}, Growth (Descending)`,
    sort: 'desc',
    mode: SortMode.TREND,
    icon: <ArrowDownward />,
  },
  {
    label: (label: string) => `${label}, Growth (Ascending)`,
    sort: 'asc',
    mode: SortMode.TREND,
    icon: <ArrowUpward />,
  },
];

const TREND_SORTING_BLACKLIST = [
  TABLE_HEADER_TYPES_ENUM.CUSTOMER_SEGMENT,
  TABLE_HEADER_TYPES_ENUM.BEHAVIOURAL_SEGMENT,
  TABLE_HEADER_TYPES_ENUM.LABEL,
  TABLE_HEADER_TYPES_ENUM.SCORE,
  TABLE_HEADER_TYPES_ENUM.NUMERICAL_BADGE,
];

const CustomColumnMenu = ({
  headers,
  handleSortModel,
  currentModel,
  colDef,
  hideMenu,
}: CustomColumnMenuProps) => {
  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    sort: GridSortDirection,
    mode: SortMode
  ) => {
    handleSortModel({
      sort,
      mode,
      field: colDef.field,
    });
    hideMenu(event);
  };

  const { label, type } = headers?.[colDef.field] ?? {};

  const sortOptions = TREND_SORTING_BLACKLIST.includes(type)
    ? CUSTOM_MENU_OPTIONS_SETTINGS.slice(0, 2)
    : CUSTOM_MENU_OPTIONS_SETTINGS;

  return (
    <CustomColumnMenuWrapper data-testid={`${colDef.field}-sorting-menu`}>
      <Typography variant="body2" mb={1}>
        Sort by
      </Typography>
      {sortOptions.map((option) => (
        <ColumnMenuButton
          onClick={(event) => handleClick(event, option.sort, option.mode)}
          key={`${option.sort}-${option.mode}-${colDef.field}`}
          className={
            currentModel?.sort === option.sort &&
            currentModel?.mode === option.mode &&
            currentModel?.field === colDef.field
              ? 'active'
              : ''
          }
          data-testid={`${option.sort}-${option.mode}`}
        >
          {option.icon}
          {option.label(label)}
        </ColumnMenuButton>
      ))}
    </CustomColumnMenuWrapper>
  );
};

export const EntityTable = () => {
  const { isDataFetching, data, entityType, searchValue, setErrorType } =
    useContext(EntityOverviewContext);
  const [isTableLoading, setIsTableLoading] = useState(true);

  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();

  const [nameColumnIndex, setNameColumnIndex] = useState();
  const [drawerOpenerColumnIndex, setDrawerOpenerColumnIndex] = useState();
  const [dynamicRowStyles, setDynamicRowStyles] = useState();
  const [dynamicRowStylesLoading, setDynamicRowStylesLoading] = useState(true);

  const tableApiRef = useGridApiRef();

  useEffect(() => {
    if (
      !isDataFetching &&
      data &&
      data.headers?.length > 0 &&
      data.items?.length > 0
    ) {
      setIsTableLoading(true);

      const { headers, items } = data;
      const tempColumns = buildColumns(headers);
      const tempRows = buildRows(headers, items);

      setColumns(tempColumns);

      if (searchValue) {
        const filteredRowsBySearchValue = filterRowsBySearchValue(
          data.headers,
          tempRows,
          searchValue
        );
        if (filteredRowsBySearchValue.length === 0) {
          setErrorType(ERROR_TYPES_ENUM.NO_SEARCH_DATA);
        }
        setRows(filteredRowsBySearchValue);
      } else {
        setErrorType(null);
        setRows(tempRows);
      }
    }
  }, [isDataFetching, data, searchValue]);

  useEffect(() => {
    if (rows?.length > 0 && columns?.length > 0 && entityType) {
      setIsTableLoading(false);

      Object.entries(rows[0]).forEach(([key, value]) => {
        if (value?.data && Object.keys(value.data).includes('name')) {
          setNameColumnIndex(key);
        }

        if (value?.data && value.data.drawerOpener) {
          setDrawerOpenerColumnIndex(key);
        }
      });
    }
  }, [rows, columns, entityType]);

  useEffect(() => {
    if (
      nameColumnIndex !== undefined &&
      drawerOpenerColumnIndex !== undefined
    ) {
      setDynamicRowStyles({
        '.MuiDataGrid-row': {
          '&:hover, &.Mui-hovered': {
            background: 'none',
          },

          [`> [data-colindex="${nameColumnIndex}"]:hover`]: {
            textDecoration: 'underline',
            cursor: 'pointer',
          },

          [`> [data-colindex="${drawerOpenerColumnIndex}"]:hover`]: {
            cursor: 'pointer',
          },
        },
      });

      setDynamicRowStylesLoading(false);
    }
  }, [nameColumnIndex, drawerOpenerColumnIndex]);

  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState();

  const onCellClick = (params) => {
    if (
      params.field === nameColumnIndex ||
      params.field === drawerOpenerColumnIndex
    ) {
      const name = findRowDataInfo(Object.entries(params?.row), 'name');
      const address = findRowDataInfo(Object.entries(params?.row), 'address');
      const id = findRowDataInfo(Object.entries(params?.row), 'id');

      trackEntityOverviewEntitySelected(name, entityType);
      setDrawerData({ name, address, id, entityType });
    }
  };

  const [sortModel, setSortModel] = useState<CustomGridSortItem[]>([
    {
      field: '0',
      sort: 'desc',
      mode: SortMode.VALUE,
    },
  ]);

  useEffect(() => {
    if (drawerData) {
      setShowDrawer(true);
    }
  }, [drawerData]);

  const noTableData = data?.items?.length === 0;
  const noRowData = rows?.length === 0;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: TABLE_PAGE_SIZE,
    page: 0,
  });

  if (noTableData || noRowData) {
    return (
      <ErrorContainer>
        <EntityError />
      </ErrorContainer>
    );
  }

  const disableVirtualization = import.meta.env.VITE_TEST_ENV === 'true';

  const handleSortModel = (model: object) => {
    setSortModel([model]);

    if (data?.headers[model[0]?.field]?.label) {
      trackEntityOverviewColumnSorting(
        data.headers[model[0].field].label,
        entityType
      );
    }
  };

  return (
    !isDataFetching &&
    !isTableLoading &&
    !dynamicRowStylesLoading && (
      <>
        <TableWrapper data-testid="entity-overview-table">
          <StyledDatagrid
            columns={columns || []}
            rows={rows || []}
            onCellClick={onCellClick}
            onColumnHeaderClick={(params, event) => {
              if (!params.colDef.sortable) return;
              // eslint-disable-next-line no-param-reassign
              event.defaultMuiPrevented = true;
              tableApiRef.current.showColumnMenu(params.field);
            }}
            stickyColumns={{ right: [`${columns.length - 1}`] }}
            loading={isDataFetching || !rows || !columns}
            paginationEnabled
            onFetchNext={(model) => setPaginationModel(model.page)}
            dynamicRowStyles={dynamicRowStyles}
            additionalProps={
              {
                initialState: {
                  ...DataGridColumnsInitialState,
                  sorting: entityType !== ENTITY_TYPES_ENUM.DIRECT_ACCOUNT && {
                    sortModel: [
                      { field: '0', sort: 'desc', mode: SortMode.VALUE },
                    ],
                  },
                },
                disableMultipleSelection: true,
                disableSelectionOnClick: true,
                disableRowSelectionOnClick: true,
                apiRef: tableApiRef,
                rowHeight: 72,
                columnHeaderHeight: 65,
                rowBuffer: columns?.length || 0,
                paginationModel,
                disableColumnReorder: true,
                disableColumnResize: true,
                autosizeOnMount: true,
                disableVirtualization,
                sortModel,
                slots: {
                  columnMenu: (props: GridColumnMenuProps) =>
                    CustomColumnMenu({
                      headers: data?.headers,
                      handleSortModel,
                      currentModel: sortModel?.[0] ?? {},
                      ...props,
                    }),
                  columnMenuIcon: () => null,
                },
              } as AdditionalProps
            }
          />
        </TableWrapper>
        <StyledDrawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          PaperProps={{
            elevation: 0,
          }}
          ModalProps={{
            style: {
              position: 'absolute',
            },
            slotProps: {
              backdrop: {
                style: {
                  position: 'fixed',
                },
              },
            },
          }}
        >
          <EntityOverviewDrawer
            drawerData={drawerData}
            setDrawerVisibility={setShowDrawer}
          />
        </StyledDrawer>
      </>
    )
  );
};
