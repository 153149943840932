import { CallPlanRow } from "./row/callPlanRow";
import { RowComponentProps } from "./callPlanTable";

export const RowProvider = ({
  objectiveId,
  onRowClick,
  cadence,
  row,
  rank,
  isLoading,
}: RowComponentProps) => {
  // TODO need to get correct data here
  const crmData = [];

  return (
    <CallPlanRow
      objectiveId={objectiveId}
      cadence={cadence}
      row={row}
      isLoading={isLoading}
      rank={rank}
      onRowClick={onRowClick}
      crmData={crmData}
      isCrmDataLoading={false}
      entitySegments={row.segments || []}
      isSegmentsLoading={false}
    />
  );
};
