import { useContext, useEffect, useState } from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Box, styled } from '@mui/material';
import { TerritoryOverviewContext } from '../territoryOverviewContext';
import {
  territoryOverviewDataExists,
  calculateLabelColumnWidth,
  isEmptyItem,
} from '../utils';
import { TableHeader } from './TableHeader';
import { TableCell } from './TableCell';
import { SkeletonBars } from '../../../components/generic/skeletonBars';

const TableWrapper = styled(Box)(() => ({
  overflow: 'hidden',
  height: '100%',
  '>.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor':
    {
      paddingTop: 0,
    },
}));

const StyledDatagrid = styled(DataGridPremium)(
  ({ theme: { themeColors } }) => ({
    '.MuiDataGrid-root': {
      overflow: 'visible',
    },
    '.MuiDataGrid-columnHeader': {
      background: themeColors.mainBackground,
    },
    '.MuiDataGrid-columnHeaders': {
      color: '#C2CAD6',
      borderColor: themeColors.borderLowContrast,
    },
    '.MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${themeColors.borderLowContrast}`,
      padding: 10,
      display: 'flex',
    },
    '.MuiDataGrid-cell--pinnedLeft, .MuiDataGrid-columnHeader--pinnedLeft, .MuiDataGrid-cell--pinnedRight, .MuiDataGrid-columnHeader--pinnedRight':
      {
        background: themeColors.mainBackground,
        boxShadow: 'none',
        borderLeft: 'none',
        borderRight: 'none',
      },
    '.MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader': {
      borderBottom: `1px solid ${themeColors.borderLowContrast}`,
    },
    '.MuiDataGrid-row': {
      fontWeight: 500,

      '&:hover, &.Mui-hovered': {
        background: 'none',
        '>.MuiDataGrid-cell.MuiDataGrid-cell--pinnedLeft, >.MuiDataGrid-cell.MuiDataGrid-cell--pinnedRight':
          {
            background: themeColors.mainBackground,
          },
      },
    },
    '.MuiDataGrid-iconSeparator': {
      color: themeColors.secondaryTextColor,
      display: 'none',
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
    '.MuiDataGrid-cell--textLeft': {
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: 'flex-start',
    },
    '.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
      overflow: 'visible',
      alignItems: 'center',
    },
    borderColor: themeColors.borderLowContrast,
    border: '0',
    boxShadow: 'none',
    paddingTop: 20,
    '.MuiDataGrid-virtualScroller': {
      '&::-webkit-scrollbar': {
        width: 14,
        height: 14,
      },
      '&::-webkit-scrollbar-track': {
        background: '#1F232C',
        border: 'none',
        boxShadow: 'none',
      },
      '&::-webkit-scrollbar-corner': {
        background: '#1F232C',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#455368',
        borderRadius: 100,
        border: `3px solid #1F232C`,
        '&:hover': {
          background: '#C2CAD6',
        },
      },
    },
    '.MuiDataGrid-footerContainer': {
      border: 'none',
      justifyContent: 'left',
    },
  })
);

const DataGridColumnsInitialState = {
  pagination: {
    paginationModel: { pageSize: 25, page: 0 },
  },
};
const DataGridOptions = {
  padding: 'dense',
};

export const getCellValue = (type, rowData) => {
  if (!rowData || (Array.isArray(rowData) && rowData.length === 0)) {
    return undefined;
  }

  if (type === 'label') {
    return rowData;
  }

  return Number(rowData[rowData.length - 1]);
};

interface HeadersMaxMinWidth {
  minWidth: number;
  maxWidth: string | number;
}

export const getHeadersMaxMinWidth = (items, type): HeadersMaxMinWidth => {
  let maxWidth: string | number = 300;
  let minWidth: number = 140;

  switch (type) {
    case 'label':
      minWidth = calculateLabelColumnWidth(items);
      maxWidth = '20rem';
      return {
        minWidth,
        maxWidth,
      };

    case 'externalLink':
      minWidth = 60;
      maxWidth = 60;
      return {
        minWidth,
        maxWidth,
      };

    default:
      return {
        minWidth,
        maxWidth,
      };
  }
};

export const Table = () => {
  const { data, isDataLoading, selectedTimeframeID, isMetadataLoading } =
    useContext(TerritoryOverviewContext);

  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();

  const [rowData, setRowData] = useState();
  const [colData, setColData] = useState();

  useEffect(() => {
    const hasTerritoryOverviewData = territoryOverviewDataExists(
      data,
      selectedTimeframeID
    );

    if (hasTerritoryOverviewData) {
      const tableHeaders = data?.headers;

      const tempRows = [];
      const tempColumns = [];

      data?.items.forEach((item, index) => {
        if (!item.every(isEmptyItem)) {
          const response = {};
          for (let i = 0; i < tableHeaders?.length; i++) {
            response[i] = item[i];
          }
          response.id = index;
          tempRows.push(response);
        }
      });

      tableHeaders?.forEach(
        ({ label, subLabel, type, graph, chartLabels }, index) => {
          const { minWidth, maxWidth } = getHeadersMaxMinWidth(
            data.items,
            type
          );

          tempColumns.push({
            field: `${index}`,
            minWidth,
            maxWidth,
            justifyContent: 'center',
            renderHeader: () => TableHeader({ label, subLabel }),
            flex: 1,
            sortable: type !== 'externalLink',
            sortingOrder: ['desc', 'asc', null],
            valueGetter: ({ data }) => getCellValue(type, data),
            renderCell: ({ row }) =>
              TableCell({
                row: row[index],
                region: row[0]?.data,
                type,
                graph,
                chartLabels,
                metricType: subLabel,
                modalGraphTitle: label,
              }),
          });
        }
      );

      setRowData(tempRows);
      setColData(tempColumns);
    }

    return () => {
      setRowData();
      setColData();
    };
  }, [data, selectedTimeframeID]);

  useEffect(() => {
    if (rowData && colData) {
      setRows(rowData);
      setColumns(colData);
    }

    return () => {
      setRows();
      setColumns();
    };
  }, [rowData, colData]);

  const pinnedRightColumnIndex =
    columns?.length > 2 ? columns.length - 1 : undefined;

  return isDataLoading || isDataLoading || isMetadataLoading ? (
    <SkeletonBars />
  ) : (
    <TableWrapper>
      <StyledDatagrid
        columns={columns || []}
        rows={rows || []}
        disableSelectionOnClick
        disableColumnMenu
        rowHeight={50}
        disableMultipleSelection
        loading={isDataLoading || !rows || !columns}
        initialState={{
          ...DataGridColumnsInitialState,
          pinnedColumns: { left: ['0'], right: [`${pinnedRightColumnIndex}`] },
        }}
        options={DataGridOptions}
        disableRowSelectionOnClick
        pagination
        rowBuffer={columns?.length || 0}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
      />
    </TableWrapper>
  );
};
