import { useContext, useMemo } from 'react';
import { MapViewCore } from '@odaia/shared-components/src/map/mapViewCore';
import { useSnackbar } from '@odaia/ui/src/components/snackbar';
import { MapViewCoreContext } from '@odaia/shared-components/src/contexts/mapViewCoreContext';
import { FieldContext } from '../fieldContext';
import { EntityPopover } from './entityPopover';
import { AppContext } from '../../../appContext';
import useFeatureToggles from '../../../../../hooks/useFeatureToggles';
import { useUserCreatedLists } from '../../../../fieldview/useUserCreatedLists';

export const MapProvider = ({
  mergedMaptuaList,
  objective,
  filteredList,
  isLoading,
  onEntityDetailsClick,
  isSuggestedEntitiesFilterActive,
  powerScoreDisplay,
  hideMap,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { profile } = useContext(AppContext);
  const {
    project,
    maptualListMetadata,
    isMaptualListLoading,
    isMaptualListsLoading,
    maptualListObjectives,
  } = useContext(FieldContext);

  const objectiveIndex = maptualListObjectives
    ? maptualListObjectives.findIndex((o) => o.id === objective?.id)
    : 0;

  const productLineId = project?.metadata?.productLineId;
  const projectId = project?.metadata?.projectId;

  const featureToggles = useFeatureToggles();
  const canUserFilterMapView = true;
  const voiceEnabled =
    featureToggles(profile.userGroup, 'maptualVoiceAssistant') &&
    profile?.role?.type === 'ADMIN';

  const { userCreatedLists } = useUserCreatedLists({
    userId: profile?.userId,
  });

  const productLineUserCreatedLists = userCreatedLists?.[productLineId];

  const anonymize = voiceEnabled;

  const maptualList = useMemo(() => {
    const maptualListTemp = structuredClone(mergedMaptuaList);
    if (mergedMaptuaList?.list?.items) {
      maptualListTemp.list.items = maptualListTemp?.list?.items.filter(
        (i) => i.address
      );
    }
    return maptualListTemp;
  }, [mergedMaptuaList]);

  const filteredMaptualList = useMemo(() => {
    const filteredlListTemp = structuredClone(filteredList);
    if (filteredList?.list?.items) {
      filteredlListTemp.list.items = filteredlListTemp?.list?.items.filter(
        (i) => i.address
      );
    }
    return filteredlListTemp;
  }, [filteredList]);

  const mapContextValues = useMemo(
    () => ({
      projectId,
      productLineId,
      maptualListMetadata,
      maptualList,
      isMaptualListLoading: isMaptualListLoading || isLoading,
      isMaptualListsLoading,
      maptualListObjectives,
      objective,
      objectiveIndex,
      enqueueSnackbar,
      canUserFilterMapView,
      productLineUserCreatedLists,
      voiceEnabled,
      anonymize,
      filteredMaptualList,
      isSuggestedEntitiesFilterActive,
      powerScoreDisplay,
      hideMap,
    }),
    [
      projectId,
      productLineId,
      maptualListMetadata,
      maptualList,
      isMaptualListLoading,
      isMaptualListsLoading,
      maptualListObjectives,
      objective,
      enqueueSnackbar,
      canUserFilterMapView,
      productLineUserCreatedLists,
      voiceEnabled,
      anonymize,
      filteredMaptualList,
      isLoading,
      isSuggestedEntitiesFilterActive,
      powerScoreDisplay,
      hideMap,
    ]
  );
  return (
    <MapViewCoreContext.Provider value={mapContextValues}>
      <MapViewCore
        EntityPopover={EntityPopover}
        moveZoomControlsUp
        onEntityDetailsClick={onEntityDetailsClick}
      />
    </MapViewCoreContext.Provider>
  );
};
