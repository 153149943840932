import { ReactNode, useState } from "react";
import { Box, Button, Popover, styled, Typography } from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import {
  ContributionBreakdownResponse,
  EntityType,
} from "@odaia/clients/maptualAPI";
import { PowerScore } from "../powerScore";
import { SkeletonLoadingBar } from "../skeletons/SkeletonBars";
import { ErrorContainer } from "../errors/ErrorContainer";
import { EntityPowerscoreExplainability } from "./powerScoreExplainability";
import { PowerScoreFeedback } from "./PowerScoreFeedback";

export interface PowerScorePopoverProps {
  entityName: string;
  entityType: EntityType;
  entityId: string;
  isBreakdownLoading?: boolean;
  isInsightsLoading?: boolean;
  breakdown?: ContributionBreakdownResponse;
  insights?: object;
  powerScore: number;
  objectiveScores?: number[];
  isError?: boolean;
  onFeedback?: (selection: string) => void;
  hideExplainability?: boolean;
  onIntercomClick: (articleId: number) => void;
  onPowerScoreClick?: () => void;
}
const POWERSCORE_ARTICLE_ID = 6576953;

export const PowerScorePopover = ({
  powerScore,
  objectiveScores = [],
  entityName,
  entityType,
  entityId,
  isBreakdownLoading = true,
  isInsightsLoading = true,
  isError = false,
  breakdown,
  insights,
  onFeedback,
  hideExplainability = false,
  onIntercomClick = () => {},
  onPowerScoreClick = () => {},
}: PowerScorePopoverProps) => {
  const isOverallPowerscore = objectiveScores.length > 1;

  if (hideExplainability) {
    return (
      <CustomPopover
        powerScore={powerScore}
        objectiveScores={objectiveScores}
        entityId={entityId}
        entityType={entityType}
        onPowerScoreClick={onPowerScoreClick}
      >
        {isOverallPowerscore && (
          <DescriptionText variant="body2">
            <IntercomLink
              variant="body2"
              onClick={() => onIntercomClick(POWERSCORE_ARTICLE_ID)}
            >
              Overall PowerScore
            </IntercomLink>{" "}
            represents an {entityType}’s likelihood to support all of your
            products relative to other {entityType}s in your territory.
          </DescriptionText>
        )}
        {!isOverallPowerscore && (
          <>
            <DescriptionText variant="body2">
              PowerScore signifies an {entityType}’s propensity to help you
              achieve your business objective.
            </DescriptionText>
            <DescriptionText variant="body2">
              The PowerScore takes into account information like historical Rx
              as well as data points specific to MAPTUAL’s algorithms, like
              Segment memberships and predicted behaviors.
            </DescriptionText>
          </>
        )}
        <PowerScoreFeedback
          entityId={entityId}
          entityType={entityType}
          onFeedbackButtonClick={onFeedback}
        />
      </CustomPopover>
    );
  }

  return (
    <CustomPopover
      powerScore={powerScore}
      objectiveScores={objectiveScores}
      entityId={entityId}
      entityType={entityType}
      onPowerScoreClick={onPowerScoreClick}
    >
      <PowerscoreTitle name={entityName} />
      {isInsightsLoading && <SkeletonLoadingBar width="100%" height={68} />}
      {isBreakdownLoading && (
        <>
          <SkeletonLoadingBar width="100%" height={42} />
          <SkeletonLoadingBar width="100%" height={96} />
          <SkeletonLoadingBar width="100%" height={70} />
        </>
      )}
      {!isBreakdownLoading && (isError || Object.keys(breakdown ?? {}).length === 0) && (
        <Box gap={1} height={285}>
          <ErrorContainer
            title="PowerScore breakdown unavailable"
            style={{
              height: "100%",
              justifyContent: "center",
              textAlign: "center",
            }}
          />
        </Box>
      )}
      {!isBreakdownLoading && !isError && Object.keys(breakdown ?? {}).length > 0 && (
        <Box display="flex" flexDirection="column" gap={2}>
          <EntityPowerscoreExplainability
            powerScore={powerScore}
            contributionBreakdown={breakdown}
            entityType={entityType}
            insights={insights}
          />
          <PowerScoreFeedback
            entityId={entityId}
            entityType={entityType}
            onFeedbackButtonClick={onFeedback}
          />
        </Box>
      )}
    </CustomPopover>
  );
};

const PowerscoreTitle = ({ name }: { name: string }) => (
  <Box display="flex" gap={2} flexDirection="column">
    <TitleText variant="h6"> PowerScore Breakdown</TitleText>
    <EntityName variant="h5">{name}</EntityName>
  </Box>
);

interface CustomPopoverProps {
  powerScore?: number;
  objectiveScores?: number[];
  entityType: EntityType;
  entityId: string;
  children?: ReactNode[] | ReactNode;
}

const CustomPopover = ({
  powerScore,
  objectiveScores = [],
  children,
  onPowerScoreClick,
}: CustomPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  const handlePowerScoreClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onPowerScoreClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton onClick={handlePowerScoreClick} isActive={isPopoverOpen}>
        <PowerScore score={powerScore} objectiveScoreList={objectiveScores} />
        <ExpandMoreRounded data-testid="powerscore-arrow-drop-down-button" />
      </StyledButton>
      <StyledPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          {children}
        </Box>
      </StyledPopover>
    </>
  );
};

const StyledButton = styled(Button)(({ theme: { themeColors }, isActive }) => ({
  "& .MuiSvgIcon-root": {
    color: themeColors.utilityActiveContentColor,
  },
  backgroundColor: isActive
    ? themeColors.utilityActiveSurfaceColor
    : "transparent",
  gap: 4,
  padding: 8,
  height: "100%",
}));

const StyledPopover = styled(Popover)(({ theme: { themeColors } }) => ({
  marginTop: 3,
  "& .MuiPopover-paper": {
    width: 430,
    padding: 24,
    backgroundColor: themeColors.popoverBackground,
    borderColor: themeColors.borderPrimaryColor,
  },
}));

const TitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
}));

const EntityName = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
}));

const DescriptionText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
}));

const IntercomLink = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  display: "inline",
  textDecoration: "underline",
  cursor: "pointer",
}));
