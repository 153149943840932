import { ComparisonTimeFrameId } from '@odaia/clients/maptualAPI';
import { ROUTES } from '../../constants';
import { RegionRow } from './types';
import { Cadence } from './enums';

interface ComparisonTimeFrame {
  label: string;
  id: string;
}

export const listContainsID = (list, type, id) => {
  if (!list) {
    return false;
  }

  let contains = false;

  switch (type) {
    case 'maptualList':
      contains = Object.keys(list).some((item) => item === id);
      break;

    default:
      break;
  }

  return contains;
};

export const isTerritoryOverviewTimeframeEmpty = (data) =>
  data instanceof Object && Object.keys(data).length === 0;

export const isTerritoryOverviewItemsEmpty = (data, selectedTimeframeID) =>
  territoryOverviewDataExists(data, selectedTimeframeID) &&
  data?.items?.length === 0;

export const territoryOverviewDataExists = (data, selectedTimeframeID) => {
  const selectedTimeframeHasData = !isTerritoryOverviewTimeframeEmpty(data);

  return selectedTimeframeID && selectedTimeframeHasData;
};

export const getRegionLabel = (row: RegionRow): string => row?.data?.name ?? '';

export const calculateLabelColumnWidth = (items) => {
  let labelColWidth = 100;

  items.forEach((row) => {
    const rowLabel = getRegionLabel(row[0]);
    labelColWidth = Math.max(labelColWidth, rowLabel.length * 9);
  });

  return labelColWidth;
};

export const getTerritoryRoute = (row, maptualListId, projectId) => {
  const tableRowData = Object.values(row);
  const territory: RegionRow = tableRowData.find(
    (dataObject: RegionRow) =>
      dataObject?.data && typeof getRegionLabel(dataObject) === 'string'
  );
  const territoryName = getRegionLabel(territory);

  const projectAndRegionUrl = `${ROUTES.PROJECTS}/${projectId}/maptualListId/${maptualListId}`;

  return `${
    ROUTES.FIELD
  }${projectAndRegionUrl}?filterTerritories=${encodeURIComponent(
    territoryName
  )}`;
};

export const isEmptyItem = (item) =>
  Array.isArray(item?.data) &&
  item.data.every((data) => data === null) &&
  item.trend &&
  item.trend.value === null &&
  item.trend.type === 'TREND' &&
  item.trend.status === -1;

export const TIMEFRAME_TABS = {
  [Cadence.MONTH]: {
    id: Cadence.MONTH,
    name: 'Month',
  },
  [Cadence.QUARTER]: {
    id: Cadence.QUARTER,
    name: 'Quarter',
  },
  [Cadence.YTD]: {
    id: Cadence.YTD,
    name: 'YTD',
  },
};

export const getTimeframeName = (timeframeID: string) =>
  TIMEFRAME_TABS[timeframeID]?.name;

export const getComparisonTimeFrames = (
  cadence: Cadence
): ComparisonTimeFrame[] => {
  const comparisonTimeFrames = {
    [Cadence.MONTH]: [
      {
        id: ComparisonTimeFrameId.CadenceLastYear,
        label: 'Compare to same month last year',
      },
      {
        id: ComparisonTimeFrameId.PreviousCadence,
        label: 'Compare to previous month',
      },
    ],
    [Cadence.QUARTER]: [
      {
        id: ComparisonTimeFrameId.CadenceLastYear,
        label: 'Compare to same quarter last year',
      },
      {
        id: ComparisonTimeFrameId.PreviousCadence,
        label: 'Compare to previous quarter',
      },
    ],
    [Cadence.YTD]: [
      {
        id: ComparisonTimeFrameId.CadenceLastYear,
        label: 'Compare to YTD last year',
      },
    ],
  };

  return comparisonTimeFrames[cadence] || [];
};
