import { useQuery } from 'react-query';
import { EntityType } from '@odaia/clients/maptualAPI';
import { MaptualListMetadata } from '@odaia/domain/src/types';
import {
  getEntitiesPrecallInsights,
  getEntityPrecallInsights,
} from '../../../../../../request/projectRequests';

type Args = {
  maptualListMetadata: MaptualListMetadata['regionsFlat'][number];
  projectId: string;
  objectiveId: string | undefined;
  entityId?: string;
};

export const useEntityPrecallInsights = ({
  maptualListMetadata,
  projectId,
  objectiveId,
  entityId,
}: Args) =>
  useQuery({
    queryKey: [
      'map-entity-insights',
      maptualListMetadata.sfMaptualListId,
      objectiveId,
      entityId,
    ],
    queryFn: async () => {
      if (!objectiveId) {
        return undefined;
      }
      try {
        if (!entityId) {
          const response = await getEntitiesPrecallInsights({
            regionId: maptualListMetadata.sfMaptualListId,
            projectId,
            objectiveId,
            entityType: EntityType.Hcp,
          });
          return response.data || [];
        }

        const response = await getEntityPrecallInsights({
          entityId,
          projectId,
          objectiveId,
          regionId: maptualListMetadata.sfMaptualListId,
        });

        return response.data || [];
      } catch {
        return [];
      }
    },
    enabled:
      !!maptualListMetadata.maptualListId && !!objectiveId && !!projectId,
  });
