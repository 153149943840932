import { Box, Skeleton, styled } from "@mui/material";

interface SkeletonLayout {
  width?: number | string;
  height?: number | string;
  gap?: number | string;
  direction?: "row" | "column";
  children?: SkeletonLayout[];
}

const Wrapper = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== "gap" && propName !== "flexDirection";
  },
})(({ gap, flexDirection }) => ({
  display: "flex",
  flexDirection,
  gap: gap,
}));

export const SkeletonLoadingBar = styled(Skeleton, {
  shouldForwardProp(propName) {
    return propName !== "width" && propName !== "height";
  },
})(({ theme: { themeColors }, width, height }) => ({
  backgroundColor: themeColors.surfaceEmpty,
  transform: "scale(1)",
  height: height,
  width: width,
}));

export const SkeletonBars = ({ layout }: { layout: SkeletonLayout }) => {
  if (layout.children?.length) {
    return (
      <Wrapper
        gap={layout.gap}
        flexDirection={layout.direction ?? "column"}
        width={layout.width ?? "fit-content"}
        height={layout.height ?? "fit-content"}
      >
        {layout.children.map((childLayout) => (
          <SkeletonBars layout={childLayout} />
        ))}
      </Wrapper>
    );
  }
  return (
    <SkeletonLoadingBar
      width={layout.width ?? "100%"}
      height={layout.height ?? "100%"}
    />
  );
};
