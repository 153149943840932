import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  styled,
  Stack,
} from '@mui/material';
import { groupBy } from 'lodash';

import { toCapitalize } from '../../../utils/toCapitalize';
import { Wrapper } from './generic';

const stringFormat = (str, delimiter = '_') => {
  const arr = str.split(delimiter);

  return [...arr.map((a) => toCapitalize(a))].join(' ');
};

const Stat = ({ title, value }) => (
  <Box>
    <b>{title}: </b>
    {value}
  </Box>
);

const Subtitle = styled(Box)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryMaptualListFilteringColor,
  fontSize: 14,
}));
const BasketStat = ({ title, value, sx = {} }) => (
  <Box sx={sx}>
    <i>{title}: </i>
    {value}
  </Box>
);

const BasketWrapper = styled(Box)(() => ({
  marginTop: 2,
  marginBottom: 2,
}));
const Basket = ({ basket }) => {
  const { basketMetrics, paramText, productFriendlyName, basketScoringWeight } =
    basket;
  return (
    <BasketWrapper>
      <Box>
        <Subtitle>{paramText}</Subtitle>
        <Typography variant="h4">{productFriendlyName}</Typography>
      </Box>
      <Box>
        <BasketStat
          title="Basket Scoring Weight"
          value={basketScoringWeight}
          sx={{ marginBottom: 1 }}
        />
        {basketMetrics ? (
          <Box>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              Entity Metric Scoring Weights
            </Typography>
            {Object.entries(groupBy(basketMetrics, 'entity')).map(
              ([entityType, entityMetrics], i) => (
                <Stack key={i} mb={1} ml={3}>
                  <Typography variant="h6">{`${stringFormat(
                    entityType
                  )}`}</Typography>
                  {entityMetrics.map((metric, j) => (
                    <li key={j}>
                      {metric.rxSourceType} {metric.rxType}:{' '}
                      <b>{metric.scoringWeight}</b>
                    </li>
                  ))}
                </Stack>
              )
            )}
          </Box>
        ) : null}
      </Box>
    </BasketWrapper>
  );
};

const ObjectiveCard = styled(Card)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.contentCardBackgroundColor,
  borderColor: themeColors.contentCardBorderColor,
  padding: 10,
  flexGrow: 1,
}));

const ObjectiveCardHeader = styled(CardHeader)(
  ({ theme: { themeColors } }) => ({
    '.MuiCardHeader-subheader': {
      color: themeColors.primaryMaptualListFilteringColor,
    },
    textAlign: 'left',
  })
);

const ObjectiveCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gridGap: '10px',
}));

const Objective = ({ objective, name }) => (
  <ObjectiveCard variant="outlined">
    <ObjectiveCardHeader
      title={`${objective.fullDisplayName}: ${name}`}
      subheader={objective.objectiveId}
    />
    <ObjectiveCardContent>
      {objective.params.products.map((p, i) => (
        <Basket basket={p} key={`${i}basket`} />
      ))}
    </ObjectiveCardContent>
  </ObjectiveCard>
);

const Metadata = ({ metadata }) => {
  if (Object.keys(metadata || {}).length === 0) {
    return null;
  }
  return (
    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
      <Stat
        title="Created"
        value={new Date(metadata.creationDate * 1000).toDateString()}
      />
      <Stat
        title="Last Updated"
        value={new Date(metadata.lastUpdated * 1000).toDateString()}
      />
      <Stat
        title="Project Import ID"
        value={
          metadata.projectImportId.length > 0
            ? metadata.projectImportId
            : 'None'
        }
      />
      <Stat
        title="Product Line"
        value={`${metadata.productLine} (${metadata.productLineId})`}
      />
      <Stat title="Market" value={metadata.market} />
    </Box>
  );
};

const ObjectiveCardWrapper = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: 20,
  flexWrap: 'wrap',
}));

export const ProjectSummary = ({ project, objectiveNames }) => (
  <Wrapper>
    <Typography variant="h2">Metadata</Typography>
    <Metadata metadata={project.metadata} />
    <Typography variant="h2">Objectives</Typography>
    <ObjectiveCardWrapper>
      {project.objectives.map((obj, i) => (
        <Objective
          objective={obj}
          key={`obj-${i}`}
          name={objectiveNames[obj.objectiveId]}
        />
      ))}
    </ObjectiveCardWrapper>
  </Wrapper>
);
