import { useState, useRef } from "react";
import { useTheme, Box, styled } from "@mui/material";
import { Line } from "react-chartjs-2";
import { formatGraphValue, formatYAxisTitle } from "../utils/formatters";
import { GRAPH_VALUE_TYPE } from "../utils/constants";
import { Tooltip, TooltipData, createTooltip } from "../Tooltip";
import { GraphDataset } from "../interfaces";
import {
  Chart,
  ChartDataset,
  ScriptableTooltipContext,
  ScriptableScaleContext,
} from "chart.js";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignContent: "center",
  height: "100%",
  maxWidth: 1000,
}));
const createHoverLine = (chart: Chart, strokeColor: string) => {
  const x = chart.tooltip.caretX;
  const { ctx } = chart;
  const topY = chart.scales.y.top;
  const bottomY = chart.scales.y.bottom;
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x, topY);
  ctx.lineTo(x, bottomY);
  ctx.lineWidth = 1;
  ctx.setLineDash([3, 3]);
  ctx.strokeStyle = strokeColor;
  ctx.stroke();
  ctx.restore();
};

export interface LineGraphDataset extends GraphDataset {
  fillColor?: string;
  isFilled?: boolean;
}

export interface LineGraphProps {
  datasets: LineGraphDataset[];
  labels: string[];
  expanded?: boolean;
  yTitle?: string;
  tooltipDataPointTitle?: string;
  valueType?: GRAPH_VALUE_TYPE;
  width?: number | string;
}

export const LineGraph = ({
  datasets,
  labels,
  valueType,
  expanded = false,
  yTitle = "",
  tooltipDataPointTitle = "",
  width = "100%",
}: LineGraphProps) => {
  const [tooltip, setTooltip] = useState<TooltipData>({
    display: "none",
    opacity: 0,
    top: 0,
    left: 0,
    label: "",
    value: 0,
    trend: undefined,
  });

  const { themeColors } = useTheme();
  const chartRef = useRef(null);

  const isPercentage =
    valueType === GRAPH_VALUE_TYPE.RATE ||
    (valueType ?? "").includes("percent");

  const afterDrawPlugin = {
    afterDraw(chart: Chart<"line">) {
      // eslint-disable-next-line no-underscore-dangle
      if (chart?.tooltip?.caretX && chart.tooltip._active?.length) {
        createHoverLine(chart, themeColors.markerLine);
      }
    },
  };

  const chartData = {
    labels,
    datasets: datasets.map(({ data, color, isFilled, fillColor }) => ({
      tick: false,
      scale: false,
      pointStyle: false,
      label: "",
      data,
      fill: isFilled,
      borderColor: color,
      borderWidth: 2,
      backgroundColor: isFilled ? fillColor : "transparent",
    })),
  };

  const options = {
    maintainAspectRatio: false,
    animation: expanded,
    scales: {
      x: {
        display: expanded,
        ticks: {
          color: themeColors.chartDataLabel,
        },
        grid: {
          display: false,
        },
      },
      y: {
        min: (scale: ScriptableScaleContext) =>
          isPercentage
            ? 0
            : scale.chart.data.datasets.reduce(
                (acc: number, curr: ChartDataset) => {
                  const min = Math.min(...curr.data);
                  return min < acc ? min : acc;
                },
                0
              ),
        max: isPercentage ? 1 : undefined,
        title: {
          display: !!yTitle,
          text: formatYAxisTitle(yTitle),
          font: 12,
          color: themeColors.chartAxisLabel,
        },
        ticks: {
          color: themeColors.chartDataLabel,
          padding: 8,
          callback: (value: number) => formatGraphValue(value, valueType),
        },
        grid: {
          display: expanded,
          color: themeColors.chartLines,
        },
        display: expanded,
        border: { display: false },
      },
    },
    legend: {
      display: false,
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context: ScriptableTooltipContext<"line">) => {
          if (!expanded) return;
          createTooltip({
            context,
            tooltip,
            updateTooltip: (newTooltipData: TooltipData) =>
              setTooltip(newTooltipData),
          });
        },
      },
    },
  };

  const plugins = expanded ? [afterDrawPlugin] : [];

  return (
    <Wrapper width={width}>
      <Line
        data={chartData}
        ref={chartRef}
        options={options}
        plugins={plugins}
      />
      {expanded && (
        <Tooltip
          tooltip={tooltip}
          tooltipDataPointTitle={tooltipDataPointTitle}
          valueType={valueType}
        />
      )}
    </Wrapper>
  );
};
