import { useState, JSX } from 'react';
import { useTheme, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { isEqual } from 'lodash';
import {
  Chart,
  ChartData,
  Plugin,
  ChartArea,
  TooltipModel,
  ChartOptions,
} from 'chart.js';
import { METRIC_DATA_TYPE, TREND_STATUS } from '../constants';
import {
  INITIAL_TOOLTIP_STATE,
  Tooltip,
  TooltipData,
  TOOLTIP_WIDTH,
} from '../Tooltip';
import { buildTrendArrowImage } from './LineChart';
import { Cadence, Trend } from '../types';

const MAX_Y_AXIS_VALUE: number = 100;
const TOOLTIP_CENTRE_OFFSET_MULTIPLIER: number = 0.5;
const MAX_CATEGORY_SIZING = 0.8;

export interface BarChartDataset {
  data: number[];
  backgroundColor: string | string[];
  trends: Trend[];
  timestamps: string[];
  categoryPercentage: number;
  barPercentage: number;
  label: string;
  borderColor?: string;
  borderWidth?: number | number[];
}

export interface BarChartData extends ChartData<'bar'> {
  datasets: BarChartDataset[];
}

interface BarTooltipData extends Omit<TooltipData, 'top'> {
  index?: number;
}

const getTooltipValue = (data: number): string => {
  const nthDecimalDigits = 10;

  const formattedData = (
    Math.round(data * nthDecimalDigits) / nthDecimalDigits
  ).toFixed(1);
  return formattedData;
};

const buildTooltipData = (
  tooltipModel: TooltipModel<'bar'>,
  chartPosition: DOMRect,
  chartArea: ChartArea,
  metric: string,
  data: BarChartData
): BarTooltipData => {
  const { dataIndex: dataTimePosition } = tooltipModel.dataPoints[0];

  const groupedBarValues = data.datasets.map((dataset: BarChartDataset) => ({
    label: dataset.label,
    value: dataset.data[dataTimePosition]
      ? `${getTooltipValue(dataset.data[dataTimePosition])}% ${metric}`
      : 'No data available',
    color: dataset.backgroundColor,
    trend: dataset.trends[dataTimePosition],
  }));

  const categoryWidth: number = chartArea.width / data.labels.length;
  const tooltipLeft: number =
    chartArea.left +
    categoryWidth * (TOOLTIP_CENTRE_OFFSET_MULTIPLIER + dataTimePosition);
  const tooltipRight: number =
    chartArea.width -
    categoryWidth * (TOOLTIP_CENTRE_OFFSET_MULTIPLIER + dataTimePosition);

  return {
    display: 'flex',
    opacity: 1,
    left:
      tooltipLeft + TOOLTIP_WIDTH < chartPosition.width ? tooltipLeft : 'unset',
    right:
      tooltipLeft + TOOLTIP_WIDTH >= chartPosition.width
        ? tooltipRight
        : 'unset',
    lineValues: groupedBarValues,
    date: data.datasets[0].timestamps[dataTimePosition],
    index: dataTimePosition,
  };
};

interface CreateTooltipProps {
  context: { chart: Chart; tooltip: TooltipModel<'bar'> };
  currentTooltip: BarTooltipData;
  updateTooltip: (newTooltipData: BarTooltipData) => void;
  metric: string;
  data: BarChartData;
  selectedCardTab: METRIC_DATA_TYPE;
}

const createTooltip = ({
  context,
  currentTooltip,
  updateTooltip,
  metric,
  data,
}: CreateTooltipProps): void => {
  const { chart, tooltip: tooltipModel } = context;

  if (!chart) return;

  if (tooltipModel.opacity === 0) {
    if (currentTooltip.opacity !== 0) {
      updateTooltip({ ...currentTooltip, opacity: 0, display: 'none' });
    }
    return;
  }

  const chartPosition = chart.canvas.getBoundingClientRect();
  const { chartArea } = chart;

  const newTooltipData = buildTooltipData(
    tooltipModel,
    chartPosition,
    chartArea,
    metric,
    data
  );

  if (!isEqual(currentTooltip, newTooltipData)) {
    updateTooltip(newTooltipData);
  }
};

const verticalCategoryBackgroundPlugin: Plugin = {
  id: 'verticalCategoryBackground',
  afterTooltipDraw: (chart, args, options) => {
    const { ctx } = chart;
    const { left, top, height, width } = chart.chartArea;
    const defaultColor: string = '#000000';

    ctx.save();

    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color ?? defaultColor;

    const numberOfCategoryColumns: number = chart.scales.x.ticks.length;
    const overlayWidth: number = width / numberOfCategoryColumns;
    const activeColumnIndex: number = args.tooltip.dataPoints[0].dataIndex;

    ctx.fillRect(
      left + overlayWidth * activeColumnIndex,
      top,
      overlayWidth,
      height
    );

    ctx.restore();
  },
};

const TREND_ARROW_BAR_OFFSET = -12;

const calculateTrendArrowPosition = (
  barValues: number[],
  barAnchor: number
): object => ({
  xValue: barValues.length - 1,
  xAdjust: barAnchor,
  yValue: barValues[barValues.length - 1],
  yAdjust: TREND_ARROW_BAR_OFFSET,
});

export const buildAnnotationPlugin = (productDatasets, ctx) => {
  const labels = {};

  if (!productDatasets?.length) {
    return labels;
  }

  const { chart } = ctx;
  const { chartArea, data } = chart;

  if (!chartArea?.width || !data?.labels?.length) return labels;

  const fullDatasetColumnWidth = chartArea.width / data.labels.length;
  const categoryWidth = fullDatasetColumnWidth * MAX_CATEGORY_SIZING;
  const fullBarColumnWidth = categoryWidth / productDatasets.length;

  const centreIndex = (productDatasets.length - 1) / 2;

  productDatasets.forEach((productData, index) => {
    const { data, trends } = productData;
    if (!data.length) return;

    const lastTrend = trends[trends.length - 1];
    const { status } = lastTrend || {};

    const indexDelta = index - centreIndex;
    const barAnchor = indexDelta * fullBarColumnWidth;

    const showTrend =
      (data[data.length - 1] &&
        status === TREND_STATUS.INCREASING_OUT_OF_RANGE) ||
      status === TREND_STATUS.DECREASING_OUT_OF_RANGE;

    labels[index] = {
      type: 'label',
      content: showTrend ? buildTrendArrowImage(status) : null,
      ...calculateTrendArrowPosition(data, barAnchor),
    };
  });

  return labels;
};

interface BarChartProps {
  selectedCadence: Cadence;
  selectedCardTab: string;
  data: BarChartData;
  metric: string;
  yAxisTitle: string;
  legendPlugin: object;
  dataTestId?: string;
}

export const BarChart = ({
  selectedCadence,
  selectedCardTab,
  metric,
  data,
  yAxisTitle,
  legendPlugin,
  dataTestId,
}: BarChartProps): JSX.Element => {
  const { themeColors } = useTheme();

  const [tooltip, setTooltip] = useState<BarTooltipData>(INITIAL_TOOLTIP_STATE);

  const options: ChartOptions = {
    bounds: 'data',
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    plugins: {
      legend: legendPlugin,
      tooltip: {
        enabled: false,
        external: (context) => {
          createTooltip({
            context,
            currentTooltip: tooltip,
            updateTooltip: (newTooltipData) => setTooltip(newTooltipData),
            metric,
            data,
          });
        },
        position: 'nearest',
        axis: 'x',
      },
      verticalCategoryBackground: {
        color: themeColors.contentCardAltBackgroundColor,
      },
      annotation: {
        annotations: (ctx) => buildAnnotationPlugin(data.datasets, ctx),
      },
    },
    scales: {
      y: {
        min: 0,
        max: MAX_Y_AXIS_VALUE,
        border: { display: false },
        grid: {
          drawTicks: false,
          color: themeColors.borderPrimaryColor,
          lineWidth: 1,
        },
        ticks: {
          color: themeColors.tertiaryColor,
          stepSize: Math.ceil(MAX_Y_AXIS_VALUE / 5),
          maxTicksLimit: 6,
          callback: (value) => `${value}%`,
        },
        title: {
          display: !!metric,
          text: yAxisTitle,
          font: 12,
          color: themeColors.tertiaryColor,
        },
      },
      x: {
        border: {
          display: false,
        },
        grid: {
          drawTicks: true,
          drawOnChartArea: false,
        },
        ticks: {
          color: themeColors.tertiaryColor,
          padding: 4,
        },
      },
    },
  };

  return (
    <Box position="relative" height={192} data-testid={dataTestId}>
      <Bar
        options={options}
        data={data}
        plugins={[verticalCategoryBackgroundPlugin]}
      />
      <Tooltip
        tooltip={tooltip}
        metricName={metric}
        selectedCardTab={selectedCardTab}
        selectedCadence={selectedCadence}
      />
    </Box>
  );
};
