import { Box, Typography } from "@mui/material"

import { PreCallInsightIcon, PrecallInsightType } from "./preCallInsightsIcon"
import { ComponentProps } from "react"

type Props = {insightType: PrecallInsightType, title: string, width?: string, strong?: boolean, sx?: ComponentProps<typeof Box>['sx'] }

export const PreCallInsight = ({ insightType, title, strong, sx }: Props) => <Box sx={{ display: "grid", gridTemplateColumns: '26px 1fr', alignItems: "center", flexDirection: "row", gap: 1, textAlign: "left", ...sx }}>
    <PreCallInsightIcon insightType={insightType} />
    <Typography color={theme => theme.themeColors.inputs.secondary.default.content} variant="body2" fontWeight={strong ? 500: 400}>{title.replace(/^#*\s/, "")}</Typography>
</Box>