import { styled, Box, Button } from "@mui/material";
import { UnfoldMore } from "@mui/icons-material";
import { MouseEvent } from "react";

const StyledExpandIcon = styled(UnfoldMore)(({ theme }) => ({
  color: theme.themeColors.neutral70,
  width: 16,
  height: 16,
  transform: "rotate(45deg)",
  transformOrigin: "center",
}));

const Wrapper = styled(Box)(({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
}));

const StyledButton = styled(Button)(({
  width: '100%',
  height: '100%',
  padding: 0,
}));
  
export const ActionCell = ({
  onClick = () => {},
}: {
  onClick: (_?: MouseEvent<HTMLButtonElement>) => void;
}) => (
  <Wrapper
    data-testid="table-expander-button"
  >
    <StyledButton onClick={onClick}>
      <StyledExpandIcon />
    </StyledButton>
  </Wrapper>
);
