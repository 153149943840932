import { Box, useTheme, styled } from "@mui/material";
import { formatModalValue, getEntityCellValue } from "../utils/formatters";
import { GraphModal } from "./components/GraphModal";
import { TrendValue } from "../../trend/TrendValue";
import { ChartType, GRAPH_VALUE_TYPE } from "../utils/constants";
import { LineGraph } from "../../graphs";
import { MouseEvent, useState } from "react";
import { TrendProps } from "../../trend";

const ValueTrendBox = styled(Box)(({ theme: { themeColors } }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  fontSize: 16,
  fontWeight: 400,
  cursor: "default",
  color: themeColors.tableCellDefaultContent,
  minWidth: 50,
  alignItems: "start",
  justifyContent: "center",
  lineHeight: 1,
}));

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  height: 50,
  cursor: "pointer",
  alignContent: "center",
}));

export interface TrendAreaCellProps {
  data: number[];
  xLabels: string[];
  trend?: TrendProps;
  type: GRAPH_VALUE_TYPE;
  graphTitle: string;
  color?: string;
  yTitle: string;
}

export const TrendAreaCell = ({
  data,
  color,
  xLabels,
  trend,
  type,
  graphTitle,
  yTitle,
}: TrendAreaCellProps) => {
  const { themeColors } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalType =
    type === GRAPH_VALUE_TYPE.RATE ? GRAPH_VALUE_TYPE.PERCENT : type;
  const modalData = data?.map((value) => formatModalValue(value, modalType));

  const onContainerClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (modalData?.length > 0) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Container onClick={onContainerClick}>
        <LineGraph
          datasets={[
            {
              data,
              color: color ?? themeColors.dataGeneralColor,
              isFilled: true,
              fillColor: `${color ?? themeColors.dataGeneralColor}33`,
            },
          ]}
          yTitle={graphTitle}
          labels={xLabels}
          expanded={false}
          width={44}
        />

        <ValueTrendBox data-testid={`value-trend-box-${data.join("-")}`}>
          {getEntityCellValue(data, type)}
          {trend && (
            <Box mt={0.5}>
              <TrendValue
                type={trend?.type}
                status={trend?.status}
                value={trend?.value}
                noMargin
              />
            </Box>
          )}
        </ValueTrendBox>
      </Container>

      <GraphModal
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        graphType={ChartType.LINE}
        data={modalData}
        chartLabels={xLabels}
        graphTitle={graphTitle}
        yTitle={yTitle}
        graphColor={themeColors.dataGeneralColor}
        valueType={modalType}
      />
    </>
  );
};
