import Button from '@mui/material/Button';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { ConfigSchemaTitle } from '../styledComponents';
import { SchemaInventory } from './SchemaInventory';
import { SchemaCreationModal } from './schemaCreationModal';
import { CreateInstancesSection } from './createInstancesSection';

const ConfigMainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 70px 30px 40px',
}));
const FlexSpaceApartRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '36px',
  gap: 36,
}));

export function ConfigSchemaView() {
  const [schemaCreationModalOpen, setSchemaCreationModalOpen] = useState(false);

  return (
    <ConfigMainContainer>
      <FlexSpaceApartRow>
        <ConfigSchemaTitle variant="h3">
          Module Configurations
        </ConfigSchemaTitle>
        <Button
          variant="contained"
          onClick={() => setSchemaCreationModalOpen(true)}
        >
          Add New Config +
        </Button>
      </FlexSpaceApartRow>
      <Stack gap={3}>
        <CreateInstancesSection />
        <SchemaInventory />
      </Stack>
      <SchemaCreationModal
        isOpen={schemaCreationModalOpen}
        onClose={() => setSchemaCreationModalOpen(false)}
      />
    </ConfigMainContainer>
  );
}
