import { useContext, useEffect, useState } from 'react';
import { styled, Box, Typography, Divider } from '@mui/material';
import { Table } from './table/Table';
import { TerritoryOverviewContext } from './territoryOverviewContext';
import { Settings } from './components/Settings';
import { Disclaimer } from './components/Disclaimer';
import { SkeletonChartContent } from '../../components/generic/SkeletonChartContent';
import { disclaimerOptions } from './constants';
import { SkeletonBars } from '../../components/generic/skeletonBars';
import { RegionSelection } from '../application/appViews/field/navigation/regionSelection/RegionSelection';

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const Header = styled(FlexColumnBase)(() => ({
  padding: 16,
  paddingBottom: 0,
  width: '100%',
  minWidth: 560,
  gap: 16,
}));

const Title = styled(Typography)(() => ({
  textAlign: 'left',
}));

const TableContainer = styled(FlexColumnBase)(() => ({
  paddingLeft: 16,
  maxHeight: '90%',
  overflow: 'hidden',
}));

const TitleContainer = styled(FlexColumnBase)(() => ({
  gap: 10,
}));

const StyledDivider = styled(Divider)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.dividerPrimaryColor,
  margin: '2px -16px',
}));

const RegionButtonContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 34,
}));

export const Container = () => {
  const { metadataStatus, isMetadataLoading, metadata } = useContext(
    TerritoryOverviewContext
  );

  const [disclaimerText, setDisclaimerText] = useState();

  useEffect(() => {
    if (!isMetadataLoading) {
      switch (metadataStatus) {
        case 200:
          setDisclaimerText(null);
          break;
        case 204:
          setDisclaimerText(disclaimerOptions.INVALID_REGION);
          break;
        case 404:
          setDisclaimerText(disclaimerOptions.NO_PRODUCT_DATA);
          break;
        default:
          setDisclaimerText(disclaimerOptions.UNHANDLED_ERROR);
          break;
      }
    }
  }, [isMetadataLoading, metadata, metadataStatus]);

  if (isMetadataLoading) {
    return (
      <>
        <HeaderContent />
        <TableContainer>
          <SkeletonChartContent />
        </TableContainer>
      </>
    );
  }

  if (disclaimerText) {
    return (
      <>
        <HeaderContent />
        <TableContainer>
          <Disclaimer disclaimerText={disclaimerText} />
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <HeaderContent />

      <TableContainer>
        <Settings />
        <TableContent />
      </TableContainer>
    </>
  );
};

const HeaderContent = () => {
  const {
    territoryName,
    setSelectedTimeframeID,
    setSelectedMetric,
    setSelectedObjective,
  } = useContext(TerritoryOverviewContext);

  const clearSelections = () => {
    setSelectedTimeframeID();
    setSelectedMetric();
    setSelectedObjective();
  };

  return (
    <Header>
      <RegionButtonContainer>
        <RegionSelection
          regionName={territoryName}
          handleChange={() => clearSelections()}
        />
      </RegionButtonContainer>
      <TitleContainer>
        <Title variant="h3">Territory Overview</Title>
        <StyledDivider />
      </TitleContainer>
    </Header>
  );
};

const TableContent = () => {
  const { data, isDataLoading, status } = useContext(TerritoryOverviewContext);

  const [disclaimerText, setDisclaimerText] = useState<string | null>(null);

  useEffect(() => {
    if (!isDataLoading) {
      switch (status) {
        case 200:
          setDisclaimerText(null);
          break;
        case 204:
          setDisclaimerText(disclaimerOptions.NO_CHILD_REGIONS);
          break;
        default:
          setDisclaimerText(disclaimerOptions.UNHANDLED_ERROR);
          break;
      }
    }
  }, [isDataLoading, status]);

  if (isDataLoading) {
    return <SkeletonBars />;
  }

  if (disclaimerText) {
    return <Disclaimer disclaimerText={disclaimerText} />;
  }

  if (Object.keys(data ?? {}).length === 0) {
    return <Disclaimer disclaimerText={disclaimerOptions.UNHANDLED_ERROR} />;
  }

  return <Table />;
};
