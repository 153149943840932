import { useEffect, useMemo, useRef, useState } from 'react';
import {
  styled,
  Box,
  Button,
  Switch as MuiSwitch,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Autocomplete } from '@mui/lab';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import { isEqual } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { FloatingButtonWrapper, LoadingIcon } from './styledComponents';
import { formatListForDisplay } from '../../utils/formatListForDisplay';
import { JsonInputSection } from './jsonInput';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.themeColors.tooltipBackgroundColor,
    color: theme.themeColors.primaryTextColor,
    maxWidth: 250,
  },
}))(Tooltip);

const StyledSegmentContainer = styled(Box)(({ theme: { themeColors } }) => ({
  flex: 1,
  padding: 24,
  textAlign: 'left',
  overflowY: 'scroll',
  border: `2px solid ${themeColors.userConfigEditHighlight}`,
  position: 'relative',
}));

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  '&:disabled': {
    backgroundColor: themeColors.contentCardAltBackgroundColor,
  },
}));

const StyledAutocomplete = styled(Autocomplete)(
  ({ theme: { themeColors } }) => ({
    width: '50%',
    '& label.Mui-focused': {
      color: themeColors.projectModalTextColor,
    },
    '&.Mui-focused .MuiAutocomplete': {
      borderColor: themeColors.projectModalTextColor,
      borderWidth: '1px',
    },
  })
);

const ContentWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: 24,
});

const TextSelectionWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: 16,
});

const SecondaryText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
}));

const FormHelperText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.neutral60,
  fontSize: '12px',
  fontWeight: 400,
  textOverflow: 'ellipsis',
  margin: '0px 14px',
}));

const SubHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

const ContentRow = styled('div')({
  display: 'flex',
  width: '100%',
  gap: 16,
});

const ToggleContentRow = styled(ContentRow)({
  marginTop: -8,
});

const StyledToggleSection = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 10,
  '.MuiTypography-root:first-child': {
    marginTop: 0,
  },
  '.MuiTypography-root:last-child': {
    marginBottom: 0,
  },
});

const StyledPrimaryText = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  margin: '24px 0 4px',
});

const StyledSecondaryText = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: 16,
    color: themeColors.neutral60,
    margin: '4px 0',
  })
);

const OrderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  width: '50%',
  textOverflow: 'ellipsis',
});
const ToggleSection = ({ title, description, value, onChange }) => (
  <StyledToggleSection>
    <Box>
      <StyledPrimaryText>{title}</StyledPrimaryText>
      <StyledSecondaryText>{description}</StyledSecondaryText>
    </Box>
    <Box>
      <MuiSwitch checked={value} onChange={onChange} />
    </Box>
  </StyledToggleSection>
);

export function validateJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return e.message;
  }
  return true;
}

const santizeGeneratorConfig = (generatorConfig) => {
  const sanitizedConfig = {};
  Object.entries(generatorConfig).forEach(([key, value]) => {
    if (
      value !== null &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === 'object' && Object.keys(value).length === 0)
    ) {
      sanitizedConfig[key] = value;
    }
  });
  return JSON.stringify(sanitizedConfig);
};
export function SegmentEdit({
  selectedSegment,
  setEditView,
  segmentIsSaving,
  onSaveSegment,
  segmentOrders,
  productLines,
}) {
  const initialData = {
    productLineId:
      selectedSegment.productLineIds.length > 0
        ? selectedSegment.productLineIds[0]
        : '',
    intercomId: selectedSegment.intercomId || '',
    primaryText: selectedSegment.primaryText,
    secondaryText: selectedSegment.secondaryText,
    description: selectedSegment.description || '',
    maptualList: !!selectedSegment.maptualList,
    UILabel: selectedSegment.maptualList?.label ?? '',
    order:
      (selectedSegment.maptualList?.order
        ? String(selectedSegment.maptualList?.order)
        : selectedSegment.maptualList?.order) ?? '',
    isRescoringInclusive: !!selectedSegment.isRescoringInclusive,
    isHiddenInSphere: !!selectedSegment.isHiddenInSphere,
    editedSegmentLabelGenerator: santizeGeneratorConfig(
      selectedSegment.segmentLabelGenerator
    ),
    editedSegmentActivityGenerator: santizeGeneratorConfig(
      selectedSegment.segmentActivityGenerator
    ),
    editedProjectSegmentLabelGenerator: santizeGeneratorConfig(
      selectedSegment.projectSegmentLabelGenerator
    ),
  };

  const { control, setValue, watch, formState, handleSubmit } = useForm({
    defaultValues: initialData,
    mode: 'all',
  });

  const formValues = watch();

  useEffect(() => {
    if (!formValues.maptualList) {
      setValue('UILabel', '');
      setValue('order', '');
    }
  }, [formValues.maptualList]);
  const { order, maptualList } = formValues;

  const overlappingOrders = useMemo(
    () =>
      Object.keys(segmentOrders).filter((key) => segmentOrders[key] === +order),
    [segmentOrders, order]
  );
  const typographyRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const canSave = !isEqual(initialData, formValues) && formState.isValid;

  useEffect(() => {
    const typographyElement = typographyRef.current;
    if (typographyElement) {
      const isTextOverflowing =
        typographyElement.scrollWidth > typographyElement.clientWidth ||
        typographyElement.scrollHeight > typographyElement.clientHeight;

      setIsOverflowing(isTextOverflowing);
    }
  }, [overlappingOrders]);

  const onSave = handleSubmit((formData) => {
    onSaveSegment({
      productLineIds: [formData.productLineId].filter(Boolean),
      primaryText: formData.primaryText,
      secondaryText: formData.secondaryText,
      intercomId: formData.intercomId || null,
      isHiddenInSphere: formData.isHiddenInSphere,
      isRescoringInclusive: formData.isRescoringInclusive,
      ...(formData.maptualList
        ? { maptualList: { label: formData.UILabel, order: +formData.order } }
        : {}),
      segmentLabelGenerator: JSON.parse(formData.editedSegmentLabelGenerator),
      segmentActivityGenerator: JSON.parse(
        formData.editedSegmentActivityGenerator
      ),
      projectSegmentLabelGenerator: JSON.parse(
        formData.editedProjectSegmentLabelGenerator
      ),
    });
  });

  const isParentNode = selectedSegment.nodes.length > 0;

  return (
    <StyledSegmentContainer>
      <FloatingButtonWrapper style={{ top: 0 }}>
        <Button onClick={() => setEditView(false)} variant="outlined">
          Cancel
        </Button>
        <StyledButton variant="contained" disabled={!canSave} onClick={onSave}>
          {segmentIsSaving ? (
            <>
              Saving
              <LoadingIcon size="11px" />
            </>
          ) : (
            'Save'
          )}
        </StyledButton>
      </FloatingButtonWrapper>
      <Typography variant="h5">{selectedSegment.primaryText}</Typography>
      <ContentWrapper>
        <TextSelectionWrapper>
          <SubHeader>
            <Typography variant="h5">Segment Information</Typography>
            <SecondaryText variant="body1">
              Add defining information for this segment
            </SecondaryText>
          </SubHeader>
          {!isParentNode && (
            <ContentRow>
              <Controller
                name="productLineId"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledAutocomplete
                    {...field}
                    onChange={(event, newValue) => field.onChange(newValue)}
                    options={productLines}
                    renderInput={(params) => (
                      <TextField
                        notched
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Product Line ID"
                        placeholder="Select Product Line ID"
                        error={fieldState.invalid}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="intercomId"
                control={control}
                rules={{
                  validate: (val) => /^[0-9]+$/.test(val) || val === '',
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    notched
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '50%' }}
                    error={fieldState.invalid}
                    label="Intercom ID"
                    placeholder="Add Intercom ID"
                    {...field}
                  />
                )}
              />
            </ContentRow>
          )}
          <ContentRow>
            <Controller
              name="primaryText"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: '50%' }}
                  error={fieldState.invalid}
                  placeholder="Add Primary Text"
                  required
                  label="Primary Text"
                />
              )}
            />

            <Controller
              name="secondaryText"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: '50%' }}
                  label="Secondary Text"
                  placeholder="Add Secondary Text"
                />
              )}
            />
          </ContentRow>

          <Controller
            name="description"
            control={control}
            disabled
            render={({ field }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                multiline
                label="Description"
                placeholder="Add Description"
              />
            )}
          />
        </TextSelectionWrapper>
        {!isParentNode && (
          <>
            <Controller
              name="maptualList"
              control={control}
              render={({ field }) => (
                <ToggleSection
                  {...field}
                  title="Maptual List"
                  description="Show segment chip on HCP cards on the Maptual List"
                  onChange={(e, checked) => {
                    field.onChange(checked);
                  }}
                />
              )}
            />

            <ToggleContentRow>
              <Controller
                name="UILabel"
                control={control}
                rules={{ required: maptualList }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '50%' }}
                    value={
                      maptualList ? field.value : 'Toggle on to add UI Label'
                    }
                    required={maptualList}
                    label="UI Label"
                    disabled={!maptualList}
                    error={maptualList && fieldState.invalid}
                    placeholder="Add Label"
                  />
                )}
              />

              <OrderWrapper>
                <Controller
                  name="order"
                  control={control}
                  rules={{
                    required: maptualList,
                    validate: (val) =>
                      /^[0-9]+$/.test(val) ||
                      order === '' ||
                      !maptualList ||
                      'Whole numbers only',
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      value={
                        maptualList ? field.value : 'Toggle on to add order'
                      }
                      InputLabelProps={{ shrink: true }}
                      required={maptualList}
                      label="Order"
                      disabled={!maptualList}
                      error={maptualList && fieldState.invalid}
                      helperText={
                        maptualList && fieldState.invalid
                          ? fieldState.error?.message
                          : undefined
                      }
                      placeholder="Add Order"
                    />
                  )}
                />

                {overlappingOrders.length > 0 && !isOverflowing && (
                  <FormHelperText noWrap ref={typographyRef}>
                    Overlaps with {overlappingOrders.join(', ')}
                  </FormHelperText>
                )}
                {overlappingOrders.length > 0 && isOverflowing && (
                  <CustomTooltip
                    title={`Overlaps with ${formatListForDisplay(
                      overlappingOrders
                    )}`}
                  >
                    <FormHelperText noWrap ref={typographyRef}>
                      Overlaps with {overlappingOrders.join(', ')}
                    </FormHelperText>
                  </CustomTooltip>
                )}
              </OrderWrapper>
            </ToggleContentRow>
          </>
        )}
        <Controller
          name="isHiddenInSphere"
          control={control}
          render={({ field }) => (
            <ToggleSection
              {...field}
              onChange={(e, checked) => {
                field.onChange(checked);
              }}
              title="isHiddenInSphere"
              description="When selected, segment will only be shown in Field"
            />
          )}
        />
        {!isParentNode && (
          <>
            <Controller
              name="isRescoringInclusive"
              control={control}
              render={({ field }) => (
                <ToggleSection
                  {...field}
                  onChange={(e, checked) => {
                    field.onChange(checked);
                  }}
                  title="isRescoringInclusive"
                  description="Generate PowerScore for an HCP that belongs to this segment"
                />
              )}
            />

            <Controller
              name="editedSegmentLabelGenerator"
              control={control}
              rules={{ validate: validateJson }}
              render={({ field, fieldState }) => (
                <JsonInputSection
                  {...field}
                  onChange={(e) =>
                    setValue('editedSegmentLabelGenerator', e.target.value)
                  }
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  header="Segment Label Generator"
                  subheader="Attributes to generate segment membership for project run date"
                />
              )}
            />

            <Controller
              name="editedSegmentActivityGenerator"
              control={control}
              rules={{ validate: validateJson }}
              render={({ field, fieldState }) => (
                <JsonInputSection
                  {...field}
                  onChange={(e) =>
                    setValue('editedSegmentActivityGenerator', e.target.value)
                  }
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  header="Segment Activity Generator"
                  subheader="Attributes to generate predictive segments activities"
                />
              )}
            />

            <Controller
              name="editedSegmentActivityGenerator"
              control={control}
              rules={{ validate: validateJson }}
              render={({ field, fieldState }) => (
                <JsonInputSection
                  {...field}
                  onChange={(e) =>
                    setValue('editedSegmentActivityGenerator', e.target.value)
                  }
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  header="Project Segment Label Generator"
                  subheader="Attributes to aggregate segments into project level segments"
                />
              )}
            />
          </>
        )}
      </ContentWrapper>
    </StyledSegmentContainer>
  );
}
