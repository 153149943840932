import { Box, Typography, TypographyProps, styled } from '@mui/material';
import { TypographyDefinitions } from '@odaia/ui/src/typography';

interface StyledTypographyProps extends TypographyProps {
  isCaption?: boolean;
}

const StyledNameCellTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isCaption' && prop !== 'variant',
})<StyledTypographyProps>(({ theme, isCaption }) => ({
  fontWeight: isCaption
    ? TypographyDefinitions.fontWeight.regular
    : TypographyDefinitions.fontWeight.medium,
  color: isCaption
    ? theme.themeColors.tertiaryColor
    : theme.themeColors.secondaryTextColor,
    fontSize: 14,
}));

const Wrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  flexDirection: 'column',
  textAlign: 'left'
});

export interface NameCellProps {
    name: string;
    secondaryText: string;
}

export const NameCell = ({ name, secondaryText }: NameCellProps) => (
  <Wrapper>
    <StyledNameCellTypography variant="body2">
      {name}
    </StyledNameCellTypography>
    <StyledNameCellTypography variant="caption" isCaption>
      {secondaryText}
    </StyledNameCellTypography>
  </Wrapper>
);
