import { useContext } from 'react';
import { Skeleton, styled, Typography, useTheme } from '@mui/material';
import { MarketPerformanceMetadataContext } from './data/marketPerformanceMetadataContext';
import { MarketPerformanceChartContext } from './data/marketPerformanceChartContext';
import { ErrorContainer } from '../../../generic/errorContainer';
import { LineChart } from '../shared/LineChart';

const SkeletonBar = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
}));

export const buildLegendPlugin = (themeColors) => ({
  display: true,
  position: 'bottom',
  align: 'start',
  labels: {
    usePointStyle: true,
    pointStyle: 'circle',
    boxHeight: 8,
    padding: 12,
    generateLabels: (chart) =>
      chart.data.datasets.map((dataset) => ({
        text: dataset.label,
        fillStyle: dataset.borderColor,
        fontColor: themeColors.tertiaryColor,
      })),
  },
});

export const MarketPerformanceLineChart = () => {
  const theme = useTheme();
  const { themeColors } = theme;

  const {
    isMetadataLoading,
    selectedMetric,
    selectedCadence,
    selectedUnit,
    currentTabMetadata,
    selectedCardTab,
  } = useContext(MarketPerformanceMetadataContext);

  const { isDataLoading, isDataError, isDataIdle, refetchData, title, data } =
    useContext(MarketPerformanceChartContext);

  const metricName = currentTabMetadata?.metrics?.find(
    (metric) => metric.rxType === selectedMetric
  )?.displayName;

  if (isMetadataLoading || isDataIdle || isDataLoading) {
    return <SkeletonBar animation="wave" height={225} />;
  }

  if (isDataError) {
    return <ErrorContainer handleRetry={refetchData} />;
  }

  if (!data?.length) {
    return (
      <ErrorContainer
        title="Graph not available"
        description="We do not have enough data to generate a graph for these products."
      />
    );
  }

  return (
    <>
      <Typography
        variant="h6"
        textAlign="left"
        color={themeColors.primaryTextColor}
      >
        {title}, {selectedCadence.label}
      </Typography>

      <LineChart
        selectedCadence={selectedCadence}
        selectedCardTab={selectedCardTab}
        unit={selectedUnit}
        metricName={metricName}
        data={data}
        legendPlugin={buildLegendPlugin(themeColors)}
        dataTestId="market-performance-line-chart"
      />
    </>
  );
};
