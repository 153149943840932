import React, { useEffect, useState } from "react";
import { FilterSection } from "./filterSidebar";
import { MultiSelectFiltering, filterOption } from "./multiSelectFiltering";
import { Filters } from "../mapView";

const getSelectedTargets = (filters: Filters) => {
  const selectedTargets = {};
  filters?.customerTargetFilter?.forEach((customerTarget) => {
    selectedTargets[customerTarget] = { id: customerTarget };
  });
  return selectedTargets;
};

export const CustomerTargetFilter = ({
  filters,
  setFilters,
  customerTargetsConfig,
}: {
  filters: Filters;
  setFilters: (arg0: Filters) => void;
  customerTargetsConfig: any;
}) => {
  const [selectedTargets, setSelectedTargets] = useState<filterOption | null>(
    getSelectedTargets(filters)
  );

  useEffect(() => {
    const TargetsToUpdate = selectedTargets || {};
    if (
      filters?.customerTargetFilter?.sort().toString() !==
      Object.keys(TargetsToUpdate).sort().toString()
    ) {
      setSelectedTargets(getSelectedTargets(filters));
    }
  }, [filters]);

  const formattedTargets = customerTargetsConfig?.sort().map((s) => ({
    id: s.value,
    label: s.friendlyName,
    tokenConfig: s.tokenConfig,
  }));

  const updateSelectedItems = (itemId, itemLabel, checked) => {
    let newTargets = {};
    if (checked) {
      newTargets = {
        ...selectedTargets,
        [itemId]: { id: itemId },
      };
    } else {
      newTargets = selectedTargets;
      delete selectedTargets[itemId];
    }

    setSelectedTargets(newTargets);
    setFilters({ ...filters, customerTargetFilter: Object.keys(newTargets) });
  };

  return (
    <FilterSection>
      {filters.customerTargetFilter && (
        <MultiSelectFiltering
          title="Targeting Bands"
          description="Show HCPs within the selected Bands"
          filterType="targeting bands"
          filterOptions={formattedTargets}
          selectedFilters={selectedTargets}
          updateFilters={(itemId, itemLabel, checked) =>
            updateSelectedItems(itemId, itemLabel, checked)
          }
          showIcon
        />
      )}
    </FilterSection>
  );
};
