import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import { EntityType } from '@odaia/clients/maptualAPI';
import SubCharts from './subCharts';
import EmptyView from '../../emptyview/emptyView';
import { ProductPerformanceCard } from '../../fieldView/entityCharts/productPerformance';
import { MarketPerformanceCard } from '../../fieldView/entityCharts/marketPerformance';
import { OpportunityCard } from '../../fieldView/entityCharts/opportunity';
import { ObjectiveContext } from '../contexts/objectiveContext';

const DashboardView = ({
  projectId,
  segmentId,
  regionId,
  getDashboardStructure,
  getDashboardSubUrl,
}) => {
  const [dashboardStruct, setDashboardStruct] = useState([]);
  const [fetchingDashboardStruct, setFetchingDashboardStruct] = useState(true);

  const { selectedObjective } = useContext(ObjectiveContext);
  const fetchDashboardStruct = async () => {
    setFetchingDashboardStruct(true);
    const dashboardStructure = await getDashboardStructure({
      projectId,
      segmentId,
      regionId,
    });
    if (
      dashboardStructure.action.type ===
      'PROJECT_GET_DASHBOARD_STRUCTURE_FULFILLED'
    ) {
      setDashboardStruct([
        ...dashboardStructure.action.payload.metricsSections,
      ]);
    }
    setFetchingDashboardStruct(false);
  };

  useEffect(() => {
    fetchDashboardStruct();
  }, [segmentId, regionId]);

  if (!selectedObjective?.id) {
    return <EmptyView />;
  }

  return (
    <>
      <Box sx={{ width: 800 }}>
        <div>
          <ProductPerformanceCard
            projectId={projectId}
            regionId={regionId}
            objectiveId={selectedObjective.id}
            entityId={regionId}
            entityType={EntityType.RegionalTeam}
          />
          <MarketPerformanceCard
            projectId={projectId}
            regionId={regionId}
            objectiveId={selectedObjective.id}
            entityId={regionId}
            entityType={EntityType.RegionalTeam}
          />
          <OpportunityCard
            projectId={projectId}
            regionId={regionId}
            objectiveId={selectedObjective.id}
            entityId={regionId}
            entityType={EntityType.RegionalTeam}
          />
        </div>
      </Box>
      {fetchingDashboardStruct ? (
        <Fade in timeout={600} key="loading">
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <EmptyView isLoading />
          </div>
        </Fade>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            paddingTop: 15,
          }}
        >
          {dashboardStruct.map((section) => (
            <SubCharts
              key={`${section.componentType}-${section.subUrl}`}
              getDashboardSubUrl={getDashboardSubUrl}
              subUrl={section.subUrl}
              projectId={projectId}
              segmentId={segmentId}
              regionId={regionId}
              objectiveId={selectedObjective.id}
              primaryText={section.primaryText}
              componentType={section.componentType}
            />
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  segmentId: state.projectViewer.selectedSegmentId,
  regionId: state.projectViewer.selectedRegion?.regionId,
});

DashboardView.propTypes = {
  projectId: PropTypes.string,
  segmentId: PropTypes.string,
  regionId: PropTypes.string,
  getDashboardStructure: PropTypes.func,
  getDashboardSubUrl: PropTypes.func,
};

export default connect(mapStateToProps)(DashboardView);
