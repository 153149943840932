import makeStyles from "@mui/styles/makeStyles";
import Refresh from "@mui/icons-material/Refresh";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(({ themeColors }) => ({
  panel: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    align: "center",
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    maxWidth: 150,
    color: themeColors.emptyText,
    marginTop: 15,
  },
  labelSecondary: {
    fontSize: 12,
    fontWeight: 400,
    maxWidth: 170,
    color: themeColors.emptyText,
    marginTop: 5,
  },
  busyIcon: {
    width: "2rem",
    height: "2rem",
    marginBottom: 5,
  },
}));

type EmptyViewProps = {
  isLoading: boolean;
  refreshAction?: () => void;
  loadingTitle?: string;
  isUnavailableProject?: boolean;
  errorTitle?: string;
  errorSubtitle?: string;
};

export default function EmptyView({
  isLoading = false,
  refreshAction,
  isUnavailableProject = false,
  loadingTitle,
  errorTitle = "No Content Loaded",
  errorSubtitle = "Sorry, we are having trouble loading the requested data. Please try again later.",
}: EmptyViewProps) {
  const classes = useStyles();

  return isLoading ? (
    <Grow in timeout={500} key="loading">
      <div className={classes.panel}>
        <CircularProgress className={classes.busyIcon} />
        <Typography
          className={classes.label}
          style={loadingTitle ? { maxWidth: 500 } : {}}
        >
          {loadingTitle || "Loading Content ..."}
        </Typography>
        <Typography className={classes.labelSecondary}>
          Just a moment please, while we load your data.
        </Typography>
      </div>
    </Grow>
  ) : (
    <Grow in timeout={500} key="empty">
      <div className={classes.panel}>
        <Typography className={classes.label}>{errorTitle}</Typography>
        {isUnavailableProject ? (
          <>
            <Typography className={classes.labelSecondary}>
              Sorry, we were unable to load your project data because it is
              either out of date or unavailable.
            </Typography>
            <Button
              style={{ marginTop: 30 }}
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => {
                refreshAction?.();
              }}
            >
              Back
            </Button>
          </>
        ) : (
          <>
            <Typography className={classes.labelSecondary}>
              {errorSubtitle}
            </Typography>
            <Button
              data-testid="test-no-content"
              style={{ marginTop: 30 }}
              variant="outlined"
              startIcon={<Refresh />}
              onClick={() => {
                if (refreshAction) {
                  refreshAction();
                } else {
                  window.location.reload();
                }
              }}
            >
              Retry
            </Button>
          </>
        )}
      </div>
    </Grow>
  );
}
