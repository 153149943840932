import { GRAPH_VALUE_TYPE, TABLE_HEADER_TYPE } from "./constants";

export const formatCellValue = (
  value: number,
  type: GRAPH_VALUE_TYPE,
  decimalPlaces: number = 0
) => {
  const dollarFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  const multiplier = decimalPlaces ? 10 ** decimalPlaces : 1;

  switch (type) {
    case GRAPH_VALUE_TYPE.PERCENT:
      return `${Math.round(value * 100 * multiplier) / multiplier}%`;
    case GRAPH_VALUE_TYPE.PERCENT_NOT_MULTIPLIED:
    case GRAPH_VALUE_TYPE.RATE:
      return `${Math.round(value * multiplier) / multiplier}%`;
    case GRAPH_VALUE_TYPE.PERCENT_NO_SYMBOL:
      return Math.round(value * 100 * multiplier) / multiplier;
    case GRAPH_VALUE_TYPE.DOLLAR:
    case GRAPH_VALUE_TYPE.DOLLARS:
      return dollarFormat.format(Math.round(value));
    case GRAPH_VALUE_TYPE.NUMBER:
    case GRAPH_VALUE_TYPE.UNIT:
      return Math.round(value * multiplier) / multiplier;
    default:
      return value;
  }
};

export const formatModalValue = (value: number, modalType: string) => {
  switch (modalType) {
    case GRAPH_VALUE_TYPE.PERCENT_NOT_MULTIPLIED:
    case GRAPH_VALUE_TYPE.NUMBER:
    case GRAPH_VALUE_TYPE.UNIT:
    case GRAPH_VALUE_TYPE.DOLLAR:
    case GRAPH_VALUE_TYPE.DOLLARS:
      return Math.round(value);
    default:
      return value;
  }
};

const MAX_Y_AXIS_LABEL_LENGTH = 18;

export const formatYAxisTitle = (title: string): string =>
  !!title && title.length > MAX_Y_AXIS_LABEL_LENGTH
    ? `${title.substring(0, MAX_Y_AXIS_LABEL_LENGTH)}…`
    : title;



const EM_DASH = "—";

export const getEntityCellValue = (
  rowData: number[],
  type: GRAPH_VALUE_TYPE
) => {
  if (!rowData || rowData.length === 0) return EM_DASH;

  const value = Number(rowData[rowData.length - 1]);
  const valueType = type === TABLE_HEADER_TYPE.RATE ? "percent" : type;
  return formatCellValue(value, valueType);
};

export const makeWordTitleCase = (word: string): string => {
  const lowerCaseWord = word.toLowerCase();
  return `${lowerCaseWord.charAt(0).toUpperCase()}${lowerCaseWord.slice(1)}`;
};

export const addSpacesBetweenSeparators = (entityName: string): string=>
  entityName
    .replace(/ - /g, '-')
    .replace(/-/g, ' - ')
    .replace(/ \+ /g, '+')
    .replace(/\+/g, ' + ')
    .replace(/ \/ /g, '/')
    .replace(/\//g, ' / ');

export const isWordFSACode = (word: string): boolean => {
  const fSAPattern = /^[A-Za-z]\d[A-Za-z]$/;
  return fSAPattern.test(word);
};

export const formatEntityName = (entityName: string): string => {
  if (!entityName) {
    return entityName;
  }

  const spacedName = addSpacesBetweenSeparators(entityName);
  const words = spacedName.split(/\s+/);

  return words
    .map((word) =>
      isWordFSACode(word) ? word.toUpperCase() : makeWordTitleCase(word)
    )
    .join(' ');
};
