import {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
  RefObject,
} from "react";
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
} from "recyclerlistview/web";
import { styled } from "@mui/material";
import EmptyView from "../../emptyView";
import { EntityListItem, MapListItem } from "./mapListItem";
import { useMapViewContext } from "../../contexts/mapViewContext.ts";
import { EntityMapData, EntityPopoverProps } from "../mapView.tsx";
import {
  EntityRuleInsight,
  ListType,
  MaptualList,
  MaptualListItem,
  MaptualListObjective,
  PrecallInsightWithNone,
  UserCreatedLists,
} from "@odaia/domain/src/types.ts";
import { EntityLlmInsights } from "@odaia/clients/maptualAPI/api.ts";

const StyledMapListContainer = styled("div")(({ theme }) => ({
  width: "400px",
  [theme.breakpoints.down("md")]: {
    width: "280px",
  },
  height: "100%",
  colorScheme: theme.themeColors.colorScheme,
  borderRight: `1px solid ${theme.themeColors.borderPrimaryColor}`,
}));

const StyledMapList = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "calc(100% - 52px)",
}));

const StyledMapListHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  paddingLeft: 16,
  paddingTop: 12,
  marginBottom: 4,
}));
const StyledMapListItemTitle = styled("div")(({ theme: { themeColors } }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: themeColors.primaryTextColor,
}));

const StyledMapListItemSubTitle = styled("div")(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.tertiaryColor,
  })
);

const checkArrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

const elementIsVisibleInViewport = (el, parentTop) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return (
    top >= parentTop &&
    left >= 0 &&
    bottom <= innerHeight &&
    right <= innerWidth
  );
};

type MapListProps = {
  visibleMaptualList: MaptualList["list"];
  visibleMaptualListLoading: boolean;
  selectedMaptualListItem?: MaptualListItem;
  onSelectMaptualListItem: (
    selectedEntity: unknown,
    zoomToEntity?: boolean
  ) => void;
  groupSelected: boolean;
  isPopoverOpen: boolean;
  setIsPopoverOpen: Dispatch<SetStateAction<boolean>>;
  setIsEntityFullModalOpen: Dispatch<SetStateAction<boolean>>;
  objective: MaptualListObjective | null;
  selectedObjectiveIndex: number | null;
  routeEntities: Record<string, unknown>[];
  setRoute: (entities: unknown) => void;
  setIsRouteLoading: Dispatch<SetStateAction<boolean>>;
  EntityPopover?: (props: EntityPopoverProps) => JSX.Element;
  mapContentContainerRef: RefObject<HTMLElement | null>;
  filteredEntityMapData: EntityMapData[];
  onEntityDetailsClick: (entityId: string) => void;
  isSuggestedEntitiesFilterActive?: boolean;
};

export const MapList = ({
  visibleMaptualList: entityList,
  visibleMaptualListLoading,
  selectedMaptualListItem,
  onSelectMaptualListItem,
  groupSelected,
  isPopoverOpen,
  setIsPopoverOpen,
  setIsEntityFullModalOpen,
  objective,
  selectedObjectiveIndex,
  routeEntities,
  setRoute,
  setIsRouteLoading,
  EntityPopover,
  mapContentContainerRef,
  filteredEntityMapData,
  onEntityDetailsClick,
  mapZoom,
  isSuggestedEntitiesFilterActive,
}: MapListProps) => {
  const [entityListData, setEntityListData] = useState<EntityListItem[]>([]);
  const [dataProvider, setDataProvider] = useState(
    new DataProvider((r1, r2) => r1 !== r2)
  );
  const [layoutProvider, setLayoutProvider] = useState<LayoutProvider | null>(
    null
  );
  const [popoverTopPosition, setPopoverTopPosition] = useState(0);
  const divRef = useRef<HTMLDivElement>();

  const {
    entityPrecallInsights,
    isEntityPrecallInsightsLoading,
    entityRuleInsights,
    isEntityRuleInsightsLoading,
    productLineUserCreatedLists,
    entityCuration,
    isEntityCurationLoading,
    anonymize,
    filteredMaptualList,
  } = useMapViewContext();

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  useEffect(() => setPopoverTopPosition(0), [selectedMaptualListItem]);

  useEffect(() => {
    // temp for implementing core
    if (entityList?.items?.length) {
      setEntityListData(entityList.items);
      return;
    }

    const getEntityInsightToDisplay = (
      entityId: string,
      entityPrecallInsights: EntityLlmInsights[] | undefined,
      entityRuleInsights: Record<string, EntityRuleInsight> | undefined
    ): PrecallInsightWithNone | null => {
      const entityPrecallInsight =
        entityPrecallInsights && entityPrecallInsights.length > 0
          ? entityPrecallInsights?.find(
              (entity) => entity.entityId.toString() === entityId
            )
          : null;
      const precallInsightToDispaly = entityPrecallInsight?.insights?.reduce(
        function (prev, current) {
          return prev && prev.priority < current.priority ? prev : current;
        }
      );

      const ruleInsight: PrecallInsightWithNone | null = entityRuleInsights?.[
        entityId
      ]
        ? {
            title: entityRuleInsights[entityId].title,
            description: entityRuleInsights[entityId].message,
            type: "None",
            priority: 1,
          }
        : null;
      return precallInsightToDispaly || ruleInsight || null;
    };

    if (
      isEntityPrecallInsightsLoading ||
      isEntityRuleInsightsLoading ||
      isEntityCurationLoading ||
      !entityList?.items
    )
      return;

    const listData: EntityListItem[] = [];
    const filteredEntityMapDataMapping = filteredEntityMapData.reduce<
      Record<string, string | undefined>
    >((acc, item) => {
      acc[item.entityId] = item?.address;
      return acc;
    }, {});
    for (const item of entityList.items) {
      if (!item || !item.entityId) continue;
      const insight =
        item.insight ??
        getEntityInsightToDisplay(
          item.entityId,
          entityPrecallInsights,
          entityRuleInsights
        );
      const entityListItem: EntityListItem = {
        primaryText: item.primaryText,
        secondaryText: item.secondaryText,
        maptualDisplayScore: item.maptualDisplayScore,
        maptualScoreChange: item.maptualScoreChange,
        objectiveScores: item.objectiveScores || [item.maptualDisplayScore],
        insight,
        entityId: item.entityId,
        segments: item.segments,
        emailOptOut: item.emailOptOut,
        customListMembership: getCustomListMembership(
          item.entityId,
          productLineUserCreatedLists
        ),
        address: filteredEntityMapDataMapping[item.entityId],
        curationRank: entityCuration?.[item.entityId],
        coordinates: item?.coordinates,
        customerTarget: item?.customerTarget,
      };
      listData.push(entityListItem);
    }
    setEntityListData(listData);
  }, [
    entityPrecallInsights,
    entityRuleInsights,
    entityList,
    entityCuration,
    filteredMaptualList,
  ]);

  const isLoading =
    visibleMaptualListLoading ||
    !entityList?.items ||
    isEntityPrecallInsightsLoading ||
    isEntityCurationLoading;

  const selectedEntityData = entityListData?.find(
    (item) =>
      item.entityId === selectedMaptualListItem?.entityId
  );

  const scrollToSelectedEntity = (entityData) => {
    const entityElement = document.getElementById(
      `map-list-item-${entityData.entityId}`
    );
    if (!entityElement) return;
    const { top: parentTop } =
      entityElement.parentElement.getBoundingClientRect();
    if (!elementIsVisibleInViewport(entityElement, parentTop)) {
      entityElement.scrollIntoView();
    }
    const { top } = entityElement.getBoundingClientRect();
    const topToParent = top - parentTop;
    if (top && popoverTopPosition !== topToParent) {
      setPopoverTopPosition(topToParent);
    }
  };

  if (selectedEntityData && !isLoading) {
    scrollToSelectedEntity(selectedEntityData);
  }

  useEffect(() => {
    const newEntityListData = entityListData.slice();
    if (selectedMaptualListItem) {
      const activeIndex = entityListData.findIndex(
        (item) =>
          item.entityId === selectedMaptualListItem.entityId
      );

      newEntityListData[activeIndex] = {
        ...entityListData[activeIndex],
        active: true,
      };
    }
    setDataProvider(dataProvider.cloneWithRows(newEntityListData));
    if (newEntityListData.length > 0) {
      setLayoutProvider(
        new LayoutProvider(
          (index) => {
            let heightIncrement = 0;
            const padding = 16;
            const isMultiObjective =
              newEntityListData[index].objectiveScores.length > 0;
            const hasPrecallInsights =
              !!newEntityListData[index]?.insight?.title;
            const hasIcons =
              Object.values(
                newEntityListData[index].customListMembership || {}
              ).some((_) => _) || newEntityListData[index].emailOptOut === 0;
            if (isMultiObjective) {
              heightIncrement += 32 + padding;
            }
            if (hasPrecallInsights) {
              heightIncrement += 24;
            }
            if (hasIcons) {
              heightIncrement += 24 + padding;
            }
            return heightIncrement;
          },
          (type, dim) => {
            const baseHeight = 127;
            dim.width = 385;
            dim.height = baseHeight + (type as number);
          }
        )
      );
    }
  }, [entityListData, selectedMaptualListItem]);

  const isPopoverDisplayable =
    !isLoading &&
    isPopoverOpen &&
    selectedEntityData?.entityId &&
    mapZoom > 14;

  const getCustomListMembership = (
    entityId: string,
    userCreatedListEntities: UserCreatedLists | undefined
  ) => {
    const customListMembership: Record<ListType, boolean> = {
      noSee: false,
      starred: false,
      longTermLeave: false,
    };
    if (!userCreatedListEntities) {
      return customListMembership;
    }
    for (const key of Object.keys(userCreatedListEntities) as ListType[]) {
      if (userCreatedListEntities[key].includes(Number(entityId))) {
        customListMembership[key] = true;
      }
    }
    return customListMembership;
  };

  return (
    <StyledMapListContainer ref={divRef}>
      {isLoading && <EmptyView isLoading />}
      {!isLoading && (
        <>
          <StyledMapListHeader>
            <StyledMapListItemTitle>
              {groupSelected ? "Selection" : "All"}
            </StyledMapListItemTitle>
            <StyledMapListItemSubTitle>
              {entityList?.items.length}
            </StyledMapListItemSubTitle>
          </StyledMapListHeader>
          {layoutProvider &&
            entityListData.length > 0 &&
            dataProvider._data.length && (
              <StyledMapList>
                <RecyclerListView
                  forceNonDeterministicRendering
                  key={routeEntities.map((e) => e.entityId).join()}
                  suppressBoundedSizeException={true}
                  dataProvider={dataProvider}
                  layoutProvider={layoutProvider}
                  rowRenderer={(type, data) => (
                    <MapListItem
                      item={data}
                      isSelected={
                        selectedMaptualListItem?.entityId ===
                        data.entityId
                      }
                      customListMembership={getCustomListMembership(
                        data.entityId,
                        productLineUserCreatedLists
                      )}
                      onSelectMaptualListItem={onSelectMaptualListItem}
                      key={data.entityId}
                      selectedObjectiveIndex={selectedObjectiveIndex}
                      routeEntities={routeEntities}
                      setRoute={setRoute}
                      setIsRouteLoading={setIsRouteLoading}
                      objective={objective}
                      anonymize={anonymize}
                      showRank={isSuggestedEntitiesFilterActive}
                      customerTarget={data.customerTarget}
                    />
                  )}
                />
              </StyledMapList>
            )}
          {!entityListData ||
            (entityListData.length === 0 && (
              <StyledMapList>
                <StyledMapListHeader>
                  <StyledMapListItemSubTitle>
                    No HCPs found. Try adjusting your filters or navigating to a
                    different area.
                  </StyledMapListItemSubTitle>
                </StyledMapListHeader>
              </StyledMapList>
            ))}
        </>
      )}
      {isPopoverDisplayable && EntityPopover && (
        <EntityPopover
          isOpen={isPopoverDisplayable}
          anchorEl={divRef.current || undefined}
          setIsEntityFullModalOpen={setIsEntityFullModalOpen}
          objective={objective}
          entityData={selectedEntityData}
          topPosition={popoverTopPosition}
          handleClose={handleClose}
          routeEntities={routeEntities}
          setRoute={setRoute}
          setIsRouteLoading={setIsRouteLoading}
          mapContentContainerRef={mapContentContainerRef}
          onEntityDetailsClick={onEntityDetailsClick}
        />
      )}
    </StyledMapListContainer>
  );
};
