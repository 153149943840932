import { useContext } from "react";
import {
  Badge,
  styled,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { Search } from "@mui/icons-material";
import { ObjectiveSelector } from "../objectiveSelector";
import { RegionSelection } from "../../../maptual/src/containers/application/appViews/field/navigation/regionSelection/RegionSelection";
import { SuggestedEntitiesButton } from "./SuggestedEntitiesButton";
import { MapToggle } from "../../../maptual/src/containers/application/appViews/field/map/mapToggle";

const Container = styled("div")(({ showRegion }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "16px 16px 0px 16px",
  gap: "16px",
  marginTop: showRegion ? 48 : 12,
}));

const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  display: "block",
  height: "100%",
  width: "56px",
  "& .MuiBadge-badge": {
    backgroundColor: themeColors.notificationBadgeSurface,
    color: themeColors.notificationBadgeContent,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.buttonContentColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: "4px",
  boxShadow: themeColors.boxShadowLevelOne,
  "&:hover": {
    backgroundColor: themeColors.inputBackgroundHover,
    borderColor: themeColors.buttonBorderColor,
  },
  padding: "8px 12px",
}));

const ControlsEnd = styled("div")(({ showRegion }) => ({
  marginLeft: "auto",
  height: 40,
  marginTop: showRegion ? "-42px" : "0px",
  gap: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
}));

const StyledSearchContainer = styled(FormControl)(() => ({
  height: "42px",
  width: "350px",
  minWidth: "150px",
}));

export const ControlBar = ({
  mergedMaptualList,
  showCurationData,
  setSearch,
  activeFilterCount,
  setIsFilterDrawerOpen,
  isSuggestedEntities,
  setIsSuggestedEntities,
  maptualListMetadata,
  objective,
  setObjective,
  maptualListObjectives,
  pageToggle = null,
}) => {
  const anonymize = false;
  return (
    <Container showRegion={!pageToggle}>
      {mergedMaptualList?.list?.items.length > 0 && (
        <StyledSearchContainer>
          <OutlinedInput
            sx={{ height: "100%" }}
            onChange={(e) => setSearch(e.target.value)}
            id="search"
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </StyledSearchContainer>
      )}
      {mergedMaptualList?.list?.items.length > 0 && (
        <StyledBadge badgeContent={activeFilterCount}>
          <StyledIconButton
            data-testid="filter-button"
            onClick={() => setIsFilterDrawerOpen()}
          >
            <TuneIcon />
          </StyledIconButton>
        </StyledBadge>
      )}
      {showCurationData && (
        <SuggestedEntitiesButton
          isActive={isSuggestedEntities}
          handleClick={() => setIsSuggestedEntities()}
        />
      )}
      {maptualListObjectives?.length > 1 && objective?.id && (
        <ObjectiveSelector
          updateFilterObjective={(objectiveId) =>
            setObjective(
              maptualListObjectives.find((o) => o.id === objectiveId)
            )
          }
          objectiveId={objective?.id}
          currentObjectives={maptualListObjectives}
          minWidth="275px"
        />
      )}
      <ControlsEnd showRegion={!pageToggle}>
        {maptualListMetadata?.listName && objective?.id && (
          <RegionSelection
            regionName={
              anonymize
                ? `Territory ${String(
                    maptualListMetadata?.sfMaptualListId
                  ).slice(-3)}`
                : maptualListMetadata?.listName
            }
            anonymizeRegions={anonymize}
          />
        )}
        {pageToggle}
        {!pageToggle && <MapToggle />}
      </ControlsEnd>
    </Container>
  );
};
