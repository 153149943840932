import { useModuleConfigurations } from '~/hooks/useModuleConfigurations';
import { EntityType, ParamType } from '@odaia/clients/maptualAPI';
import { useObjectiveBaskets } from '~/containers/application/appViews/field/hooks/useObjectiveBaskets';
import { OpportunityCard } from './opportunity';
import { MarketPerformanceCard } from './marketPerformance';
import { ProductPerformanceCard } from './productPerformance';

const hasValidMarketBasket = (objectiveBasket): boolean => {
  const competitorBasket = objectiveBasket[ParamType.ProductFrom];

  if (!competitorBasket || competitorBasket.products.length === 0) return false;

  const hasContinuousMetric: boolean = competitorBasket.metrics.some(
    (metric) => metric.dataType === 'continuous' && metric.displayPriority
  );

  return hasContinuousMetric;
};

const hasValidOpportunityBasket = (objectiveBasket): boolean => {
  const validOpportunityBaskets = [
    ParamType.AnalogueProduct,
    ParamType.PrecursorProduct,
    ParamType.UnderlyingProduct,
  ];

  const hasAtLeastOneBasketWithProducts = validOpportunityBaskets.some(
    (basketType) => {
      const basket = objectiveBasket[basketType];
      return (
        basket &&
        basket.products.length > 0 &&
        basket.metrics.some(
          (metric) => metric.dataType === 'continuous' && metric.displayPriority
        )
      );
    }
  );

  return hasAtLeastOneBasketWithProducts;
};

export const EntityCharts = ({
  userGroup,
  entityId,
  snowflakeMaptualListId,
  projectId,
  activeObjective,
  marketId,
}: {
  userGroup: string;
  entityId: number;
  snowflakeMaptualListId: string;
  projectId: string;
  activeObjective: any;
  marketId: string;
}) => {
  const { data: entityChartsConfig } = useModuleConfigurations({
    schemaName: 'entityCharts',
    marketId,
    userGroup,
    useDefaults: true,
  });

  const { basketsByObjective } = useObjectiveBaskets({
    projectId,
    entityType: EntityType.Hcp,
  });

  const objectiveBasket =
    basketsByObjective?.[activeObjective?.objectiveId] ?? {};

  return (
    <>
      {entityChartsConfig?.showProductPerformanceChart && (
        <ProductPerformanceCard
          projectId={projectId}
          regionId={snowflakeMaptualListId}
          objectiveId={activeObjective?.objectiveId}
          entityId={entityId}
        />
      )}

      {hasValidMarketBasket(objectiveBasket) && (
        <MarketPerformanceCard
          projectId={projectId}
          regionId={snowflakeMaptualListId}
          objectiveId={activeObjective?.objectiveId}
          entityId={entityId}
        />
      )}

      {hasValidOpportunityBasket(objectiveBasket) && (
        <OpportunityCard
          projectId={projectId}
          regionId={snowflakeMaptualListId}
          objectiveId={activeObjective?.objectiveId}
          entityId={entityId}
        />
      )}
    </>
  );
};
