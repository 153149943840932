import { Box, styled, Typography } from "@mui/material";
import { PowerScore, PowerScoreProps } from "../../powerScore";

export interface PowerScoreCellProps extends PowerScoreProps {
  label?: string | null;
}

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "100%",
  paddingLeft: 10,
});

export const PowerScoreCell = ({
  label = null,
  ...props
}: PowerScoreCellProps) => {
  return (
    <Wrapper>
      <PowerScore
        style={{flexDirection: 'row'}}
        {...props}
      />
      {label && <Typography variant="title3" ml={1}>{label}</Typography>}
    </Wrapper>
  );
};
