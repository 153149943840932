import { useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getComparisonTimeFrames } from '~/containers/territoryOverview/utils';
import { TerritoryOverviewContext } from '../territoryOverviewContext';

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width = '' }) => ({
  width,
}));

const BaseSelector = ({
  handleDropdownSelect,
  options = [],
  selected,
  testId = '',
  disabled = false,
  width = '237px',
}) => (
  <StyledSelect
    variant="outlined"
    key={selected}
    value={selected}
    IconComponent={KeyboardArrowDownIcon}
    data-testid={testId}
    disabled={disabled}
    width={width}
    MenuProps={{
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    }}
  >
    {options.map((item) => (
      <MenuItem
        key={item.id}
        value={item.id}
        onClick={() => handleDropdownSelect(item.id)}
        variant="outlined"
      >
        {item.label || ''}
      </MenuItem>
    ))}
  </StyledSelect>
);

export const ObjectiveSelector = () => {
  const { objectiveOptions, updateSelectedObjective, selectedObjective } =
    useContext(TerritoryOverviewContext);

  const handleDropdownSelect = (objectiveId) => {
    updateSelectedObjective(objectiveId);
  };

  if (!selectedObjective) return null;

  return (
    <BaseSelector
      handleDropdownSelect={handleDropdownSelect}
      options={Object.values(objectiveOptions)}
      selected={selectedObjective}
      testId="objective-selector"
    />
  );
};

export const MetricSelector = () => {
  const { metricOptions, selectedMetric, updateSelectedMetric } = useContext(
    TerritoryOverviewContext
  );

  const handleDropdownSelect = (metricId) => {
    updateSelectedMetric(metricId);
  };

  if (!selectedMetric) return null;

  if (Object.keys(metricOptions).length <= 1) return null;

  return (
    <BaseSelector
      handleDropdownSelect={handleDropdownSelect}
      options={Object.values(metricOptions)}
      selected={selectedMetric}
      testId="metric-selector"
    />
  );
};

export const ComparisonTimeFrameSelector = () => {
  const {
    selectedTimeframeID,
    comparisonTimeFrameId,
    setComparisonTimeFrameId,
  } = useContext(TerritoryOverviewContext);

  const handleDropdownSelect = (comparisonTimeFrameId) => {
    setComparisonTimeFrameId(comparisonTimeFrameId);
  };

  const comparisonTimeFrames = getComparisonTimeFrames(selectedTimeframeID);

  return (
    <BaseSelector
      handleDropdownSelect={handleDropdownSelect}
      options={comparisonTimeFrames}
      selected={comparisonTimeFrameId}
      testId="time-frame-selector"
      disabled={comparisonTimeFrames.length === 1}
      width="304px"
    />
  );
};
