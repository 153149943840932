import { Box } from "@mui/system";
import { useState } from "react";
import {
  ContributionBreakdownResponse,
  EntityType,
} from "@odaia/clients/maptualAPI";
import { PowerScoreBar } from "./PowerScoreBar";
import { ContributionBreakdown } from "./ContributionBreakdown";
import { CONTRIBUTION_TYPE } from "./constants";
import { ExplainabilityInsights } from "./ExplainabilityInsights";

export const EntityPowerscoreExplainability = ({
  powerScore,
  contributionBreakdown,
  entityType,
  insights,
}: {
  powerScore: number;
  contributionBreakdown: ContributionBreakdownResponse;
  entityType: EntityType;
  insights: object;
}) => {
  const [expanded, setExpanded] = useState<CONTRIBUTION_TYPE | false>(false);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <ExplainabilityInsights insights={insights} />
      <PowerScoreBar
        currentValue={contributionBreakdown.currentValue.value ?? 0}
        potentialValue={contributionBreakdown.potential.value ?? 0}
        powerScore={powerScore}
        expanded={expanded}
      />
      <ContributionBreakdown
        contributionData={contributionBreakdown}
        expanded={expanded}
        setExpanded={setExpanded}
        entityType={entityType}
      />
    </Box>
  );
};
