import { Box, styled } from "@mui/material";
import { ListType, UserCreatedLists } from "@odaia/domain/src/types";
import { userCreatedListOptions } from "../../userCreatedListsConfig";
import { MarkEmailReadOutlined } from "@mui/icons-material";
import { BadgeContainer } from "../../BadgeContainer";

const PropertiesIcon = styled("div")(({ theme: { themeColors } }) => ({
  svg: {
    color: themeColors.generalSecondaryColor,
    width: "20px",
    height: "18px",
  },
  display: "flex",
  alignItems: "center",
}));

const customListOrder: ListType[] = ["noSee", "starred", "longTermLeave"];

type Props = {
  isEmailOptOut: boolean;
  customListMembership: Record<keyof UserCreatedLists, boolean>;
  entitySegments: {
    id: number;
    title: string;
    isHistoricalMember: boolean;
    isPredictedMember: boolean;
  }[];
  isSelected?: boolean;
};

export const EntityAttributes = ({
  customListMembership,
  entitySegments,
  isEmailOptOut,
  isSelected,
}: Props) => {
  const hasIcons =
    !isEmailOptOut || Object.values(customListMembership || {}).some((_) => _);
  return (
    <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
      {hasIcons && (
        <Box display="flex">
          {!isEmailOptOut && (
            <PropertiesIcon>
              <MarkEmailReadOutlined />
            </PropertiesIcon>
          )}
          {customListOrder.map((listName) => {
            const Icon = userCreatedListOptions[listName].icon.outlined;
            return customListMembership?.[listName] ? (
              <PropertiesIcon key={listName}>
                <Icon />
              </PropertiesIcon>
            ) : null;
          })}
        </Box>
      )}
      <BadgeContainer
        content={entitySegments}
        maxItems={2}
        wrap={false}
        isSelected={isSelected}
      />
    </Box>
  );
};
