import { TableComponentProps } from "../callPlan";
import { EntitiesTable } from "./EntitiesTable";

export const TableComponent = (props: TableComponentProps) => (
  <EntitiesTable
    {...props}
    refetch={() => {}}
    isFetching={false}
    shouldShowError={false}
  />
);
