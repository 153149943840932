import InsightsCardRow from '../entityInsights/insightsCardRow';
import InsightsSegments from '../entityInsights/insightsSegments';
import { InsightsChannels } from '../entityInsights/insightsChannels';
import { InsightsPrecall } from '../entityInsights/insightsPrecall';
import { InsightsTopics } from '../entityInsights/insightsTopics';
import { InsightsWarningCard } from '../entityInsights/insightsWarningCard';

import useFeatureToggles from '../../../hooks/useFeatureToggles';

import { CategoricalDataWrapper } from '../entityCategoricalData/categoricalDataWrapper';
import { EntityMessages } from '../entityMessages/entityMessages';
import { useEntityTopAttribution } from '../../../containers/fieldview/useEntityTopAttribution';
import { EntityCharts } from '../entityCharts';

export const AmaOptInSection = ({
  userGroup,
  marketId,
  entity,
  entityId,
  projectId,
  snowflakeMaptualListId,
  activeObjective,
}) => {
  const featureToggles = useFeatureToggles();

  const metadata = entity?.metadata || {};
  const isNoSee = entity?.metadata?.listMemberships?.noSee || false;
  const isLongTermLeave =
    entity?.metadata?.listMemberships?.longTermLeave || false;

  const { topAttributionData, isTopAttributionLoading } =
    useEntityTopAttribution({
      projectId,
      entityId,
    });

  return (
    <>
      {isNoSee && (
        <InsightsWarningCard
          name={metadata.entityName}
          userCreatedListType="No-See"
        />
      )}
      {isLongTermLeave && (
        <InsightsWarningCard
          name={metadata.entityName}
          userCreatedListType="Long-term Leave"
        />
      )}

      <InsightsChannels
        maptualTopChannels={topAttributionData?.maptualTopChannels || []}
        isLoading={isTopAttributionLoading}
      />

      {featureToggles(userGroup, 'showPrecallInsights') && (
        <InsightsPrecall
          entityId={entityId}
          objective={activeObjective}
          projectId={projectId}
          regionId={snowflakeMaptualListId}
        />
      )}

      {featureToggles(userGroup, 'showTopicsInsights') && (
        <InsightsTopics
          maptualTopTopics={topAttributionData?.maptualTopMessages || []}
          isLoading={isTopAttributionLoading}
        />
      )}

      <EntityMessages
        selectedObjective={activeObjective}
        entityId={entityId}
        projectId={projectId}
      />

      <CategoricalDataWrapper
        entityId={entityId}
        projectId={projectId}
        objectiveId={activeObjective?.objectiveId}
      />

      <InsightsCardRow objectiveCards={activeObjective?.cards || []} />

      <InsightsSegments objectiveCards={activeObjective?.cards || []} />

      <EntityCharts
        userGroup={userGroup}
        entityId={entity?.metadata?.entityId}
        snowflakeMaptualListId={snowflakeMaptualListId}
        projectId={projectId}
        activeObjective={activeObjective}
        marketId={marketId}
      />
    </>
  );
};
