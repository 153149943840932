import { useTheme } from "@mui/styles";
import { TrendIcon } from "../TrendIcon";
import { COLOR_STATES, PREDICTION_PRECISION, TREND_TYPES } from "./constants";

export const roundPercentage = (number: number): string | number => {
  if (Math.abs(number) > 0 && Math.abs(number) < 1) {
    return "< 1";
  }
  return Math.round(number);
};

interface TrendColors {
  primary: COLOR_STATES;
  secondary: COLOR_STATES;
}

export const getColors = ({
  type,
  status,
  value,
}: {
  type: TREND_TYPES;
  status: PREDICTION_PRECISION;
  value: number;
}): TrendColors => {
  const { themeColors } = useTheme();
  const primaryColorMap = {
    [COLOR_STATES.NEUTRAL]: themeColors.primaryTextColor,
    [COLOR_STATES.POSITIVE]: themeColors.increaseColorMainDefaultContent,
    [COLOR_STATES.NEGATIVE]: themeColors.decreaseColorMainDefaultContent,
  };
  const secondaryColorMap = {
    [COLOR_STATES.NEUTRAL]: "inherit",
    [COLOR_STATES.POSITIVE]: themeColors.increaseColorMainDefault,
    [COLOR_STATES.NEGATIVE]: themeColors.decreaseColorMainDefault,
  };

  const colorState = getColorState({ type, status, value });

  if (!colorState) {
    return { primary: colorState, secondary: colorState };
  }
  return {
    primary: primaryColorMap[colorState],
    secondary: secondaryColorMap[colorState],
  };
};

export const getColorState = ({
  type,
  status,
  value,
}: {
  type: TREND_TYPES;
  status: PREDICTION_PRECISION;
  value: number;
}): COLOR_STATES => {
  if (type === TREND_TYPES.VALUE) return COLOR_STATES.NEUTRAL;
  // if no value, but instead bad prediction status, use that instead
  if (value === null) {
    switch (status) {
      case PREDICTION_PRECISION.CANNOT_PREDICT:
        return COLOR_STATES.NEUTRAL;
      case PREDICTION_PRECISION.INCREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return COLOR_STATES.POSITIVE;
      case PREDICTION_PRECISION.DECREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return COLOR_STATES.NEGATIVE;
      default:
        return COLOR_STATES.UNKNOWN;
    }
  }

  if (value === 0) return COLOR_STATES.NEUTRAL;
  if (value > 0) return COLOR_STATES.POSITIVE;
  if (value < 0) return COLOR_STATES.NEGATIVE;
  return COLOR_STATES.UNKNOWN;
};

const getIncrDecrText = ({ value, status }: {
  value: number;
  status: PREDICTION_PRECISION;
}): string => {
  // if no value, but instead bad prediction status, use that instead
  if (value === null) {
    switch (status) {
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return "INCR";
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return "DECR";
      default:
        return "";
    }
  }
  if (value > 0) return "INCR";
  if (value < 0) return "DECR";
  return "";
};

const Arrow = ({ value, status, collapsed }: {
  value: number;
  status: PREDICTION_PRECISION;
  collapsed: boolean;
}) => {
  const upArrow = (
    <TrendIcon collapsed={collapsed} testId="trend-up-arrow" direction="up" />
  );

  const downArrow = (
    <TrendIcon
      collapsed={collapsed}
      testId="trend-down-arrow"
      direction="down"
    />
  );

  if (value === null) {
    switch (status) {
      case PREDICTION_PRECISION.INCREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE:
        return upArrow;
      case PREDICTION_PRECISION.DECREASING_WITHIN_RANGE:
      case PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE:
        return downArrow;
      default:
        return null;
    }
  }

  if (value > 0) return upArrow;
  if (value < 0) return downArrow;
  return null;
};

export const transformValue = ({ type, status, useZero, value }: {
  type: TREND_TYPES;
  status: PREDICTION_PRECISION;
  useZero: boolean;
  value: number;
}) => {
  if (
    !useZero &&
    value === 0 &&
    (status === PREDICTION_PRECISION.CANNOT_PREDICT ||
      status === PREDICTION_PRECISION.NO_CHANGE)
  ) {
    return null;
  }

  switch (type) {
    case TREND_TYPES.TREND:
    case TREND_TYPES.TREND_CHANGE:
      return value === null
        ? null
        : `${roundPercentage(Math.abs(value) * 100)}%`;
    case TREND_TYPES.TREND_ONLY:
    case TREND_TYPES.DIRECTION_ONLY:
      return getIncrDecrText({ value, status });
    default:
      return value;
  }
};

export const getIcon = ({
  type,
  status,
  value,
  useZero,
  collapsed,
  checkNull = false,
}: {
  type: TREND_TYPES;
  status: PREDICTION_PRECISION;
  value: number;
  useZero: boolean;
  collapsed: boolean;
  checkNull?: boolean;
}) => {
  if (
    ((!useZero && value === 0) || (checkNull && value === null)) &&
    (status === PREDICTION_PRECISION.CANNOT_PREDICT ||
      status === PREDICTION_PRECISION.NO_CHANGE)
  ) {
    return "—";
  }

  switch (type) {
    case TREND_TYPES.TREND:
    case TREND_TYPES.TREND_ONLY:
    case TREND_TYPES.TREND_CHANGE:
    case TREND_TYPES.DIRECTION_ONLY:
      return Arrow({ value, status, collapsed });
    default:
      return null;
  }
};

export const getTrendValue = (historic: number, current: number) => ({
  status:
    (current - historic) / historic > 0
      ? 2
      : (current - historic) / historic < 0
      ? 0
      : 1,
  value: historic
    ? (current - historic) / historic
    : !historic && !current
    ? 0
    : null,
  type: !historic && current > historic ? 'DIRECTION_ONLY' : 'TREND_CHANGE',
});

export function getColorFromTrend({ status, value }, themeColors) {
  if (
    status === PREDICTION_PRECISION.INCREASING_WITHIN_RANGE ||
    status === PREDICTION_PRECISION.INCREASING_OUTSIDE_RANGE
  )
    return themeColors.increaseColorMain;
  if (
    status === PREDICTION_PRECISION.DECREASING_WITHIN_RANGE ||
    status === PREDICTION_PRECISION.DECREASING_OUTSIDE_RANGE
  )
    return themeColors.decreaseColorMain;
  if (
    status === PREDICTION_PRECISION.NO_CHANGE ||
    status === PREDICTION_PRECISION.CANNOT_PREDICT
  )
    return themeColors.tertiaryColor;

  if (typeof value !== 'number' || value === 0)
    return themeColors.tertiaryColor;
  return value > 0
    ? themeColors.increaseColorMain
    : themeColors.decreaseColorMain;
}
