import { useQuery } from 'react-query';
import { InsightGroup } from '@odaia/clients/maptualAPI';
import { fieldApi } from '../../../../request/maptualApiClient';

export const useEntityPowerscoreInsights = ({
  entityId,
  projectId,
  regionId,
  objectiveId,
  shouldFetch = true,
}: {
  entityId: string;
  projectId: string;
  regionId: string;
  objectiveId: string;
  shouldFetch: boolean;
}) =>
  useQuery({
    queryKey: [
      'entity-powerscore-insights',
      entityId,
      projectId,
      regionId,
      objectiveId,
    ],
    queryFn: async () => {
      const response = await fieldApi.getEntityLLMInsights({
        entityId,
        projectId,
        regionId,
        objectiveId,
        insightsGroup: InsightGroup.PowerscoreExplainability,
      });
      return response.data?.[0] ?? {};
    },
    enabled:
      !!entityId && !!projectId && !!regionId && !!objectiveId && shouldFetch,
  });
