import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getObjectiveProducts } from '@odaia/shared-components/src/utils';
import { PulseContext } from '../../../../../context/PulseContext';
import { ProgressCircle } from '../../../../../../../../shared/graphs/circleGraph/progressCircle';
import { useRegionCrmData } from '../../../../salesReps/repTable/table/row/hooks/useRegionCrmData';

export const CircularProgressProvider = ({
  objectiveId,
  cadence,
  timestamp,
}) => {
  const { projectId, territoryId } = useParams();
  const { productLineId, projectTargetProducts } = useContext(PulseContext);
  const [curatedEntityCount, setCuratedEntityCount] = useState();
  const [curatedCallCount, setCuratedCallCount] = useState();

  const { data, isLoading } = useRegionCrmData({
    productLineId,
    projectId,
    objectiveId,
    regionId: territoryId.split('?')[0], // todo: fix with routing,
    cadence,
    timestamp,
    products: getObjectiveProducts(projectTargetProducts, objectiveId),
  });

  useEffect(() => {
    if (data?.calls?.curated && data?.metadata?.lists) {
      const timestamps = Object.keys(data.calls.curated);
      const lastTimestamp = timestamps[timestamps.length - 1];
      if (
        !data.metadata.lists?.[lastTimestamp]?.total ||
        !data.calls.curated?.[lastTimestamp]?.total
      ) {
        return;
      }
      setCuratedEntityCount(data.metadata.lists[lastTimestamp].total);
      setCuratedCallCount(data.calls.curated[lastTimestamp].total);
    }
  }, [data]);

  const formattedPercentage = () =>
    curatedEntityCount
      ? Math.round((curatedCallCount / curatedEntityCount) * 100)
      : 0;

  const valueDescription = `${
    curatedEntityCount ? `${curatedCallCount}/${curatedEntityCount}` : 0
  } HCPs`;

  return (
    <ProgressCircle
      title="Curated HCPs Called"
      subtitle="% of Curated HCPs Called"
      progressValue={`${formattedPercentage()}%`}
      valueDescription={valueDescription}
      isLoading={isLoading}
    />
  );
};
