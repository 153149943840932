import { AutoAwesomeOutlined } from "@mui/icons-material";
import { Box, List, Popper, styled, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { ClickableTooltip } from "./ClickableTooltip";

const Title = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.menuTextColor,
}));

const SubTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
}));

const InsightList = styled(List)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  listStylePosition: "outside",
  listStyle: "unset",
  marginLeft: "1em",
  fontSize: 14,
}));

const StyledTooltipPopper = styled(Popper)(({ theme: { themeColors } }) => ({
  ".MuiTooltip-tooltip": {
    backgroundColor: themeColors.popoverBackground,
    color: themeColors.tertiaryColor,
    border: `1px solid ${themeColors.borderPrimaryColor}`,
    fontSize: 14,
    fontWeight: 400,
    padding: 16,
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.24)",
  },
  '&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
    marginTop: 6,
  },
}));

const Wrapper = styled(Box)({
  svg: {
    width: 16,
    height: 16,
    marginRight: 4,
    marginBottom: -2,
  },
});

const MARKDOWN_COMPONENTS = {
  h3: (props) => <Title variant="title3">{props.children}</Title>,
  p: (props) => <SubTitle variant="body2">{props.children}</SubTitle>,
  ul: (props) => <InsightList variant="body2">{props.children}</InsightList>,
  code: () => <AutoAwesomeOutlined />,
};

const ICON_STRING = "```AutoAwesomeOutlined```";

const TOOLTIP_TEXT =
  "This summary is generated by a large language model (LLM). While the data is accurate, " +
  "interpretations of the data provided by the LLM may not always be perfect. Your feedback helps us improve.";

export const ExplainabilityInsights = ({ insights }) => {
  if (!(insights?.insights?.length > 0)) return null;

  return (
    <Wrapper>
      <ClickableTooltip
        key="llm-insights"
        title={TOOLTIP_TEXT}
        placement="bottom"
        PopperComponent={StyledTooltipPopper}
      >
        <ReactMarkdown components={MARKDOWN_COMPONENTS}>
          {/*
            To allow the markdown component to render the icon inline
            with the rest of the text, override the code markdown
            rendering to return an icon based on the text passed.
          */}
          {`${ICON_STRING}${insights.insights[0]?.description}`}
        </ReactMarkdown>
      </ClickableTooltip>
    </Wrapper>
  );
};
