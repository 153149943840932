import { Box, styled, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const EM_DASH = "—";

export const ProgressCell = ({
  numerator,
  denominator,
  percentageOnly = false,
}: ProgressCellProps) => {
  const formattedPercentage = () => {
    if (!numerator && !denominator) {
      return EM_DASH;
    }

    if (!denominator || numerator === undefined) {
      return 0;
    }

    return Math.round((numerator / denominator) * 100);
  };

  const percentage = formattedPercentage();

  return (
    <Container>
      <GraphContainer>
        <BorderLinearProgress
          variant="determinate"
          value={percentage === EM_DASH ? 0 : percentage}
        />
      </GraphContainer>
      <Box>
        <Percentage variant="body1">{`${
          percentage === EM_DASH ? percentage : `${percentage}%`
        }`}</Percentage>
        {!percentageOnly && (
          <SecondaryRow variant="subtitle1">
            {!numerator && !denominator
              ? EM_DASH
              : `${numerator || 0}/${denominator}`}
          </SecondaryRow>
        )}
      </Box>
    </Container>
  );
};

interface ProgressCellProps {
  numerator: number;
  denominator: number;
  percentageOnly?: boolean;
}

const Container = styled(Box)(() => ({
  display: "flex",
  gap: 8,
}));

const GraphContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme: { themeColors } }) => ({
    height: 8,
    width: 56,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: themeColors.progressBarBackground,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: themeColors.progressBar,
    },
  })
);

const Percentage = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.progressBarPrimaryMetric,
}));

const SecondaryRow = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.progressBarSecondaryMetric,
}));
