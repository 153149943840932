import { useQuery } from 'react-query';
import { coreApi } from '../../../../request/maptualApiClient';

export const useCustomerTargetSegments = ({ productLineId, regionId }) => {
  const fetchCustomerTargetSegments = async () => {
    try {
      if (productLineId && regionId) {
        const params = {
          productLineId,
          regionId,
        };
        const response = await coreApi.getCustomerTargetSegmentsForRegion(
          params
        );
        return response?.data;
      }
      return {};
    } catch (error) {
      return {};
    }
  };

  const { isFetching, data, isError, error, isLoading } = useQuery({
    queryKey: ['customer-target-segments', productLineId, regionId],
    queryFn: fetchCustomerTargetSegments,
  });

  return {
    isFetching,
    isLoading,
    data,
    isError,
    error,
  };
};
