import { useMemo, useState, useEffect, useRef, memo, useCallback } from "react";
import { useMap, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { useTheme } from "@mui/styles";
import { OverlappingMarkerSpiderfier } from "../lib/oms";
import { EntityMarker } from "./entityMarker";
import { entityClusterRenderer } from "./entityClusterRenderer";
import { EntityRoute } from "./entityRoute";

const OmsInstance = OverlappingMarkerSpiderfier.call(window);

const average = (array) => array.reduce((a, b) => a + b, 0) / array.length;

export const EntityMap = ({
  setMapCenter,
  mapCenter,
  allCoordinateData,
  coordinateData,
  updateBoundedCoordinates,
  updateIsNavigating,
  onPointClick,
  onSpiderClick,
  selectedObjectiveIndex,
  selectedEntity,
  routeEntities,
  setIsPopoverOpen,
  clearSelectedMaptualListItem,
  setRouteInfo,
  mapIdOverride,
  defaultZoom,
  setMapZoom,
  powerScoreDisplay,
}) => {
  const [defaultMapCenter, setDefaultMapCenter] = useState(null);
  const { themeColors } = useTheme();
  const map = useMap();
  let timer = 0;

  useEffect(() => {
    setMapZoom(map?.getZoom());
  }, [map?.getZoom()]);

  useEffect(() => {
    if (
      mapCenter &&
      ((map && !map.getBounds()?.contains(mapCenter)) || map?.getZoom() < 15)
    ) {
      map.panTo(mapCenter);
      if (selectedEntity) {
        smoothZoom(map, 16, map.getZoom());
      } else {
        map.setZoom(defaultZoom ?? 9);
      }
    }
  }, [mapCenter]);

  const smoothZoom = (map, max, cnt) => {
    if (cnt >= max) {
      return;
    } else {
      const z = google.maps.event.addListener(
        map,
        "zoom_changed",
        function (event) {
          google.maps.event.removeListener(z);
          smoothZoom(map, max, cnt + 1);
        }
      );
      setTimeout(function () {
        map.setZoom(cnt);
      }, 500);
    }
  };

  useEffect(() => {
    if (!map) return;
    const bounds = map.getBounds();
    const inBoundsCoordinates = coordinateData.filter((coord) =>
      bounds?.contains({
        lat: coord.coordinates.latitude,
        lng: coord.coordinates.longitude,
      })
    );
    updateBoundedCoordinates(inBoundsCoordinates);
  }, [coordinateData]);

  const handleIdle = (e) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      updateIsNavigating(false);
      const bounds = e.map.getBounds();
      const inBoundsCoordinates = coordinateData.filter((coord) =>
        bounds?.contains({
          lat: coord.coordinates.latitude,
          lng: coord.coordinates.longitude,
        })
      );
      updateBoundedCoordinates(inBoundsCoordinates);
    }, 1500);
  };

  const handleClick = (e) => {
    const bounds = e.map.getBounds();
    const inBoundsCoordinates = coordinateData.filter((coord) =>
      bounds.contains({
        lat: coord.coordinates.latitude,
        lng: coord.coordinates.longitude,
      })
    );
    onSpiderClick([]);
    updateBoundedCoordinates(inBoundsCoordinates);
  };

  useEffect(() => {
    if (allCoordinateData?.length > 0) {
      const entityCoordinatesCenter = {
        lat: average(allCoordinateData.map((i) => i.coordinates.latitude)),
        lng: average(allCoordinateData.map((i) => i.coordinates.longitude)),
      };
      if (
        !defaultMapCenter ||
        (defaultMapCenter.lat !== entityCoordinatesCenter.lat &&
          defaultMapCenter.lng !== entityCoordinatesCenter.lng)
      ) {
        setDefaultMapCenter(entityCoordinatesCenter);
        setMapCenter(entityCoordinatesCenter);
      }
    }
  }, [allCoordinateData]);

  const handleMapInteract = () => {
    setMapCenter(null);
    updateIsNavigating(true);
  };

  const handleMapDrag = () => {
    setIsPopoverOpen(false);
    clearSelectedMaptualListItem();
  };

  const MapMemo = useMemo(
    () =>
      coordinateData &&
      defaultMapCenter && (
        <Map
          streetViewControl={false}
          fullscreenControl={false}
          mapTypeControl={false}
          clickableIcons={false}
          mapId={
            mapIdOverride ??
            (themeColors.colorScheme === "dark"
              ? "8474605dd9aee9df"
              : "3b857a5969573a1b")
          }
          defaultZoom={defaultZoom ?? 9}
          minZoom={3}
          defaultCenter={defaultMapCenter}
          onIdle={(e) => handleIdle(e)}
          onClick={(e) => handleClick(e)}
          onDrag={() => {
            handleMapInteract();
            handleMapDrag();
          }}
          onZoomChanged={() => handleMapInteract()}
          onBoundsChanged={() => handleMapInteract()}
          isFractionalZoomEnabled={false}
        >
          <EntityRoute
            routeEntities={routeEntities}
            setRouteInfo={setRouteInfo}
          />
          <ClusteredMarkers
            points={coordinateData}
            onPointClick={onPointClick}
            onSpiderClick={onSpiderClick}
            selectedObjectiveIndex={selectedObjectiveIndex}
            selectedEntity={selectedEntity}
            routeEntities={routeEntities}
            powerScoreDisplay={powerScoreDisplay}
          />
        </Map>
      ),
    [
      coordinateData,
      defaultMapCenter,
      selectedObjectiveIndex,
      selectedEntity,
      routeEntities,
      powerScoreDisplay,
    ]
  );

  return MapMemo;
};

const ClusteredMarkers = ({
  points,
  onPointClick,
  onSpiderClick,
  selectedObjectiveIndex,
  selectedEntity,
  routeEntities,
  powerScoreDisplay,
}) => {
  const map = useMap();
  const [markers, setMarkers] = useState({});
  const clusterer = useRef(null);
  const [oms, setOms] = useState(null);
  const [idled, setIdled] = useState(false);
  const [spiderfiedEntityId, setSpiderfiedEntityId] = useState("");

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      const algorithm = new SuperClusterAlgorithm({ maxZoom: 14, radius: 180 });
      clusterer.current = new MarkerClusterer({
        map,
        algorithm,
        renderer: entityClusterRenderer,
      });
    }
    if (!oms) {
      const omsStartInstance = new OmsInstance(map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: false,
        circleFootSeparation: 40,
        spiralFootSeparation: 60,
        spiralLengthStart: 40,
        spiralLengthFactor: 5,
        keepSpiderfied: true,
        nearbyDistance: 40,
      });
      setOms(omsStartInstance);
      window.google.maps.event.addListener(map, "idle", () => {
        setIdled(true);
        omsStartInstance.unspiderfy();
        onSpiderClick([]);
      });
    }
  }, [map]);

  useEffect(() => {
    if (oms && map.getProjection()) {
      Object.getPrototypeOf(oms).formatMarkers.call(oms);
    }
    setIdled(false);
  }, [idled]);

  useEffect(() => {
    setMarkers([]);
  }, [points]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = useCallback(
    (marker, key) => {
      if (!marker && points.find((p) => p.entityId === key)) return;
      if (marker && markers[key]) return;
      if (!marker && !markers[key]) return;

      setMarkers((prev) => {
        if (marker) {
          return { ...prev, [key]: marker };
        }
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      });
    },
    [markers, setMarkers, points]
  );

  const getMarkerRef = useCallback((key) => markers[key], [markers]);

  return (
    <>
      {points.map((i) => (
        <ClusterAdvancedMarker
          key={i.entityId}
          i={i}
          points={points}
          markers={markers}
          oms={oms}
          selectedObjectiveIndex={selectedObjectiveIndex}
          selectedEntity={selectedEntity}
          setMarkerRef={setMarkerRef}
          getMarkerRef={getMarkerRef}
          onPointClick={onPointClick}
          onSpiderClick={onSpiderClick}
          spiderfiedEntityId={spiderfiedEntityId}
          setSpiderfiedEntityId={setSpiderfiedEntityId}
          routeEntities={routeEntities}
          powerScoreDisplay={powerScoreDisplay}
        />
      ))}
    </>
  );
};

const ClusterAdvancedMarker = memo(
  ({
    i,
    points,
    markers,
    oms,
    selectedObjectiveIndex,
    selectedEntity,
    setMarkerRef,
    getMarkerRef,
    onPointClick,
    onSpiderClick,
    spiderfiedEntityId,
    setSpiderfiedEntityId,
    routeEntities,
    powerScoreDisplay,
  }) => {
    const [parentSpiderState, setParentSpiderState] =
      useState("UNSPIDERFIABLE");

    const refCreate = (marker) => {
      setMarkerRef(marker, i.entityId);
    };

    const markerOmsData = getMarkerRef(i.entityId)?._omsData;
    return (
      <AdvancedMarker
        key={i.entityId}
        position={
          parentSpiderState === "SPIDERFIED" && markerOmsData
            ? {
                lat: markerOmsData.newPosition.lat(),
                lng: markerOmsData.newPosition.lng(),
              }
            : {
                lat: i.coordinates.latitude,
                lng: i.coordinates.longitude,
              }
        }
        ref={refCreate}
      >
        <EntityMarker
          entity={i}
          marker={getMarkerRef(i.entityId)}
          oms={oms}
          markers={markers}
          points={points}
          onPointClick={onPointClick}
          onSpiderClick={onSpiderClick}
          selectedObjectiveIndex={selectedObjectiveIndex}
          selectedEntity={selectedEntity}
          spiderfiedEntityId={spiderfiedEntityId}
          setSpiderfiedEntityId={setSpiderfiedEntityId}
          setParentSpiderState={setParentSpiderState}
          parentSpiderState={parentSpiderState}
          routeEntities={routeEntities}
          powerScoreDisplay={powerScoreDisplay}
        />
      </AdvancedMarker>
    );
  }
);
ClusterAdvancedMarker.displayName = "ClusterAdvancedMarker";
