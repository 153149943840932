export enum CONTRIBUTION_TYPE {
  CURRENT = "current",
  POTENTIAL = "potential",
}

export const CONTRIBUTION_LABEL =  {
  [CONTRIBUTION_TYPE.POTENTIAL]: "Potential",
  [CONTRIBUTION_TYPE.CURRENT]: "Current Value",
}

export enum METRIC_DATA_FORMAT {
  CATEGORICAL = "categorical",
  CONTINUOUS = "continuous",
}
