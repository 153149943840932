import { NameCell } from '@odaia/shared-components/src/table/cells';
import { addSpacesBetweenSeparators } from '../../../../utils/formatEntityName';

export const EntityTableNameCell = ({
  entityName,
  address,
}: {
  entityName: string;
  address: string;
}) => (
  <NameCell
    name={addSpacesBetweenSeparators(entityName).toUpperCase()}
    secondaryText={address}
  />
);
