import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { FilterSidebar } from '@odaia/shared-components/src/map/filtering/filterSidebar';
import { ControlBar } from '@odaia/shared-components/src/map/FieldMainControls';
import {
  applyListFilters,
  defaultFiltersOff,
  defaultFiltersOn,
  Filters,
  getActiveFilterCount,
  getConfigurableFilters,
  getFilters,
  saveFilters,
  searchItems,
} from '@odaia/shared-components/src/map/filtering/FieldMainFilters';
import { getObjectiveProducts } from '@odaia/shared-components/src/utils';
import { PowerScoreDisplay } from '@odaia/shared-components/src/powerScoreDisplay';
import { ROUTES } from '~/constants';
import { useUserCreatedLists } from '~/containers/fieldview/useUserCreatedLists';
import { useSegmentFilters } from '~/containers/fieldview/useSegmentFilters';
import { useRouteMatch } from 'react-router-dom';
import { useEntityCuration } from '~/request/queries';
import EmptyView from '~/components/emptyview/emptyView';
import { FieldContext } from './fieldContext';
import { ListProvider } from './list/ListProvider';
import { MapProvider } from './map/mapProvider';
import { CallPlanDrawer } from './callPlan/CallPlanDrawer';
import { useEntityCoordinates } from './map/hooks/useEntityCoordinates';
import { useEntityPrecallInsights } from './map/hooks/useEntityPrecallInsights';
import { useEntityRuleInsights } from './map/hooks/useEntityRuleInsights';
import { useProjectTargetProducts } from '../pulse/context/hooks/useProjectTargetProducts';
import { useHcpCrmData } from '../pulse/views/rep/table/row/hooks/useHcpCrmData';
import { AppContext } from '../../appContext';
import { getCustomListMembership } from './FieldMainUtils';
import useFeatureToggles from '../../../../hooks/useFeatureToggles';
import { useModuleConfigurations } from '../../../../hooks/useModuleConfigurations';
import { useCustomerTargetSegments } from './useCustomerTargetSegments';

const PageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
}));

const ViewContainer = styled('div')(() => ({
  padding: 16,
  height: '100%',
}));

const MapViewContainer = styled('div')(({ isVisible }) => ({
  padding: '24px 0 0 0',
  height: 'calc(100vh - 120px)',
  display: isVisible ? 'flex' : 'none',
}));

const VIEW = {
  LIST: 'LIST',
  MAP: 'MAP',
};

const MIN_PRIORITY = 99;

const EmptyViewContainer = styled('div')(() => ({
  height: 400,
}));

export const FieldMain = () => {
  const { profile } = useContext(AppContext);
  const {
    maptualListMetadata,
    maptualList,
    projectMaptualList,
    objective,
    setObjective,
    maptualListObjectives,
    relevantUserSpecialties,
    project,
    isMaptualListLoading,
    projectMaptualListLoading,
  } = useContext(FieldContext);

  const [mergedMaptualList, setMergedMaptualList] = useState(null);
  const [filteredMaptualList, setFilteredMaptualList] = useState(null);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Filters | null>(defaultFiltersOn);
  const [defaultFilters, setDefaultFilters] = useState<Filters | null>(
    defaultFiltersOff
  );
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const [entityDrawerOpen, setEntityDrawerOpen] = useState(false);
  const [entityDrawerId, setEntityDrawerId] = useState<string | null>(null);

  const [isSuggestedEntitiesFilterActive, setIsSuggestedEntitiesFilterActive] =
    useState(false);
  const [isListUpdating, setIsListUpdating] = useState(false);

  const featureToggles = useFeatureToggles();

  const showCurationData = featureToggles(
    profile.userGroup,
    'showFieldCoreCurationData'
  );

  const hideMap =
    !maptualListMetadata?.listName ||
    maptualListMetadata?.listName?.toLowerCase().includes('national');

  const openEntityDrawer = (entityId: string) => {
    setEntityDrawerOpen(true);
    setEntityDrawerId(entityId);
  };

  const { data: moduleConfig, isLoading: isModuleConfigLoading } =
    useModuleConfigurations({
      schemaName: 'customerTargetConfig',
      marketId: project.metadata?.marketId,
      userGroup: profile.userGroup,
      useDefaults: true,
    });

  const { data: veevaAppConfig, isLoading: isVeevaConfigLoading } =
    useModuleConfigurations({
      schemaName: 'veevaApplicationConfig',
      userGroup: profile.userGroup,
      useDefaults: true,
    });

  useEffect(() => {
    if (!isVeevaConfigLoading && !isModuleConfigLoading) {
      const [configuredFilters, configuredDefaultFilters] =
        getConfigurableFilters(
          veevaAppConfig,
          moduleConfig,
          filters,
          defaultFilters
        );
      setFilters(configuredFilters);
      setDefaultFilters(configuredDefaultFilters);
    }
  }, [moduleConfig, veevaAppConfig]);

  const {
    data: customerTargetSegmentsData,
    isLoading: isCustomerTargetSegmentsLoading,
  } = useCustomerTargetSegments({
    productLineId: project?.metadata?.productLineId,
    regionId: maptualListMetadata.sfMaptualListId,
  });

  const {
    data: entityCoordinatesData,
    isLoading: isLoadingEntityCoords,
    isError: isErrorEntityCoords,
  } = useEntityCoordinates({
    maptualListMetadata,
    project,
  });

  const {
    data: projectTargetProducts,
    isLoading: isProductsLoading,
    isError: isProductsError,
  } = useProjectTargetProducts(
    project?.metadata?.productLineId,
    project?.metadata?.projectId
  );

  const {
    data: entityPrecallInsights,
    isLoading: isEntityPrecallInsightsLoading,
  } = useEntityPrecallInsights({
    maptualListMetadata,
    projectId: project?.metadata?.projectId,
    objectiveId: objective?.id,
  });

  const {
    data: entityRuleInsights,
    isLoading: isEntityRuleInsightsLoading,
    isError: isEntityRuleInsightsError,
  } = useEntityRuleInsights({
    regionId: maptualListMetadata.sfMaptualListId,
    productLineId: project?.metadata?.productLineId,
    objectiveId: objective?.id,
    projectId: project?.metadata?.projectId,
  });

  const {
    data: entityCuration,
    isLoading: isEntityCurationLoading,
    isError: isEntityCurationError,
  } = useEntityCuration({
    regionId: maptualListMetadata.sfMaptualListId,
    objectiveId: objective?.id,
  });

  const {
    data: entityCrmData,
    isLoading: isEntityCrmDataLoading,
    isError: isEntityCrmDataError,
  } = useHcpCrmData({
    productLineId: project?.metadata?.productLineId,
    projectId: project?.metadata?.projectId,
    regionId: maptualListMetadata.sfMaptualListId,
    entityId: null,
    products: getObjectiveProducts(projectTargetProducts, objective?.id),
    latestCurationTimestamp: null,
  });

  const { userCreatedLists } = useUserCreatedLists({
    userId: profile?.userId,
  });

  const productLineUserCreatedLists =
    userCreatedLists?.[project?.metadata?.productLineId];

  // setting up objective level list
  useEffect(() => {
    if (maptualListObjectives?.length > 0 && (!objective || !objective.id)) {
      setObjective(maptualListObjectives[0]);
    }
  }, [maptualListObjectives]);

  // setting up filters
  useEffect(() => {
    if (project?.metadata?.projectId) {
      const sessionFilters = getFilters(project?.metadata?.projectId);
      if (sessionFilters) setFilters(sessionFilters);
    }
  }, [project?.metadata?.projectId]);

  // setting up maptual list with objective scores and coordinate data
  useEffect(() => {
    if (
      projectMaptualList?.list?.items &&
      maptualList?.list?.items &&
      projectMaptualList?.metadata?.sfMaptualListId ===
        maptualList?.metadata?.sfMaptualListId &&
      (hideMap ||
        (entityCoordinatesData && entityCoordinatesData?.length !== 0)) &&
      entityPrecallInsights &&
      entityRuleInsights &&
      entityCuration &&
      entityCrmData &&
      customerTargetSegmentsData
    ) {
      const listMap = new Map(
        maptualList.list.items.map((i) => [
          parseInt(i.entityId, 10),
          {
            ...i,
            entityId: parseInt(i.entityId, 10),
          },
        ])
      );

      projectMaptualList.list.items.forEach((entity) => {
        const entityId = parseInt(entity.entityId, 10);
        if (listMap.has(entityId)) {
          const item = listMap.get(entityId);
          listMap.set(entityId, {
            ...item,
            objectiveScores: entity.objectiveScores || [
              entity.maptualDisplayScore,
            ],
            insight: entityRuleInsights?.[entityId]
              ? {
                  title: entityRuleInsights[entityId].title,
                  description: entityRuleInsights[entityId].message,
                }
              : null,
          });
        }
      });

      if (!hideMap) {
        entityCoordinatesData.forEach((entity) => {
          const { entityId } = entity;
          if (listMap.has(entityId)) {
            const item = listMap.get(entityId);
            listMap.set(entityId, {
              ...item,
              coordinates: {
                latitude: entity.latitude,
                longitude: entity.longitude,
              },
              address: entity.address,
            });
          }
        });
      }

      entityPrecallInsights.forEach((entityPrecallInsight) => {
        const { entityId } = entityPrecallInsight;
        const precallInsightToDisplay = entityPrecallInsight?.insights?.reduce(
          (prev, current) =>
            prev && prev.priority < current.priority ? prev : current
        );
        if (listMap.has(entityId)) {
          const item = listMap.get(entityId);
          listMap.set(entityId, {
            ...item,
            insight: {
              title: precallInsightToDisplay?.title,
              description: precallInsightToDisplay?.description,
            },
          });
        }
      });

      const newList = [...listMap.values()];

      const updatedList = {
        ...maptualList,
        list: {
          ...maptualList.list,
          items: newList.map((item) => ({
            ...item,
            customListMembership: getCustomListMembership(
              item.entityId,
              productLineUserCreatedLists
            ),
            curationRank: entityCuration?.[item.entityId],
            crmData: {
              qtdCallCount: entityCrmData?.[Number(item.entityId)],
            },
            customerTarget: moduleConfig?.customerTargets.find(
              (i) =>
                i.value ===
                customerTargetSegmentsData?.customerTargetSegments?.[
                  item.entityId
                ]
            ),
          })),
        },
      };
      if (
        veevaAppConfig?.powerScoreDisplay === PowerScoreDisplay.CUSTOMER_TARGET
      ) {
        updatedList.list.items.sort((a, b) => {
          const aR = a.customerTarget?.priority || MIN_PRIORITY;
          const bR = b.customerTarget?.priority || MIN_PRIORITY;
          return aR - bR;
        });
      }
      setMergedMaptualList(updatedList);
    }
  }, [
    projectMaptualList,
    maptualList,
    entityCoordinatesData,
    entityPrecallInsights,
    entityRuleInsights,
    entityCuration,
    customerTargetSegmentsData,
    entityCrmData,
  ]);

  useEffect(() => {
    if (mergedMaptualList?.list?.items?.length > 0 && objective?.id) {
      let newList = applyListFilters(
        mergedMaptualList?.list?.items,
        maptualListObjectives.findIndex((o) => o.id === objective.id),
        filters,
        isSuggestedEntitiesFilterActive
      );

      newList = searchItems(newList, search);

      const newFilteredMaptualList = {
        ...mergedMaptualList,
        list: {
          items: newList,
        },
      };

      const numActiveFilters = getActiveFilterCount(filters, defaultFilters);
      setFilteredMaptualList(newFilteredMaptualList);
      setActiveFilterCount(numActiveFilters);

      saveFilters(filters, project?.metadata?.projectId);
      setIsListUpdating(false);
    }
  }, [filters, mergedMaptualList, search, isSuggestedEntitiesFilterActive]);

  const { segmentFilters, isSegmentFiltersBusy } = useSegmentFilters({
    objectiveId: objective?.id,
  });

  const match = useRouteMatch();

  const view = match.url.includes(`${ROUTES.HCPS}${ROUTES.MAP}`)
    ? VIEW.MAP
    : VIEW.LIST;

  const powerScoreDisplay = veevaAppConfig?.powerScoreDisplay;

  const isLoading =
    isLoadingEntityCoords ||
    isEntityPrecallInsightsLoading ||
    isEntityRuleInsightsLoading ||
    isEntityCurationLoading ||
    isEntityCrmDataLoading ||
    isProductsLoading ||
    isMaptualListLoading ||
    projectMaptualListLoading ||
    isListUpdating ||
    isCustomerTargetSegmentsLoading;

  const isError =
    isErrorEntityCoords ||
    isProductsError ||
    isEntityRuleInsightsError ||
    isEntityCurationError ||
    isEntityCrmDataError;

  return (
    <>
      <PageContainer>
        <ControlBar
          maptualListMetadata={maptualListMetadata}
          objective={objective}
          setObjective={setObjective}
          maptualListObjectives={maptualListObjectives}
          mergedMaptualList={mergedMaptualList}
          showCurationData={showCurationData}
          setSearch={setSearch}
          activeFilterCount={activeFilterCount}
          setIsFilterDrawerOpen={() => {
            setIsFilterDrawerOpen(!isFilterDrawerOpen);
          }}
          isSuggestedEntities={isSuggestedEntitiesFilterActive}
          setIsSuggestedEntities={() => {
            setIsSuggestedEntitiesFilterActive(
              !isSuggestedEntitiesFilterActive
            );
            setIsListUpdating(true);
          }}
        />
        {isLoading && (
          <EmptyViewContainer>
            <EmptyView isLoading />
          </EmptyViewContainer>
        )}

        {!isLoading && isError && (
          <EmptyViewContainer>
            <EmptyView />
          </EmptyViewContainer>
        )}
        {!isLoading && !isError && (
          <>
            {view === VIEW.LIST && (
              <ViewContainer>
                <ListProvider
                  maptualList={filteredMaptualList}
                  onRowClick={(entityId) => openEntityDrawer(entityId)}
                  isProductsLoading={isProductsLoading}
                  projectTargetProducts={projectTargetProducts}
                  showCurationData={showCurationData}
                  isSuggestedEntitiesFilterActive={
                    isSuggestedEntitiesFilterActive
                  }
                  powerScoreDisplay={powerScoreDisplay}
                />
              </ViewContainer>
            )}
            {maptualListMetadata?.locality === 'TERRITORY' && (
              <MapViewContainer isVisible={view === VIEW.MAP}>
                <MapProvider
                  mergedMaptuaList={mergedMaptualList}
                  filteredList={filteredMaptualList}
                  objective={objective}
                  isLoading={isLoading}
                  onEntityDetailsClick={(entityId) =>
                    openEntityDrawer(entityId)
                  }
                  isSuggestedEntitiesFilterActive={
                    isSuggestedEntitiesFilterActive
                  }
                  powerScoreDisplay={powerScoreDisplay}
                />
              </MapViewContainer>
            )}
            {maptualListMetadata?.locality !== 'TERRITORY' &&
              view === VIEW.MAP && (
                <MapViewContainer isVisible>
                  <MapProvider
                    mergedMaptuaList={mergedMaptualList}
                    filteredList={filteredMaptualList}
                    objective={objective}
                    isLoading={isLoading}
                    onEntityDetailsClick={(entityId) =>
                      openEntityDrawer(entityId)
                    }
                    isSuggestedEntitiesFilterActive={
                      isSuggestedEntitiesFilterActive
                    }
                    powerScoreDisplay={powerScoreDisplay}
                    hideMap={hideMap}
                  />
                </MapViewContainer>
              )}
            <CallPlanDrawer
              open={entityDrawerOpen}
              entityId={entityDrawerId}
              snowflakeMaptualListId={maptualListMetadata.sfMaptualListId}
              setOpen={setEntityDrawerOpen}
            />
          </>
        )}
      </PageContainer>
      {mergedMaptualList?.list?.items.length > 0 && (
        <FilterSidebar
          isFilterDrawerOpen={isFilterDrawerOpen}
          setIsFilterDrawerOpen={setIsFilterDrawerOpen}
          filters={filters}
          setFilters={setFilters}
          relevantUserSpecialties={relevantUserSpecialties}
          filterMetadata={segmentFilters}
          filterMetadataLoading={isSegmentFiltersBusy}
          objective={objective}
          powerScoreDisplay={powerScoreDisplay}
          customerTargetsConfig={moduleConfig?.customerTargets || []}
          showUserCreatedLists
        />
      )}
    </>
  );
};
