import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import { isEqual } from "lodash";
import { getTrendValue } from "../trend/utils/helpers";
import { formatGraphValue } from "./utils/formatters";
import { TrendValue } from "../trend/TrendValue";
import { GRAPH_VALUE_TYPE } from "./utils/constants";
import { ScriptableTooltipContext } from "chart.js";
import { TrendProps } from "../trend";

const Wrapper = styled(Box)(
  ({
    tooltip,
    theme: { themeColors },
  }: { tooltip: TooltipData } & BoxProps) => ({
    display: tooltip.display,
    flexDirection: "column",
    maxWidth: "220px",
    top: tooltip.top,
    left: tooltip.left,
    opacity: tooltip.opacity,
    position: "absolute",
    padding: 12,
    zIndex: 1,
    backgroundColor: themeColors.tooltipBackgroundColor,
    borderRadius: "4px",
    border: `1px solid ${themeColors.contentCardBorderColor}`,
    pointerEvents: "none",
  })
);

const TooltipText = styled(Typography, {
  shouldForwardProp: (prop) => !["marginBottom", "marginLeft"].includes(prop),
})(
  ({
    marginBottom = 0,
    theme: { themeColors },
  }: { marginBottom?: number } & TypographyProps) => ({
    color: themeColors.secondaryTextColor,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    marginBottom,
    whiteSpace: "nowrap",
  })
);

const TooltipBody = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "4px",
}));

const DataContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  height: "fit-content",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "4px",
}));

const DataPoint = styled(Box)(({ theme: { themeColors } }) => ({
  marginTop: "6px",
  width: "10px",
  height: "10px",
  backgroundColor: themeColors.dataGeneralColor,
  borderRadius: "50%",
}));

export const createTooltip = ({
  context,
  tooltip,
  updateTooltip,
}: {
  context: ScriptableTooltipContext;
  tooltip: TooltipData;
  updateTooltip: (_: TooltipData) => void;
}) => {
  const { chart, tooltip: tooltipModel } = context;
  if (!chart) return;
  const position = context.chart.canvas.getBoundingClientRect();
  if (tooltipModel.opacity === 0) {
    if (tooltip.opacity !== 0) {
      updateTooltip({ ...tooltip, opacity: 0, display: "none" });
    }
    return;
  }
  const trend =
    tooltipModel.dataPoints[0].dataIndex === 0
      ? { status: 1, type: "TREND_CHANGE", value: 0 }
      : getTrendValue(
          tooltipModel.dataPoints[0].dataset.data[
            tooltipModel.dataPoints[0].dataIndex - 1
          ],
          tooltipModel.dataPoints[0].dataset.data[
            tooltipModel.dataPoints[0].dataIndex
          ]
        );
  const barWidth = chart.getDatasetMeta(0).data[0].width;
  const xOffset = (barWidth ? barWidth / 2 : 0) + 5;
  const newTooltipData = {
    display: "flex",
    opacity: 1,
    left: position.left + tooltipModel.caretX + xOffset,
    top: position.top + tooltipModel.caretY,
    label: tooltipModel.dataPoints[0].label,
    value: tooltipModel.dataPoints[0].raw,
    trend,
  } as TooltipData;
  if (!isEqual(tooltip, newTooltipData)) updateTooltip(newTooltipData);
};

export interface TooltipData {
  display: string;
  opacity: number;
  top: number;
  left: number;
  label: string;
  value: number;
  trend: TrendProps | undefined;
}

export interface TooltipProps {
  tooltip: TooltipData;
  tooltipDataPointTitle: string;
  valueType?: string;
}

export const Tooltip = ({
  tooltip,
  tooltipDataPointTitle,
  valueType = "",
}: TooltipProps) => {
  if (!tooltip.trend) return;

  return (
    <Wrapper tooltip={tooltip}>
      <TooltipText marginBottom={12}>{tooltip.label}</TooltipText>
      <TooltipBody>
        <DataPoint />
        <DataContainer>
          <TrendValue
            type={tooltip.trend?.type}
            status={tooltip.trend?.status}
            value={tooltip.trend?.value}
            noMargin
            filled
          />
          <TooltipText>
            {formatGraphValue(
              tooltip.value,
              (valueType as GRAPH_VALUE_TYPE) || valueType,
              1
            )}{" "}
            {tooltipDataPointTitle}
          </TooltipText>
        </DataContainer>
      </TooltipBody>
    </Wrapper>
  );
};
