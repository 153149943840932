import { useContext } from 'react';
import { styled, Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { EntityOverviewContext } from '../../data/entityOverviewContext';
import { trackEntityOverviewObjectiveDropdown } from '../../../../trackers/mixpanel';

const ObjectiveSelect = styled(Select)(() => ({
  width: 248,
}));

export const ObjectiveSelector = () => {
  const { entityType, metadata, objectiveId, setObjectiveId } = useContext(
    EntityOverviewContext
  );

  const handleObjectiveChange = (objective) => {
    trackEntityOverviewObjectiveDropdown(objective.label, entityType);
    setObjectiveId(objective.id);
  };

  return (
    <ObjectiveSelect
      data-testid="objective-selector"
      key={objectiveId}
      value={objectiveId}
      IconComponent={KeyboardArrowDownIcon}
      variant="outlined"
    >
      {Array.isArray(metadata?.objectives) &&
        metadata?.objectives.map((objective) => (
          <MenuItem
            key={objective.id}
            value={objective.id}
            onClick={() => handleObjectiveChange(objective)}
            variant="outlined"
          >
            {objective.label}
          </MenuItem>
        ))}
    </ObjectiveSelect>
  );
};
