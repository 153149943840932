import { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material';
import { PreCallInsight } from './preCallInsight';
import { LlmInsights } from '@odaia/clients/maptualAPI/api';


const StyledAccordion = styled(Accordion)(() => ({
  padding: 0,
  background: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));



const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme: { themeColors, spacing } }) => ({
    display: 'flex',
    backgroundColor: themeColors.secondaryColor,
    border: '1px solid',
    borderColor: themeColors.borderPrimaryColor,
    borderRadius: 7,
    width: '100%',
    gap: spacing(1.5),

    '&[aria-expanded="true"]': {
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },

    '&:hover .accordion-title .MuiTypography-title3': {
      color: themeColors.buttonHoverContentColor,
    },
  })
);

const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme: { themeColors } }) => ({
    display: 'flex',
    border: `1px solid ${themeColors.borderPrimaryColor}`,
    borderTop: 'none',
    borderRadius: '0 0 7px 7px',
    padding: 0,
    gap: 11.5,
    width: '100%',
  })
);

const ExpandIcon = styled(ExpandMoreIcon)(() => ({
  height: 16,
  width: 16,
}));

type Props = {
    insight: LlmInsights;
    children: ReactNode;
    onAccordionChange?: ((event: React.SyntheticEvent, expanded: boolean) => void) | undefined
}

export const PrecallInsightAccordion = ({
  insight,
  children,
  onAccordionChange
}: Props) => {

  return (
    <StyledAccordion disableGutters elevation={0} onChange={onAccordionChange}>
      <StyledAccordionSummary expandIcon={<ExpandIcon />}>
        <PreCallInsight
          insightType={insight.type}
          title={insight.title}
          strong
        />
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
