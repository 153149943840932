import { styled } from "@mui/material";
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridPaginationModel,
  GridRowParams,
  GridSortItem,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "b40a4560701fd04897a3f0943bada3ebTz05NDk2NSxFPTE3NTM1NTUyNjgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

type SortDirection = "asc" | "desc";

interface SortModel {
  column: string;
  order: SortDirection;
}

interface StickyColumns {
  left: string[];
  right: string[];
}

export interface AdditionalProps {
  initialState?: object;
  rowHeight?: number;
  rowBuffer?: number;
  columnHeaderHeight?: number;
  apiRef?: any;
  paginationModel?: GridPaginationModel;
  sortModel?: SortModel[];
  slots?: object;
  disableVirtualization: boolean;
  disableColumnReorder?: boolean;
  disableColumnResize?: boolean;
  disableMultipleSelection?: boolean;
  disableSelectionOnClick?: boolean;
  disableRowSelectionOnClick?: boolean;
  disableColumnMenu?: boolean;
  autosizeOnMount?: boolean;
}

export interface TableProps {
  className?: string;
  columns: GridColDef<object>[];
  rows: object[];
  onRowClick?: (params: GridRowParams<GridValidRowModel>) => void;
  onCellClick?: (params: GridCellParams) => void;
  onColumnHeaderClick?: (params: GridColumnHeaderParams) => void;
  paginationEnabled?: boolean;
  rowsPerPage?: number;
  stickyColumns?: StickyColumns;
  onSort?: (sort: SortModel[]) => void;
  onFetchNext?: (page: number) => void;
  isLoading?: boolean;
  sx?: object;
  additionalProps?: AdditionalProps;
}

const StyledDataGridPremium = styled(DataGridPremium)(
  ({ theme: { themeColors } }) => ({
    "& .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
      padding: "8px 10px",
    },
    ".MuiTablePagination-spacer": {
      display: "none",
    },
    ".MuiDataGrid-columnHeader": {
      color: themeColors.tableCellHeaderDefaultContent,
      fontSize: 12,

      ".MuiDataGrid-columnHeaderTitle": {
        fontWeight: 400,
      },
    },
  })
);

export const Table = ({
  className,
  columns,
  rows,
  stickyColumns,
  onRowClick,
  onCellClick,
  onColumnHeaderClick,
  paginationEnabled,
  rowsPerPage,
  onSort,
  onFetchNext,
  isLoading = false,
  sx = {},
  additionalProps = {},
}: TableProps) => {
  const {initialState, ...additionalProperties} = additionalProps;

  const initialTableState = {
    pagination: { paginationModel: { pageSize: rowsPerPage, page: 0 } },
    pinnedColumns: stickyColumns,
    ...initialState,
  };


  const handleSortModelChange = (sortModel: GridSortModel): void => {
    if (!onSort) {
      return;
    }

    const sortModels = sortModel.map((model: GridSortItem) => ({
      column: model.field,
      order: model.sort as SortDirection,
    }));
    onSort(sortModels);
  };

  const handlePaginationChange = (
    paginationModel: GridPaginationModel
  ): void => {
    if (onFetchNext) {
      onFetchNext(paginationModel.page);
    }
  };

  return (
    <StyledDataGridPremium
      className={className}
      columns={columns}
      rows={rows}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
      onColumnHeaderClick={onColumnHeaderClick}
      pagination={paginationEnabled}
      initialState={initialTableState}
      onSortModelChange={handleSortModelChange}
      onPaginationModelChange={handlePaginationChange}
      getRowHeight={() => "auto"}
      loading={isLoading}
      pinnedColumns={stickyColumns}
      sx={sx}
      {...additionalProperties}
    />
  );
};
