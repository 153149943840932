import { styled } from "@mui/system";
import { Box, BoxProps, Typography } from "@mui/material";
import { PREDICTION_PRECISION, TREND_TYPES } from "./utils/constants";
import { TypographyDefinitions } from "@odaia/ui/src/typography";
import { getColors, getIcon, transformValue } from "./utils/helpers";

const Root = styled(Box)(
  ({
    theme: { themeColors },
    noMargin,
    filled,
    fillColor,
  }: { noMargin: boolean; filled: boolean; fillColor: string } & BoxProps) => ({
    display: "flex",
    justifyContent: "flex-end",
    margin: noMargin ? 0 : "3px 0px 0px 3px",
    color: themeColors.primaryTextColor,
    background: filled ? fillColor : "inherit",
    padding: filled ? "4px" : 0,
    borderRadius: filled ? "4px" : 0,
  })
);

const TrendValueStyle = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: TypographyDefinitions.fontWeight.medium,
}));

export const TrendValue = ({
  type,
  status,
  value,
  useZero = false,
  collapsed = false,
  noMargin = false,
  filled = false,
}: {
  type: TREND_TYPES;
  status: PREDICTION_PRECISION;
  value: number;
  useZero?: boolean;
  collapsed?: boolean;
  noMargin?: boolean;
  filled?: boolean;
}) => {
  return (value !== undefined || status) && type ? (
    <Root
      data-testid="trend-value-container"
      noMargin={noMargin}
      filled={filled}
      fillColor={getColors({ type, status, value }).secondary as string}
    >
      {getIcon({ type, status, value, useZero, collapsed })}
      {collapsed ? null : (
        <TrendValueStyle
          noWrap
          align="left"
          style={{ color: getColors({ type, status, value }).primary }}
        >
          {transformValue({ type, status, useZero, value })}
        </TrendValueStyle>
      )}
    </Root>
  ) : null;
};
