import { styled, Box, Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { PowerScoreExplainability } from '../../components/fieldView/PowerScoreExplainability';
import { FieldContext } from '../application/appViews/field/fieldContext';

const StyledTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme: { themeColors }, isSelected }) => ({
  color: isSelected
    ? themeColors.primaryTextColor
    : themeColors.buttonContentColor,
}));

export const StyledMultiObjectivesRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'objectiveCount',
})(({ theme, objectiveCount }) => ({
  width: '100%',
  gridGap: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns:
    objectiveCount >= 4 ? '1fr 1fr' : `repeat(${objectiveCount}, 1fr)`,
}));

const ObjectiveCard = styled(Button)(({ theme: { themeColors, spacing } }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  justifyContent: 'start',
  gap: spacing(1),
  alignItems: 'center',
  color: themeColors.buttonContentColor,
  backgroundColor: themeColors.buttonBackgroundColor,
  border: `1px solid ${themeColors.contentCardBorderColor}`,
  borderRadius: 5,
  padding: 0,
  ':hover, &.Mui-focusVisible': {
    background: themeColors.buttonBackgroundColor,
  },
  '&.selectedObjective': {
    backgroundColor: themeColors.buttonActiveBackgroundColor,
    color: themeColors.buttonActiveColor,
    border: `1px solid ${themeColors.buttonActiveBorderColor}`,
  },
  '&.Mui-focusVisible': {
    borderColor: 'white',
  },
}));

const ObjectiveInfo = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  textAlign: 'left',
  flexGrow: 1,
  padding: 8,
}));

const ObjectiveWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'applyMargins',
})(({ applyMargins }) => ({
  textAlign: 'left',
  marginTop: applyMargins.top ? 16 : 0,
  marginBottom: applyMargins.bottom ? 16 : 0,
  width: '100%',
}));

export const MultiObjectivesRow = ({
  objectives,
  handleClick,
  selectedObjective,
  locality = 'N/A',
  applyMargins = { top: true, bottom: true },
  entityType,
  entityId,
  entityName,
  isNonDld = false,
  isAmaOptOut = false,
}) => {
  const { maptualListMetadata } = useContext(FieldContext);

  const sfMaptualListId = maptualListMetadata?.sfMaptualListId;

  return (
    <ObjectiveWrapper
      className="intercom-field-objective-scores"
      data-testid="insightsObjectiveScores"
      applyMargins={applyMargins}
    >
      <StyledMultiObjectivesRow objectiveCount={objectives.length}>
        {objectives.map((obj) => (
          <ObjectiveCard
            color="secondary"
            focusRipple={false}
            key={`objective_card_${obj.id}`}
            className={
              selectedObjective.id === obj.id ? 'selectedObjective' : ''
            }
            objectiveCount={objectives.length}
          >
            {obj.maptualDisplayScore !== -1 && (
              <PowerScoreExplainability
                powerscore={Math.round(obj.maptualDisplayScore)}
                objectiveId={obj.id}
                regionId={sfMaptualListId}
                locality={locality}
                entityType={entityType}
                entityId={entityId}
                entityName={entityName}
                isAmaOptOut={isAmaOptOut}
                isNonDld={isNonDld}
              />
            )}

            <ObjectiveInfo
              onClick={() => handleClick(obj)}
              data-testid={`objective_card_${obj.id}`}
            >
              <StyledTitle
                variant="title2"
                isSelected={selectedObjective.id === obj.id}
              >
                {obj.label}
              </StyledTitle>
            </ObjectiveInfo>
          </ObjectiveCard>
        ))}
      </StyledMultiObjectivesRow>
    </ObjectiveWrapper>
  );
};
