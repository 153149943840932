import React, { useEffect } from "react";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Button,
  IconButton,
  Typography,
  styled,
  Skeleton,
} from "@mui/material";
import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { useMapViewContext } from "../contexts/mapViewContext.ts";

const MapRouteContainer = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  position: "absolute",
  zIndex: 1,
  top: "128px",
  right: "26px",
  backgroundColor: themeColors.mainBackground,
  boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.23)",
  width: 342,
  padding: 12,
  borderRadius: 4,
}));

const MapRouteTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: "left",
  color: themeColors.tertiaryColor,
  fontSize: 14,
  fontWeight: 400,
}));

const MapRouteControls = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  marginLeft: "auto",
  gap: 8,
  marginTop: 12,
  paddingLeft: 0,
}));

const MapRouteEntityList = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const MapRouteEntityItemWrapper = styled(Box)(() => ({
  position: "relative",
}));

const MapRouteEntityItem = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  padding: 6,
  gap: 10,
}));

const MapRouteEntityItemTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    fontWeight: 500,
    color: themeColors.primaryTextColor,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: 220,
  })
);

const MapRouteEntityItemSubTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.tertiaryColor,
  })
);

const StyledBrightness1Icon = styled(Brightness1OutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    width: 12,
    marginTop: "auto",
    marginBottom: "auto",
    margin: 8,
    color: themeColors.tertiaryColor,
  })
);

const StyledTopConnectLine = styled("div")(({ theme: { themeColors } }) => ({
  position: "absolute",
  top: 0,
  left: 19,
  height: 19,
  border: `1px dashed ${themeColors.tertiaryColor}`,
}));

const StyledBottomConnectLine = styled("div")(({ theme: { themeColors } }) => ({
  position: "absolute",
  bottom: 2,
  left: 19,
  height: 17,
  border: `1px dashed ${themeColors.tertiaryColor}`,
}));

const StyledRouteButton = styled(Button)(() => ({
  padding: "8px 12px",
  gap: 4,
}));

const StyledMapRouteEntityText = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  alignSelf: "center",
  cursor: "pointer",
}));

const TitleContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "8px 0px",
  gap: 10,
}));

const TitleContentContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: 8,
}));

const TitleContent = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "40px",
}));

const StyledSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  color: themeColors.buttonContentColor,
  transform: "none",
}));

const StyledIconButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  display: "flex",
  padding: "8px 12px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  border: `1px solid ${themeColors.buttonBorderColor}`,
  backgroundColor: themeColors.buttonBackgroundColor,
  boxShadow: `0px 1px 4px 0px ${themeColors.boxShadowLevelOne}`,

  "&:hover": {
    backgroundColor: themeColors.buttonHoverBackgroundColor,
  },
}));

const StyledLocationSearchingIcon = styled(LocationSearchingIcon)(
  ({ theme: { themeColors } }) => ({
    width: 16,
    height: 16,
    color: themeColors.secondaryDefaultIconPrimaryColor,
  })
);

const formattedTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const remainingDuration = seconds - hours * 3600;
  const minutes = Math.floor(remainingDuration / 60);
  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}m `;
  }
  return formattedTime || "0m";
};

export const MapRoute = ({
  setRouteEntities,
  routeEntities,
  routeInfo,
  setRouteInfo,
  isRouteLoading,
  setIsRouteLoading,
  handleRouteEntityClick,
}) => {
  const { enqueueSnackbar } = useMapViewContext();
  const handleCopyClick = () => {
    navigator.clipboard.writeText(routeInfo.link);
    enqueueSnackbar(`Route link copied to clipboard`, {
      variant: "success",
      autoHideDuration: 10000,
    });
  };

  useEffect(() => setIsRouteLoading(false), [routeInfo]);

  const routeLegsLoaded =
    routeInfo?.legs &&
    ((routeEntities.length === 1 && routeInfo.legs.length === 1) ||
      routeEntities.length - 1 === routeInfo.legs.length);

  return (
    <MapRouteContainer>
      <div>
        <MapRouteTitle>Route</MapRouteTitle>
      </div>
      <MapRouteEntityList>
        {routeEntities.map((entity, index) => (
          <MapRouteEntityItemWrapper key={entity.entityId}>
            {routeEntities.length > 1 && (
              <>
                {index !== 0 && <StyledTopConnectLine />}
                {index !== routeEntities.length - 1 && (
                  <StyledBottomConnectLine />
                )}
              </>
            )}
            <MapRouteEntityItem>
              <StyledBrightness1Icon />
              <StyledMapRouteEntityText
                onClick={() => handleRouteEntityClick(entity)}
              >
                {routeLegsLoaded && (
                  <MapRouteEntityItemTitle>{`${
                    index < routeInfo.legs.length - 1 || index === 0
                      ? routeInfo.legs[index].start_address
                      : routeInfo.legs[index - 1].end_address
                  }`}</MapRouteEntityItemTitle>
                )}
                <MapRouteEntityItemSubTitle>
                  {entity.primaryText}
                </MapRouteEntityItemSubTitle>
              </StyledMapRouteEntityText>
              <IconButton
                sx={{ marginLeft: "auto" }}
                onClick={() =>
                  setRouteEntities(
                    routeEntities.filter(
                      (e) => e.entityId !== entity.entityId
                    )
                  )
                }
              >
                <CancelOutlinedIcon sx={{ width: 18 }} />
              </IconButton>
            </MapRouteEntityItem>
          </MapRouteEntityItemWrapper>
        ))}
      </MapRouteEntityList>
      <div>
        <MapRouteTitle>
          {routeEntities && routeEntities.length < 2 ? (
            `Add another destination to create a route`
          ) : (
            <TitleContainer>
              {isRouteLoading ? (
                <>
                  <StyledSkeleton height={22} width={100} />
                  <StyledSkeleton height={22} width={50} />
                </>
              ) : (
                <>
                  <TitleContentContainer>
                    <StyledIconButton
                      onClick={() => handleRouteEntityClick(routeEntities[0])}
                    >
                      <StyledLocationSearchingIcon />
                    </StyledIconButton>
                    <TitleContent>
                      {routeInfo.title ? `via ${routeInfo.title}` : null}
                    </TitleContent>
                  </TitleContentContainer>
                  <TitleContent>
                    {formattedTime(routeInfo.duration)}
                  </TitleContent>
                </>
              )}
            </TitleContainer>
          )}
        </MapRouteTitle>
      </div>
      <MapRouteControls>
        <StyledRouteButton
          variant="outlined"
          onClick={() => {
            setRouteEntities([]);
            setRouteInfo(null);
          }}
        >
          Clear Route
        </StyledRouteButton>
        <StyledRouteButton
          variant="contained"
          disabled={routeEntities.length < 2 || !routeInfo?.link}
          onClick={() => handleCopyClick()}
        >
          Copy Route Link
          <LinkIcon />
        </StyledRouteButton>
      </MapRouteControls>
    </MapRouteContainer>
  );
};
