import React, {
  Dispatch,
  JSX,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled, Typography, Box } from "@mui/material";
import Grow from "@mui/material/Grow";
import { EntityMap } from "./entityMap";
import { MapList } from "./mapList/mapList.tsx";
import EmptyView from "../emptyView";
import { MapRoute } from "./mapRoute";
import { useMapViewContext } from "../contexts/mapViewContext.ts";
import { Stack } from "@mui/system";
import { MaptualList } from "@odaia/domain/src/types.ts";
import { Assistant } from "../assistant/Assistant.tsx";

const StyledMapContainer = styled("div")(({ moveZoomControlsUp }) => ({
  margin: 0,
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateRows: "min-content minmax(0, 1fr)",
  "> * a": {
    backgroundColor: "unset",
  },
  ".gm-bundled-control-on-bottom": {
    bottom: moveZoomControlsUp ? "155px !important" : "",
    right: moveZoomControlsUp ? "38px !important" : "",
  },
}));

const RegionButtonContainer = styled("div")(() => ({
  height: 34,
  marginTop: 6,
  display: "flex",
  gap: 8,
  alignItems: "center",
}));

const StyledProjectAndRegion = styled("div")(
  ({ theme: { themeColors }, isVeeva }) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    minHeight: isVeeva ? 0 : 0,
    padding: "0px 12px 12px 12px",
    borderBottom: `1px solid ${themeColors.dividerPrimaryColor}`,
  })
);

const StyledNavigationButtonContainer = styled("div")(() => ({
  display: "flex",
  minHeight: "24px",
  alignItems: "center",
  alignSelf: "flex-end",
}));

const HCPTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: "18px",
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const StyledMapContentContainer = styled("div")(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  height: "100%",
}));

const StyledMap = styled("div")(() => ({
  minWidth: 500,
  height: "100%",
  marginRight: "16px",
  flexGrow: 1,
}));

const StyledMapList = styled("div")(({ isFilterOpen }) => ({
  animation: isFilterOpen ? "0.5s slideListOut" : "0.5s slideListIn",
  animationFillMode: "forwards",
  "@keyframes slideListOut": {
    from: { marginRight: 0 },
    to: { marginRight: "-400px" },
  },
  "@keyframes slideListIn": {
    from: { marginRight: "-400px" },
    to: { marginRight: 0 },
  },
}));

const StyledOptionsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: "auto",
  gap: 16,
  alignItems: "flex-end",
}));

const StyledLabel = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 26,
  fontWeight: 600,
  maxWidth: 800,
  color: themeColors.secondaryMaptualListFilteringColor,
  marginTop: 15,
}));

const StyledSecondaryLabel = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: 16,
    fontWeight: 400,
    maxWidth: 800,
    color: themeColors.secondaryMaptualListFilteringColor,
    marginTop: 15,
  })
);

const StyledUnavailableContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: "grow",
  alignItems: "center",
  justifyContent: "center",
  align: "center",
  width: "100%",
  height: "100%",
}));

const filterMaptualList = (
  maptualList,
  coordinates,
  selectedObjectiveIndex
) => {
  const filteredList = [];
  const { items } = maptualList.list;
  coordinates.forEach((coordinate) => {
    const matchingItem = items.find(
      (item) => item.entityId === coordinate.entityId
    );
    if (matchingItem) {
      filteredList.push(matchingItem);
    }
  });
  const sortedList = filteredList.sort((a, b) =>
    sortByPowerScore(a, b, selectedObjectiveIndex)
  );
  return sortedList;
};

const sortByPowerScore = (a, b, selectedObjectiveIndex) => {
  if (a?.customerTarget?.priority && b?.customerTarget?.priority) {
    return a.customerTarget.priority - b.customerTarget.priority;
  } else {
    const aScores = a.objectiveScores || [a.maptualDisplayScore];
    const bScores = b.objectiveScores || [b.maptualDisplayScore];
    if (aScores[selectedObjectiveIndex] >= bScores[selectedObjectiveIndex]) {
      return -1;
    }

    if (aScores[selectedObjectiveIndex] < bScores[selectedObjectiveIndex]) {
      return 1;
    }

    return 0;
  }
};

export type EntityPopoverProps = {
  isOpen: boolean;
  anchorEl: HTMLElement | undefined;
  setIsEntityFullModalOpen: Dispatch<SetStateAction<boolean>>;
  objective: Record<string, unknown> | null;
  entityData: MaptualList["list"]["items"][number];
  topPosition: number;
  handleClose: () => void;
  routeEntities: unknown[];
  setRoute: (entities: unknown) => void;
  setIsRouteLoading: Dispatch<SetStateAction<boolean>>;
  mapContentContainerRef: RefObject<HTMLElement>;
};

export type EntityFullModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  entityId: string;
};

type MapViewProps = {
  listMapToggle?: ReactNode;
  regionSelection?: ReactNode;
  EntityPopover?: (props: EntityPopoverProps) => JSX.Element;
  onEntityDetailsClick: (entityId: string) => void;
  isVeeva?: boolean;
  mapIdOverride?: string;
  defaultZoom?: number;
  moveZoomControlsUp?: boolean;
};

export const MapViewCore = ({
  listMapToggle = null,
  regionSelection = null,
  EntityPopover,
  onEntityDetailsClick,
  isVeeva = false,
  mapIdOverride,
  defaultZoom,
  moveZoomControlsUp = false,
}: MapViewProps) => {
  const {
    maptualList,
    isMaptualListLoading,
    isMaptualListsLoading,
    objective,
    objectiveIndex,
    voiceEnabled,
    filteredMaptualList,
    // maptualListMetadata,
    isSuggestedEntitiesFilterActive,
    powerScoreDisplay,
    hideMap,
  } = useMapViewContext();
  const [visibleMaptualList, setVisibleMaptualList] = useState(
    filteredMaptualList?.list
  );
  const [visibleMaptualListLoading, setVisibleMaptualListLoading] =
    useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [selectedMaptualListItem, setSelectedMaptualListItem] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [selectedGroupCoordinates, setSelectedGroupCoordinates] =
    useState(null);
  const [groupSelected, setGroupSelected] = useState(false);
  const [isEntityPopoverOpen, setIsEntityPopoverOpen] = useState(false);
  // const [isEntityFullModalOpen, setIsEntityFullModalOpen] = useState(false);

  const [routeEntities, setRouteEntities] = useState<Record<string, unknown>[]>(
    []
  );
  const [routeInfo, setRouteInfo] = useState(null);
  const [isRouteLoading, setIsRouteLoading] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const mapContentContainerRef = useRef<HTMLDivElement | null>(null);
  const [biggestCity, setBiggestCity] = useState("");
  const [mapZoom, setMapZoom] = useState(9);

  const setRoute = (entities: Record<string, unknown>[]) => {
    setRouteEntities(
      entities.map((entity) => ({
        ...maptualList.list.items.find(
          (e) => e.entityId === entity.entityId
        ),
        ...entity,
      }))
    );
  };

  const findBiggestCity = (entityList) => {
    const cities = {};
    entityList.forEach((entity) => {
      if (entity.address) {
        const city = entity.address.split(",")[1].trim();
        if (cities[city]) {
          cities[city] += 1;
        } else {
          cities[city] = 1;
        }
      }
    });
    return Object.keys(cities).reduce((a, b) =>
      cities[a] > cities[b] ? a : b
    );
  };

  useEffect(() => {
    if (isMaptualListsLoading) {
      setSelectedCoordinates(null);
      setVisibleMaptualListLoading(true);
      setVisibleMaptualList(null);
    }
  }, [isMaptualListsLoading]);

  const selectMaptualListItem = useCallback(
    (selectedEntity, zoomToEntity = true) => {
      if (zoomToEntity) {
        setMapCenter({
          lat: selectedEntity.coordinates?.latitude,
          lng: selectedEntity.coordinates?.longitude,
        });
      }
      setIsFilterDrawerOpen(false);
      setIsEntityPopoverOpen(true);
      setSelectedMaptualListItem(selectedEntity);
    },
    [
      filteredMaptualList?.list,
      setMapCenter,
      setVisibleMaptualListLoading,
      setIsEntityPopoverOpen,
      setSelectedMaptualListItem,
      setIsFilterDrawerOpen,
    ]
  );

  useEffect(() => {
    if (
      selectedGroupCoordinates &&
      selectedMaptualListItem &&
      !selectedGroupCoordinates.find(
        (i) => i.entityId === selectedMaptualListItem.entityId
      )
    ) {
      selectGroupCoordinates([]);
    }
  }, [selectedMaptualListItem, selectedGroupCoordinates]);

  const selectCoordinates = (coordinates) => {
    setSelectedCoordinates(coordinates);
  };

  const selectGroupCoordinates = useCallback(
    (coordinates) => {
      if (coordinates.length > 0) {
        setSelectedGroupCoordinates(coordinates);
        setGroupSelected(true);
        setSelectedMaptualListItem(null);
        setIsFilterDrawerOpen(false);
      } else {
        setGroupSelected(false);
      }
    },
    [
      setSelectedGroupCoordinates,
      setGroupSelected,
      setSelectedMaptualListItem,
      setIsFilterDrawerOpen,
    ]
  );

  const clearSelectedMaptualListItem = () => {
    setSelectedMaptualListItem(null);
  };

  useEffect(() => {
    if (!selectedCoordinates) {
      if (visibleMaptualList?.items?.length > 0) {
        setVisibleMaptualList({
          items: [],
        });
      }
      return;
    }
    const newList = filterMaptualList(
      filteredMaptualList,
      groupSelected ? selectedGroupCoordinates : selectedCoordinates,
      objectiveIndex
    );
    if (voiceEnabled && selectedCoordinates && selectedCoordinates.length > 0) {
      setBiggestCity(findBiggestCity(selectedCoordinates));
    }
    setVisibleMaptualList({
      items: newList,
    });
    setVisibleMaptualListLoading(false);
  }, [
    selectedCoordinates,
    selectedGroupCoordinates,
    filteredMaptualList,
    groupSelected,
    objectiveIndex,
  ]);

  useEffect(() => {
    if (maptualList?.list?.items?.length > 0) {
      clearSelectedMaptualListItem();
    }
  }, [maptualList]);

  const isLoadingData = () =>
    isMaptualListLoading || isMaptualListsLoading || !objective?.id;

  const centerMapOnEntity = (entity) => {
    setMapCenter({
      lat: entity.coordinates?.latitude,
      lng: entity.coordinates?.longitude,
    });
  };

  return (
    <>
      <StyledMapContainer moveZoomControlsUp={moveZoomControlsUp}>
        <StyledProjectAndRegion isVeeva={isVeeva}>
          <StyledNavigationButtonContainer>
            <HCPTitle variant="h3">HCPs</HCPTitle>
          </StyledNavigationButtonContainer>
          <StyledOptionsContainer>
            {regionSelection && (
              <RegionButtonContainer>
                {voiceEnabled && (
                  <Box>
                    <Assistant biggestCity={biggestCity} />
                  </Box>
                )}
                {regionSelection}
              </RegionButtonContainer>
            )}
            {listMapToggle && (
              <Stack
                direction={"column"}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                {listMapToggle}
              </Stack>
            )}
          </StyledOptionsContainer>
        </StyledProjectAndRegion>
        <StyledMapContentContainer ref={mapContentContainerRef}>
          {hideMap && !isLoadingData() && (
            <EmptyView
              errorTitle="No National Map"
              errorSubtitle="Please select a region to view the map of that region."
            />
          )}
          {isLoadingData() && <EmptyView isLoading />}
          {!isLoadingData() &&
            !maptualList?.list?.items?.length &&
            !hideMap && (
              <Grow in timeout={500}>
                <StyledUnavailableContainer>
                  <StyledLabel>Map Unavailable</StyledLabel>
                  <StyledSecondaryLabel>
                    We do not currently have a map available for the selected
                    region. Please either switch to hcp view, or select another
                    region.
                  </StyledSecondaryLabel>
                </StyledUnavailableContainer>
              </Grow>
            )}
          {!isLoadingData() &&
            filteredMaptualList?.list?.items?.length > 0 &&
            !hideMap && (
              <>
                <StyledMapList isFilterOpen={isFilterDrawerOpen}>
                  <MapList
                    mapZoom={mapZoom}
                    visibleMaptualList={visibleMaptualList}
                    visibleMaptualListLoading={visibleMaptualListLoading}
                    selectedMaptualListItem={selectedMaptualListItem}
                    onSelectMaptualListItem={selectMaptualListItem}
                    groupSelected={groupSelected}
                    isPopoverOpen={isEntityPopoverOpen}
                    setIsPopoverOpen={setIsEntityPopoverOpen}
                    setIsEntityFullModalOpen={() => null}
                    onEntityDetailsClick={onEntityDetailsClick}
                    objective={objective}
                    selectedObjectiveIndex={objectiveIndex}
                    routeEntities={routeEntities}
                    setRoute={setRoute}
                    setIsRouteLoading={setIsRouteLoading}
                    mapContentContainerRef={mapContentContainerRef}
                    filteredEntityMapData={filteredMaptualList?.list?.items}
                    EntityPopover={EntityPopover}
                    isSuggestedEntitiesFilterActive={
                      isSuggestedEntitiesFilterActive
                    }
                  />
                </StyledMapList>
                <StyledMap>
                  {routeEntities?.length > 0 && (
                    <MapRoute
                      setRouteEntities={setRouteEntities}
                      routeEntities={routeEntities}
                      routeInfo={routeInfo}
                      setRouteInfo={setRouteInfo}
                      isRouteLoading={isRouteLoading}
                      setIsRouteLoading={setIsRouteLoading}
                      handleRouteEntityClick={centerMapOnEntity}
                    />
                  )}
                  <EntityMap
                    setMapZoom={setMapZoom}
                    setMapCenter={(center) => setMapCenter(center)}
                    mapCenter={mapCenter}
                    allCoordinateData={maptualList?.list?.items}
                    coordinateData={filteredMaptualList?.list?.items}
                    updateBoundedCoordinates={(coordinates) => {
                      selectCoordinates(coordinates);
                    }}
                    updateIsNavigating={(newIsNavigating) =>
                      newIsNavigating
                        ? setVisibleMaptualListLoading(true)
                        : null
                    }
                    onPointClick={selectMaptualListItem}
                    onSpiderClick={selectGroupCoordinates}
                    selectedObjectiveIndex={objectiveIndex}
                    selectedEntity={selectedMaptualListItem}
                    routeEntities={routeEntities}
                    setIsPopoverOpen={setIsEntityPopoverOpen}
                    clearSelectedMaptualListItem={clearSelectedMaptualListItem}
                    setRouteInfo={(info) => setRouteInfo(info)}
                    mapIdOverride={mapIdOverride}
                    defaultZoom={defaultZoom}
                    powerScoreDisplay={powerScoreDisplay}
                  />
                </StyledMap>
              </>
            )}
        </StyledMapContentContainer>
      </StyledMapContainer>
    </>
  );
};
