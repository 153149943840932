import { useContext, useEffect, useMemo, useState } from 'react';
import { MapView } from '@odaia/shared-components/src/map/mapView';
import { MapViewContext } from '@odaia/shared-components/src/contexts/mapViewContext';
import { useSnackbar } from '@odaia/ui/src/components/snackbar';
import { MaptualListObjective } from '@odaia/domain/src/types';
import { PowerScoreDisplay } from '@odaia/shared-components/src/enum/powerScoreDisplay';
import { useEntityCuration } from '~/request/queries';
import { FieldContext } from '../fieldContext';
import { useEntityCoordinates } from './hooks/useEntityCoordinates';
import { useEntityPrecallInsights } from './hooks/useEntityPrecallInsights';
import { useEntityRuleInsights } from './hooks/useEntityRuleInsights';
import { MapToggle } from './mapToggle';
import { RegionSelection } from '../navigation/regionSelection/RegionSelection';
import { EntityPopover } from './entityPopover';
import { EntityFullModal } from './entityFullModal';
import { AppContext } from '../../../appContext';
import useFeatureToggles from '../../../../../hooks/useFeatureToggles';
import { useIsAuthorized } from '../../../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../../../constants';
import useRelevantUserSpecialties from '../../../../../hooks/useRelevantUserSpecialties';
import { useUserCreatedLists } from '../../../../fieldview/useUserCreatedLists';
import { useSegmentFilters } from '../../../../fieldview/useSegmentFilters';

export const MapViewProvider = () => {
  const [objective, setObjective] = useState<MaptualListObjective | null>(null);
  const [objectiveIndex, setObjectiveIndex] = useState<number | null>(null);
  const [mergedMaptualList, setMergedMaptualList] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { profile } = useContext(AppContext);
  const {
    project,
    maptualListMetadata,
    projectMaptualList,
    maptualList,
    isMaptualListLoading,
    isMaptualListsLoading,
    maptualListObjectives,
    setObjective: setFieldObjective,
    objective: fieldObjective,
  } = useContext(FieldContext);

  useEffect(() => {
    if (
      projectMaptualList?.list?.items &&
      maptualList?.list?.items &&
      !maptualList.list.items[0].objectiveScores
    ) {
      const updatedList = {
        ...maptualList,
        list: {
          ...maptualList.list,
          items: maptualList.list.items.map((item) => {
            const entity = projectMaptualList.list.items.find(
              (i) => i.entityId === item.entityId
            );

            const entityObjectiveScores = entity?.objectiveScores || [
              entity?.maptualDisplayScore,
            ];

            return {
              ...item,
              entityId: parseInt(item.entityId, 10),
              objectiveScores: entityObjectiveScores,
            };
          }),
        },
      };
      setMergedMaptualList(updatedList);
    }
  }, [projectMaptualList, maptualList]);

  useEffect(() => {
    if (
      maptualListObjectives?.length > 0 &&
      (!fieldObjective || !fieldObjective.id)
    ) {
      setFieldObjective(maptualListObjectives[0]);
      setObjective(maptualListObjectives[0]);
      setObjectiveIndex(0);
    }
  }, [maptualListObjectives]);

  const setMapObjective = (objective: MaptualListObjective, index: number) => {
    setObjective(objective);
    setObjectiveIndex(index);
    setFieldObjective(objective);
  };

  const {
    data: entityCoordinatesData,
    isLoading: isLoadingEntityCoords,
    isError: isErrorEntityCoords,
  } = useEntityCoordinates({
    maptualListMetadata,
    project,
  });
  const productLineId = project?.metadata?.productLineId;
  const { projectId } = project?.metadata || {};

  const {
    data: entityPrecallInsights,
    isLoading: isEntityPrecallInsightsLoading,
  } = useEntityPrecallInsights({
    maptualListMetadata,
    projectId,
    objectiveId: objective?.id,
  });

  const { data: entityRuleInsights, isLoading: isEntityRuleInsightsLoading } =
    useEntityRuleInsights({
      regionId: maptualListMetadata.sfMaptualListId,
      productLineId,
      projectId,
      objectiveId: objective?.id,
    });

  const { segmentFilters, isSegmentFiltersBusy } = useSegmentFilters({
    objectiveId: objective?.id,
  });

  const { data: entityCuration, isLoading: isEntityCurationLoading } =
    useEntityCuration({
      regionId: maptualListMetadata.sfMaptualListId,
      objectiveId: objective?.id,
    });
  const featureToggles = useFeatureToggles();
  const canUserFilterMapView =
    featureToggles(profile.userGroup, 'mapViewFiltering') ||
    useIsAuthorized([RBAC_PERMISSIONS.MAP_VIEW_FILTERING]);
  const voiceEnabled =
    featureToggles(profile.userGroup, 'maptualVoiceAssistant') &&
    profile?.role?.type === 'ADMIN';
  const maptualVoiceEnabled =
    featureToggles(profile.userGroup, 'maptualVoiceAssistantBE') &&
    profile?.role?.type === 'ADMIN';
  const relevantUserSpecialties = useRelevantUserSpecialties();

  const { userCreatedLists } = useUserCreatedLists({
    userId: profile?.userId,
  });

  const productLineUserCreatedLists = userCreatedLists?.[productLineId];

  const anonymize = voiceEnabled;

  const mapContextValues = useMemo(
    () => ({
      projectId,
      productLineId,
      maptualListMetadata,
      maptualList: mergedMaptualList,
      isMaptualListLoading,
      isMaptualListsLoading,
      maptualListObjectives,
      entityCoordinatesData,
      isLoadingEntityCoords,
      isErrorEntityCoords,
      objective,
      objectiveIndex,
      entityPrecallInsights,
      isEntityPrecallInsightsLoading,
      entityRuleInsights: entityRuleInsights?.data,
      isEntityRuleInsightsLoading,
      enqueueSnackbar,
      canUserFilterMapView,
      relevantUserSpecialties,
      productLineUserCreatedLists,
      entityCuration,
      isEntityCurationLoading,
      voiceEnabled,
      maptualVoiceEnabled,
      anonymize,
      setMapObjective,
      filterMetadata: segmentFilters,
      filterMetadataLoading: isSegmentFiltersBusy,
      usergroup: profile?.userGroup,
      powerScoreDisplay: PowerScoreDisplay.POWERSCORE,
    }),
    [
      projectId,
      productLineId,
      maptualListMetadata,
      mergedMaptualList,
      isMaptualListLoading,
      isMaptualListsLoading,
      maptualListObjectives,
      entityCoordinatesData,
      isLoadingEntityCoords,
      isErrorEntityCoords,
      objective,
      objectiveIndex,
      entityPrecallInsights,
      isEntityPrecallInsightsLoading,
      entityRuleInsights,
      isEntityRuleInsightsLoading,
      enqueueSnackbar,
      canUserFilterMapView,
      relevantUserSpecialties,
      productLineUserCreatedLists,
      entityCuration,
      isEntityCurationLoading,
      voiceEnabled,
      maptualVoiceEnabled,
      anonymize,
      setMapObjective,
      segmentFilters,
      isSegmentFiltersBusy,
      profile.userGroup,
    ]
  );
  return (
    <MapViewContext.Provider value={mapContextValues}>
      <MapView
        listMapToggle={<MapToggle />}
        regionSelection={
          maptualListMetadata?.listName && (
            <RegionSelection
              regionName={
                anonymize
                  ? `Territory ${String(
                      maptualListMetadata?.sfMaptualListId
                    ).slice(-3)}`
                  : maptualListMetadata?.listName
              }
              anonymizeRegions={anonymize}
            />
          )
        }
        EntityPopover={EntityPopover}
        EntityFullModal={EntityFullModal}
        moveZoomControlsUp
      />
    </MapViewContext.Provider>
  );
};
