import styled from "@emotion/styled";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { MouseEvent, useState, useEffect } from "react";
import { SnackbarDisplay } from "../snackbar/Snackbar";

export const POWERSCORE_FEEDBACK_STATES = {
  YES: "YES",
  NO: "NO",
  NOT_SURE: "NOT SURE",
  NONE: "NONE",
};

export interface PowerScoreFeedbackProps {
  entityId: string;
  entityType: string;
  onFeedbackButtonClick?: (newSelectionState: string) => void;
}
export const PowerScoreFeedback = ({
  entityId,
  entityType,
  onFeedbackButtonClick = () => {},
}: PowerScoreFeedbackProps) => {
  const [selectionState, setSelectionState] = useState<string>(
    POWERSCORE_FEEDBACK_STATES.NONE
  );
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [snackPack, setSnackPack] = useState([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  const handleFeedbackButtonClick = (
    _: MouseEvent<HTMLElement, MouseEvent>,
    selection: string
  ) => {
    const newSelection = selection || POWERSCORE_FEEDBACK_STATES.NONE;
    setSelectionState(newSelection);
    if (newSelection !== POWERSCORE_FEEDBACK_STATES.NONE) {
      setSnackPack((prev) => [...prev, { key: new Date().getTime() }]);
    } else {
      setIsSnackBarOpen(false);
    }
    onFeedbackButtonClick(newSelection);
  };

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setIsSnackBarOpen(true);
    } else if (snackPack.length && messageInfo && isSnackBarOpen) {
      setIsSnackBarOpen(false);
    }
  }, [snackPack, messageInfo, isSnackBarOpen]);

  return (
    <>
      <Wrapper>
        <AcknowledgementText>
          Do you understand why this {entityType} has this PowerScore?
        </AcknowledgementText>
        <StyledToggleButtonGroup
          value={selectionState}
          exclusive
          onChange={handleFeedbackButtonClick}
        >
          <StyledToggleButton
            value={POWERSCORE_FEEDBACK_STATES.YES}
            variant="contained"
          >
            Yes
          </StyledToggleButton>
          <StyledToggleButton
            value={POWERSCORE_FEEDBACK_STATES.NO}
            variant="contained"
          >
            No
          </StyledToggleButton>
          <StyledToggleButton
            value={POWERSCORE_FEEDBACK_STATES.NOT_SURE}
            variant="contained"
          >
            I'm Not Sure
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </Wrapper>
      <SnackbarDisplay
        key={
          messageInfo
            ? `powerscore-feedback-${entityId}-${messageInfo.key}`
            : undefined
        }
        isOpen={isSnackBarOpen}
        handleClose={() => setIsSnackBarOpen(false)}
        handleUndo={() =>
          handleFeedbackButtonClick(null, POWERSCORE_FEEDBACK_STATES.NONE)
        }
        handleExited={() => {
          setMessageInfo(undefined);
        }}
        message={
          <Box display="flex" flexDirection="column" gap={0.5}>
            <StyledSnackbarTitle variant="title3">
              Thank you for submitting your feedback.
            </StyledSnackbarTitle>
            <StyledSnackbarBody variant="subtitle1">
              This data will help us improve our PowerScore explainability.
            </StyledSnackbarBody>
          </Box>
        }
      />
    </>
  );
};

const Wrapper = styled(Box)(({ theme: { themeColors } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: 16,
  border: `1px solid ${themeColors.borderPrimaryColor}`,
  borderRadius: 4,
}));

const AcknowledgementText = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.tertiaryColor,
    fontSize: 14,
  })
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  display: "flex",
  gap: 8,
}));

const StyledToggleButton = styled(ToggleButton)(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    height: 32,
    "&:first-of-type, :not(:first-child):not(:last-child), &:last-of-type": {
      borderRadius: 4,
      border: `1px solid ${themeColors.buttonBorderColor}`,
    },
  })
);

const StyledSnackbarTitle = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    textAlign: "left",
    color: themeColors.alertPrimaryInfoTitleColor,
  })
);

const StyledSnackbarBody = styled(Typography)(({ theme: { themeColors } }) => ({
  textAlign: "left",
  color: themeColors.alertPrimaryInfoDescriptionColor,
}));
