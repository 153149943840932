import { useContext, useEffect, useMemo, useState } from 'react';
import {
  CallPlanContext,
  CallPlanTableProvider,
} from '@odaia/shared-components/src/callPlan';
import { RowProvider } from '@odaia/shared-components/src/callPlan/RowProvider';
import { TableComponent } from '@odaia/shared-components/src/entitiesTable/TableComponent';
import { FieldContext } from '../fieldContext';

export const ListProvider = ({
  maptualList,
  projectTargetProducts,
  isProductsLoading,
  onRowClick,
  showCurationData,
  isSuggestedEntitiesFilterActive,
  powerScoreDisplay,
}) => {
  const [rows, setRows] = useState({});
  const [contextData, setContextData] = useState({});
  const {
    project,
    maptualListMetadata,
    segmentList,
    objective,
    maptualListObjectives,
  } = useContext(FieldContext);

  const productLineId = project?.metadata?.productLineId;
  const projectId = project?.metadata?.projectId;
  const regionId = maptualListMetadata?.sfMaptualListId;
  const isMultiObjective = maptualListObjectives?.length > 1;

  useMemo(
    () =>
      setContextData({
        productLineId,
        projectTargetProducts,
        isProductsLoading,
        projectId,
        regionId,
        rows,
        showCurationData,
        isSuggestedEntitiesFilterActive,
        isMultiObjective,
        powerScoreDisplay,
      }),
    [
      productLineId,
      projectTargetProducts,
      isProductsLoading,
      projectId,
      regionId,
      rows,
      showCurationData,
      isSuggestedEntitiesFilterActive,
      isMultiObjective,
      powerScoreDisplay,
    ]
  );

  useEffect(() => {
    // construct the rows here
    if (
      !maptualList?.list?.items ||
      !objective ||
      !segmentList ||
      !projectTargetProducts ||
      !regionId
    ) {
      return;
    }
    const objectiveMetadata = projectTargetProducts.find(
      (product) => product.objectiveId === objective.id
    );
    setRows({
      ...rows,
      [regionId]: maptualList.list.items.map((item) => ({
        id: item.entityId,
        entity: {
          id: item.entityId,
          name: item.primaryText,
          specialty: item.specialty,
          lastContactedDate: item.secondaryText.split('-')[0],
          city: 'test city',
          province: 'test province',
          postalCode: 'test postal',
          address: item.address,
        },
        segments: item.segments.map((segment) => ({
          ...segment,
          title: segment.segmentName,
          // TODO better way to pick customer vs our segments
          type:
            segmentList.find((s) => s.name === segment.segmentName)?.description
              ?.length > 0
              ? 'NON'
              : 'CUSTOMER',
        })),
        score: item.maptualFractionalScore,
        objectiveScores: item.objectiveScores,
        product: objectiveMetadata.name,
        objectiveId: objective.id,
        insight: item?.insight,
        qtdCallCount: item.crmData.qtdCallCount,
        curationRank: item.curationRank,
        customerTarget: item.customerTarget,
      })),
    });
  }, [maptualList, objective, segmentList, projectTargetProducts, regionId]);

  return (
    <CallPlanContext.Provider value={contextData}>
      <CallPlanTableProvider
        onRowClick={(entityId) => onRowClick(entityId)}
        RowComponent={RowProvider}
        TableComponent={TableComponent}
      />
    </CallPlanContext.Provider>
  );
};
