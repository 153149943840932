import {
  CustomerMarketsApi,
  CustomerProductLinesApi,
  CustomerSegmentsApi,
} from '@odaia/clients/configAPI';
import { baseConfigUrlOpenApi, configProxyApiInstanceOpenApi } from './config';
import { apiInstanceCreator } from './utils';

const createApiInstance = apiInstanceCreator(
  baseConfigUrlOpenApi,
  configProxyApiInstanceOpenApi
);

export const marketsApi = createApiInstance(CustomerMarketsApi);
export const productLinesApi = createApiInstance(CustomerProductLinesApi);
export const customerSegmentsApi = createApiInstance(CustomerSegmentsApi);
