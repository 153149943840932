import { useEffect, useState, useContext } from 'react';
import { getTimeframeName, getComparisonTimeFrames } from './utils';
import { useTerritoryOverviewData } from './useTerritoryOverviewData';
import { trackTerritoryOverviewOptionSet } from '../../trackers/mixpanel';
import { FieldContext } from '../application/appViews/field/fieldContext';
import { Cadence } from './enums';

export const useFieldTerritoryOverviewContext = () => {
  const {
    project: projectItem,
    maptualListMetadata,
    isMaptualListsLoading,
  } = useContext(FieldContext);
  const { projectId } = projectItem.metadata || {};

  const projectName = projectItem?.metadata?.projectName || '';
  // TODO ODAIA-8744: Change sfMaptualListId to maptualListId when
  // all endpoints are using sfMaptualListId
  const {
    sfMaptualListId,
    maptualListId,
    listName: territoryName,
  } = maptualListMetadata ?? {};

  const [selectedMetric, setSelectedMetric] = useState();
  const [selectedObjective, setSelectedObjective] = useState();
  const [selectedTimeframeID, setSelectedTimeframeID] = useState();
  const [comparisonTimeFrameId, setComparisonTimeFrameId] = useState();

  const updateSelectedObjective = (newObjective) => {
    setSelectedObjective(newObjective);

    setMetricOptions(objectiveOptions[newObjective]?.metrics);
    const metric = Object.values(objectiveOptions[newObjective]?.metrics)[0];
    setSelectedMetric(metric.id);
    setCadenceOptions(metric.cadences);

    setSelectedTimeframeID((prevTimeframeId) =>
      metric.cadences.includes(prevTimeframeId)
        ? prevTimeframeId
        : metric.cadences[0]
    );

    trackTerritoryOverviewOptionSet(
      'Objective',
      objectiveOptions[newObjective].label,
      objectiveOptions[selectedObjective]?.label,
      [
        getTimeframeName(selectedTimeframeID),
        objectiveOptions[newObjective].label,
        objectiveOptions.metrics[selectedMetric]?.label,
      ],
      false
    );
  };

  const {
    dataResponse,
    status,
    metadataResponse,
    metadataStatus,
    isDataLoading,
    isDataError,
    isMetadataLoading,
  } = useTerritoryOverviewData({
    projectId,
    maptualListId,
    sfMaptualListId,
    selectedMetric,
    selectedObjective,
    selectedTimeframeID,
    comparisonTimeFrameId,
  });

  const [objectiveOptions, setObjectiveOptions] = useState({});
  const [metricOptions, setMetricOptions] = useState({});
  const [cadenceOptions, setCadenceOptions] = useState([]);

  useEffect(() => {
    if (!metadataResponse?.data) return;

    const objectives = metadataResponse?.data?.objectives ?? {};
    const defaultObjective = Object.values(objectives)?.[0] ?? {};
    setObjectiveOptions(objectives);
    setSelectedObjective(defaultObjective?.id);

    const metrics = defaultObjective?.metrics ?? {};
    const defaultMetric = Object.values(metrics)?.[0] ?? {};
    setMetricOptions(metrics);
    setSelectedMetric(defaultMetric?.id);

    const cadences = defaultMetric?.cadences ?? [];
    const defaultCadence = cadences?.[0] ?? null;
    setCadenceOptions(cadences);
    setSelectedTimeframeID(defaultCadence);

    const comparisonTimeFrames = getComparisonTimeFrames(defaultCadence);
    const defaultComparisonTimeFrameId = comparisonTimeFrames?.[0]?.id ?? null;
    setComparisonTimeFrameId(defaultComparisonTimeFrameId);
  }, [metadataResponse?.data]);

  useEffect(() => {
    if (dataResponse && objectiveOptions && metricOptions) {
      trackTerritoryOverviewOptionSet(
        '',
        '',
        '',
        [
          getTimeframeName(selectedTimeframeID),
          objectiveOptions[selectedObjective]?.label,
          metricOptions[selectedMetric]?.label,
        ],
        true
      );
    }
  }, [dataResponse]);

  const updateSelectedMetric = (newMetric) => {
    trackTerritoryOverviewOptionSet(
      'Metric',
      metricOptions[newMetric].label,
      metricOptions[selectedMetric]?.label,
      [
        getTimeframeName(selectedTimeframeID),
        objectiveOptions[selectedObjective]?.label,
        metricOptions[newMetric].label,
      ],
      false
    );

    setSelectedMetric(newMetric);

    setCadenceOptions(metricOptions[newMetric]?.cadences);

    setSelectedTimeframeID((prevCadence) => {
      const newTimeframe = metricOptions[newMetric]?.cadences.includes(
        prevCadence
      )
        ? prevCadence
        : metricOptions[newMetric]?.cadences[0];

      handleComparisonTimeFrameChange(newTimeframe);

      return newTimeframe;
    });
  };

  const handleComparisonTimeFrameChange = (cadence: Cadence) => {
    const comparisonTimeFrames = getComparisonTimeFrames(cadence);
    const isOutdatedComparisonTimeFrameId =
      comparisonTimeFrames &&
      !comparisonTimeFrames.some(
        (comparisonTimeFrame) =>
          comparisonTimeFrame.id === comparisonTimeFrameId
      );
    if (isOutdatedComparisonTimeFrameId) {
      const newComparisonTimeFrameId = comparisonTimeFrames?.[0]?.id ?? null;
      setComparisonTimeFrameId(newComparisonTimeFrameId);
    }
  };

  return {
    projectId,
    regionId: maptualListId,
    projectName,
    territoryName,
    data: dataResponse,
    metadata: metadataResponse,
    metadataStatus,
    metricOptions,
    objectiveOptions,
    status,
    isDataLoading,
    isDataError,
    isMetadataLoading: isMetadataLoading || isMaptualListsLoading,
    selectedObjective,
    updateSelectedObjective,
    setSelectedObjective,
    selectedMetric,
    updateSelectedMetric,
    setSelectedMetric,
    selectedTimeframeID,
    setSelectedTimeframeID,
    cadenceOptions,
    comparisonTimeFrameId,
    setComparisonTimeFrameId,
    handleComparisonTimeFrameChange,
  };
};
