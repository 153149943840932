import { ReactNode } from "react";
import {
  AccordionDetails,
  Box,
  Popper,
  styled,
  Typography,
} from "@mui/material";
import {
  Contribution,
  ContributionCategoricalMetric,
  ContributionContentType,
  ContributionDataInner,
  ContributionMetric,
  EntityType,
} from "@odaia/clients/maptualAPI";
import { ClickableTooltip } from "./ClickableTooltip";
import {
  CONTRIBUTION_LABEL,
  CONTRIBUTION_TYPE,
  METRIC_DATA_FORMAT,
} from "./constants";
import { commaSeparatedStringList, METRIC_UNIT } from "./utils";

const SEGMENT_NAMES = {
  starters: "Starter",
  rising_stars: "Rising Star",
};

export const ContributionBreakdownContent = ({
  content,
  category,
  entityType,
}: {
  content: Contribution;
  category: CONTRIBUTION_TYPE;
  entityType: EntityType;
}) => {
  const hasNoContributingData = content.data.every((obj) => {
    const dataKey = Object.keys(obj).find((key) => key !== "type");
    return dataKey && Array.isArray(obj[dataKey]) && obj[dataKey].length === 0;
  });

  return (
    <StyledAccordionDetails data-testid="powerscore-contribution-breakdown-content">
      {hasNoContributingData && (
        <Typography variant="body2">
          This {entityType} has had no engagement with{" "}
          {missingContributingFactorsText(
            content.contributingFactors,
            category
          )}{" "}
          considered in PowerScore over the recent 12 months.
        </Typography>
      )}
      {!hasNoContributingData && (
        <Box>
          {formatBreakdownDescription(content.data, entityType, category)}
        </Box>
      )}
    </StyledAccordionDetails>
  );
};

const getSegmentDescription = (
  segmentName: string,
  entityType: EntityType,
  metric: string,
  targetBasket: string,
  competitiveBasket: string = ""
) => {
  const descriptions = {
    starters: `${entityType}s that have generated ${targetBasket} ${metric} for the first time within the last 3 months, or are predicted to in the near future based on similarity to other ${entityType}s.`,
    rising_stars: `${entityType}s that are predicted to generate above-average ${metric} volume in the total market (${targetBasket} + ${competitiveBasket}) in the coming 18 to 24 months.`,
  };

  return descriptions[segmentName];
};

const missingContributingFactorsText = (
  contributingFactors: string[],
  category: CONTRIBUTION_TYPE
): string => {
  if (category === CONTRIBUTION_TYPE.CURRENT) {
    const factors = contributingFactors.map((factor) => factor.toUpperCase());
    return `${commaSeparatedStringList(factors)} metrics`;
  }
  return "Potential metrics";
};

const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme: { themeColors } }) => ({
    padding: "0 12px 16px 31px",
    color: themeColors.secondaryTextColor,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    fontSize: 14,
  })
);

const UnderlinedText = styled("span")({
  textDecoration: "underline",
  cursor: "pointer",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: 1.2,
});

const StyledTooltipPopper = styled(Popper)(({ theme: { themeColors } }) => ({
  ".MuiTooltip-arrow::before": {
    backgroundColor: themeColors.tooltipSurfaceColor,
  },
  ".MuiTooltip-tooltip": {
    backgroundColor: themeColors.tooltipSurfaceColor,
    color: themeColors.tooltipContentColor,
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.2,
  },
  '&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
    marginTop: 6,
  },
}));

const formatMetricTooltip = (metric: ContributionMetric) => {
  const dateTooltip = `${metric.dateFrom} to ${metric.dateTo}`;

  if (metric.unit === METRIC_UNIT.RATE) {
    return `Average from ${dateTooltip}`;
  }

  return dateTooltip;
};

const formatBreakdownDescription = (
  data: ContributionDataInner[],
  entityType: EntityType,
  category: CONTRIBUTION_TYPE
) => {
  let metricDescription: (string | ReactNode)[] = [];
  let segmentDescription: ReactNode[] = [];

  data.forEach((dataset) => {
    if (dataset.type === ContributionContentType.Metrics) {
      metricDescription = dataset.metrics.map(
        (metric: ContributionMetric | ContributionCategoricalMetric) =>
          metric.type === METRIC_DATA_FORMAT.CATEGORICAL ? (
            metric.label
          ) : (
            <Tooltip
              tooltipText={formatMetricTooltip(metric)}
              inlineText={`${metric.basketName} ${metric.metric}`}
            />
          )
      );
    }

    if (dataset.type === ContributionContentType.Segments) {
      return (segmentDescription = dataset.segments.map((segment) => (
        <Tooltip
          tooltipText={getSegmentDescription(
            segment.name,
            entityType,
            segment.details.metric,
            segment.details.targetBasket,
            segment.details.competitiveBasket
          )}
          inlineText={SEGMENT_NAMES[segment.name as keyof typeof SEGMENT_NAMES]}
        />
      )));
    }
  });

  const description: (string | ReactNode)[] = [];

  metricDescription.forEach((metric, i) => {
    description.push(metric);
    if (i === metricDescription.length - 2 && !segmentDescription.length) {
      description.push(" and ");
    } else if (i < metricDescription.length - 1) {
      description.push(", ");
    }
  });

  if (metricDescription.length && segmentDescription.length) {
    description.push(" and ");
  }

  segmentDescription.forEach((segment, i) => {
    description.push(segment);
    if (i === segmentDescription.length - 2) {
      description.push(" and ");
    } else if (i < segmentDescription.length - 1) {
      description.push(", ");
    } else {
      description.push(" segment membership");
    }
  });

  description.push(
    ` over the last 12 months ${description.length === 1 ? "contributes" : "contribute"} to ${CONTRIBUTION_LABEL[category]} within this HCP's PowerScore.`
  );

  return description;
};

const Tooltip = ({
  tooltipText,
  inlineText,
}: {
  tooltipText: string;
  inlineText: string;
}) => (
  <ClickableTooltip
    title={tooltipText}
    arrow
    placement="bottom"
    PopperComponent={StyledTooltipPopper}
  >
    <UnderlinedText textDecoration="underline">{inlineText}</UnderlinedText>
  </ClickableTooltip>
);
