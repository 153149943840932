/* eslint-disable no-restricted-syntax */

import { ListType, UserCreatedLists } from '@odaia/domain/src/types';

export const getEntityInsightToDisplay = (
  entityId,
  entityPrecallInsights,
  entityRuleInsights
) => {
  const entityPrecallInsight =
    entityPrecallInsights.length > 0
      ? entityPrecallInsights?.find(
          (entity) => Number(entity.entityId) === Number(entityId)
        )
      : null;
  const precallInsightToDispaly = entityPrecallInsight?.insights?.find(
    (insight) => insight.priority === 1
  );

  const ruleInsight = entityRuleInsights?.[entityId]
    ? {
        title: entityRuleInsights[entityId].title,
        description: entityRuleInsights[entityId].message,
      }
    : null;
  return precallInsightToDispaly || ruleInsight || null;
};

export const getCustomListMembership = (
  entityId: number,
  userCreatedListEntities: UserCreatedLists | undefined
) => {
  const customListMembership: Record<ListType, boolean> = {
    noSee: false,
    starred: false,
    longTermLeave: false,
  };
  if (!userCreatedListEntities) {
    return customListMembership;
  }
  for (const key of Object.keys(userCreatedListEntities) as ListType[]) {
    if (userCreatedListEntities[key].includes(Number(entityId))) {
      customListMembership[key] = true;
    }
  }
  return customListMembership;
};
