import axios from 'axios';
import { fieldApi } from './maptualApiClient';

export const getTerritoryOverviewData = ({
  projectId,
  regionId,
  objectiveId,
  metric,
  cadence,
  comparisonTimeFrameId,
}) => {
  try {
    return fieldApi.getTerritoryOverviewData({
      projectId,
      regionId,
      objectiveId,
      metricName: metric,
      cadence,
      comparisonTimeFrame: comparisonTimeFrameId,
    });
  } catch (error) {
    switch (error?.response?.status) {
      case 307: {
        if (error.response.data) {
          return axios.get(error.response.data, { timeout: 60000 });
        }
        return Promise.reject(error);
      }
      default:
        return Promise.reject(error);
    }
  }
};
