import { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import PauseIcon from '@mui/icons-material/PauseCircleOutline';
import PlayIcon from '@mui/icons-material/PlayCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OutboxIcon from '@mui/icons-material/Outbox';
import PreviewIcon from '@mui/icons-material/Preview';
import { useDispatch, useSelector } from 'react-redux';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { cloneDeep } from 'lodash';
import { getProjectList } from '~/slices/project';
import { queryKeys } from '~/request/queries';
import { useQueryClient } from 'react-query';
import ProjectCardContent from './projectTiles/projectCardContent';
import {
  PROJECT_STATUS,
  PATCH_USERMANAGEMENT_PROJECT_ACTIONS,
  PROJECT_REFRESH_STATUS,
  RBAC_PERMISSIONS,
} from '../../constants';
import { patchProject } from '../../slices/userManagement';
import { toCapitalize } from '../../utils/toCapitalize';
import { useDeployDialogContext } from './deployCsvDialog/deployDialogContext';
import { DEPLOY_CSV_STATES } from './deployCsvDialog/sharedComponents';
import { showConfirmationDialog } from '../../slices/adminView';
import { themeColors as adminColors } from '../../styles/adminStyles';

import { PROJECT_REFRESH_MODAL_STATES } from './refreshConfirmationModals/projectRefreshModalStates';
import { RefreshModalHandler } from './refreshConfirmationModals/refreshModalHandler';
import { useIsAuthorized } from '../../hooks/useIsAuthorized';
import AutomatedTestResults from './projectTiles/subrows/automatedReports/automatedTestResults';
import ProjectRunStatus from './projectTiles/subrows/projectRunStatus/projectRunStatus';

export const RefreshingLabel = styled('div')({
  padding: '8px 16px',
  color: adminColors.darkTheme.borderLowContrast,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '120%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
});

export const AcceptRefreshButton = styled(Button)({
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '120%',
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: adminColors.darkTheme.adminPanelPrimaryBackground,
    border: `1px solid ${adminColors.darkTheme.borderLowContrast}`,
  },
  media: {
    height: 'auto',
  },
  textStyle: {
    margin: 0,
    padding: 0,
  },
  textStyleDisabled: {
    margin: 0,
    padding: 0,
    color: theme.palette.text.disabled,
  },
  buttonStyle: {
    maxHeight: 34,
    fontWeight: 400,
    padding: '8px 16px',
    backgroundColor: adminColors.darkTheme.adminPanelPrimaryBtnBackground,
    border: `1px solid ${adminColors.darkTheme.borderLowContrast}`,
    '&:hover': {
      backgroundColor: adminColors.darkTheme.adminPanelHoverBtnBackground,
    },
    '&:disabled': {
      color: adminColors.darkTheme.adminPanelDisabledBtnText,
      backgroundColor: adminColors.darkTheme.adminPanelDisabledBtnBackground,
    },
  },
  cardHeaderTypographyStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    textAlign: 'left',
  },
  cardHeaderSubheaderStyle: {
    fontWeight: 400,
    fontSize: 14,
    color: adminColors.darkTheme.adminCardSubheader,
  },
  cardActionAreaStyle: {
    flexGrow: 1,
  },
  cardActionsStyle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 16,
    gap: 8,
    borderTop: `1px solid ${adminColors.darkTheme.borderLowContrast}`,
  },
  noSelectClickable: {
    webkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    cursor: 'pointer',
  },
}));

export const formatObjectives = (objectives) => {
  const objectiveTitles = [];
  if (objectives) {
    objectives.forEach((obj) => {
      let title = `${toCapitalize(obj?.typeDisplay)}: `;
      const products = obj?.params?.products;
      const titleSegments = {};
      products.forEach((product) => {
        const { productFriendlyName, paramType, productLines } = product;
        if (productFriendlyName) {
          titleSegments[paramType] = productFriendlyName;
        } else if (productLines) {
          titleSegments[paramType] = productLines
            .map((p) => toCapitalize(p.productName, [' ', '-']))
            .join(', ');
        }
      });

      if (titleSegments.PRODUCT_FROM && titleSegments.TO_PRODUCT) {
        title += `${titleSegments.TO_PRODUCT} vs. ${titleSegments.PRODUCT_FROM}`;
      } else {
        title += `${titleSegments.TO_PRODUCT || ''}${
          titleSegments.PRODUCT_FROM || ''
        }`;
      }
      if (objectiveTitles.indexOf(title) === -1) {
        objectiveTitles.push(title);
      }
    });
  }
  return objectiveTitles;
};

export default function AdminProjectTile({
  project,
  match,
  deleteProject,
  userGroup,
}) {
  const dispatch = useDispatch();
  const userProjectAssignmentAsyncStatus = useSelector(
    (state) => state.userManagement.projectRequestStatus
  );

  const {
    projectName,
    projectId,
    projectStatus,
    isBusy,
    objectives,
    refreshStatus,
  } = project;
  const queryClient = useQueryClient();
  const classes = useStyles();
  const routeHistory = useHistory();
  const newItemPath = `${match.path}/${projectId}`;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const hasProjectAdminPermissions = useIsAuthorized([
    RBAC_PERMISSIONS.PROJECTS_ADMIN,
  ]);

  const openProject = () => {
    routeHistory.push(newItemPath);
  };

  const handleUpdateProjectStatus = (projectMetadata, status) => {
    dispatch(
      patchProject({
        projectId: projectMetadata.projectId,
        action:
          status === PROJECT_STATUS.READY
            ? PATCH_USERMANAGEMENT_PROJECT_ACTIONS.REMOVE
            : PATCH_USERMANAGEMENT_PROJECT_ACTIONS.ADD,
      })
    );
    queryClient.invalidateQueries(queryKeys.projectList);
    dispatch(getProjectList());
  };
  const toggleConfirmationDialog = (isOpen) => {
    setIsConfirmationOpen(isOpen);
    handleMenuClose();
  };

  const deleteHandler = () => {
    deleteProject({ projectId });
    toggleConfirmationDialog(false);
  };

  const removeObjectivesFromMetadata = (projectMetadata) => {
    const result = cloneDeep(projectMetadata);
    delete result.objectives;
    return result;
  };

  const renderNonDeployedButton = (props) => (
    <Button
      variant="contained"
      className={classes.buttonStyle}
      startIcon={<PlayIcon />}
      onClick={() =>
        handleUpdateProjectStatus(
          removeObjectivesFromMetadata(project),
          PROJECT_STATUS.DEPLOYED
        )
      }
      {...props}
    >
      Deploy
    </Button>
  );

  const renderDeployedButton = (props) => (
    <Button
      variant="contained"
      color="primary"
      className={classes.buttonStyle}
      startIcon={<PauseIcon />}
      onClick={() =>
        handleUpdateProjectStatus(
          removeObjectivesFromMetadata(project),
          PROJECT_STATUS.READY
        )
      }
      {...props}
    >
      Undeploy
    </Button>
  );

  const ProjectCardButton = () => {
    const isDeployButtonDisabled =
      isBusy ||
      userProjectAssignmentAsyncStatus === 'PENDING' ||
      projectStatus === PROJECT_STATUS.CREATED;
    const buttonRenderer =
      projectStatus === PROJECT_STATUS.DEPLOYED
        ? renderDeployedButton
        : renderNonDeployedButton;

    return isDeployButtonDisabled
      ? buttonRenderer({
          disabled: true,
          outline: 'outlined',
          endIcon: <CircularProgress color="inherit" size={16} />,
        })
      : buttonRenderer();
  };

  const {
    setActiveProject,
    setDeployDialogState,
    setShowProjectDialog,
    setProjectModalMode,
  } = useDeployDialogContext();

  const openDeployDialog = async () => {
    setActiveProject(await project);
    setDeployDialogState(DEPLOY_CSV_STATES.SELECTION);
    handleMenuClose();
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        title={project.projectName}
        titleTypographyProps={{
          className: `${classes.cardHeaderTypographyStyle} ${classes.noSelectClickable}`,
          onClick: openProject,
        }}
        subheader={project.market}
        subheaderTypographyProps={{
          className: `${classes.cardHeaderTypographyStyle} ${classes.cardHeaderSubheaderStyle} ${classes.noSelectClickable}`,
          onClick: openProject,
        }}
        action={
          <>
            <IconButton
              aria-label="project-more-actions"
              onClick={handleClick}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="project-more-actions-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  setActiveProject(project);
                  setProjectModalMode('view');
                  setShowProjectDialog(true);
                }}
              >
                <ListItemIcon>
                  <PreviewIcon />
                </ListItemIcon>
                View Configurations
              </MenuItem>

              {userGroup.includes('gsk') ? (
                <MenuItem onClick={openDeployDialog}>
                  <ListItemIcon>
                    <OutboxIcon />
                  </ListItemIcon>
                  Deploy CSV to GSK
                </MenuItem>
              ) : null}

              {hasProjectAdminPermissions && (
                <>
                  <MenuItem
                    onClick={() => {
                      dispatch(showConfirmationDialog(projectName, projectId));
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <ClearIcon sx={{ mr: '10px' }} />
                    </ListItemIcon>
                    Clear Cache
                  </MenuItem>
                  <MenuItem
                    disabled={refreshStatus !== PROJECT_REFRESH_STATUS.VALID}
                    onClick={() => {
                      setConfirmationModalStatus(
                        PROJECT_REFRESH_MODAL_STATES.REFRESH
                      );
                    }}
                  >
                    <ListItemIcon>
                      <ReplayIcon />
                    </ListItemIcon>
                    Run Project Refresh
                  </MenuItem>
                  <MenuItem
                    disabled={projectStatus === PROJECT_STATUS.DEPLOYED}
                    onClick={() => toggleConfirmationDialog(true)}
                  >
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    Delete Project
                  </MenuItem>
                </>
              )}
            </Menu>
            <Dialog
              open={isConfirmationOpen}
              onClose={() => toggleConfirmationDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete {projectName} project?
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => toggleConfirmationDialog(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={deleteHandler} color="secondary" autoFocus>
                  Yes, delete
                </Button>
              </DialogActions>
            </Dialog>
            <RefreshModalHandler
              projectName={projectName}
              projectId={projectId}
              confirmationModalStatus={confirmationModalStatus}
              setConfirmationModalStatus={setConfirmationModalStatus}
            />
          </>
        }
      />
      <CardActionArea
        disabled={isBusy}
        onClick={openProject}
        className={classes.cardActionAreaStyle}
      >
        <ProjectCardContent
          projectStatus={projectStatus}
          isBusy={isBusy}
          objectives={formatObjectives(objectives)}
          isAdmin
        >
          <ProjectRunStatus project={project} />
          <AutomatedTestResults project={project} />
        </ProjectCardContent>
      </CardActionArea>

      {hasProjectAdminPermissions && (
        <CardActions className={classes.cardActionsStyle}>
          {refreshStatus === PROJECT_REFRESH_STATUS.TEST ? (
            <>
              <AcceptRefreshButton
                variant="outlined"
                endIcon={<CloseIcon />}
                onClick={() => {
                  setConfirmationModalStatus(
                    PROJECT_REFRESH_MODAL_STATES.REJECT
                  );
                }}
              >
                Reject Refresh
              </AcceptRefreshButton>
              <AcceptRefreshButton
                variant="contained"
                endIcon={<ReplayIcon />}
                onClick={() => {
                  setConfirmationModalStatus(
                    PROJECT_REFRESH_MODAL_STATES.ACCEPT
                  );
                }}
              >
                Accept Refresh
              </AcceptRefreshButton>
            </>
          ) : (
            <>
              <ProjectCardButton />
              {refreshStatus === PROJECT_REFRESH_STATUS.IN_PROGRESS && (
                <RefreshingLabel>
                  Refreshing
                  <CircularProgress size={18} color="inherit" />
                </RefreshingLabel>
              )}
            </>
          )}
        </CardActions>
      )}
    </Card>
  );
}

AdminProjectTile.propTypes = {
  projectName: PropTypes.string,
  projectId: PropTypes.string,
  market: PropTypes.string,
  projectStatus: PropTypes.string,
  isBusy: PropTypes.bool,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  deleteProject: PropTypes.func,
  userGroup: PropTypes.string,
};
