import { configProxyApiInstance, maptualApiInstance } from './config';
import {
  customerSegmentsApi,
  marketsApi,
  productLinesApi,
} from './configApiClient';

// Replaced by backend based on auth credentials
export const PLACEHOLDER_USERGROUP = 'PLACEHOLDER_USERGROUP';

const withoutUserGroup =
  <ResponseType, T extends Record<string, any>>(
    fn: (request: T) => ResponseType
  ) =>
  (request: Omit<T, 'userGroup'>) =>
    fn({ ...request, userGroup: PLACEHOLDER_USERGROUP } as unknown as T);

export const getCustomerMetrics = () =>
  configProxyApiInstance.get('/customer-metrics');

export const getCustomerDomains = () =>
  configProxyApiInstance.get('/customer-domains');

export const getCustomerObjectiveTypes = () =>
  configProxyApiInstance.get('/customer-objective-types');

export const getMetricsForMarket = (regionalTeamId) =>
  configProxyApiInstance.get(
    `/utilities/metrics-from-customer-market/${regionalTeamId}`
  );

export const getCustomerSegments = () =>
  configProxyApiInstance.get(`/customer-segments`);

export const getCustomerProductLines = () =>
  configProxyApiInstance.get(`customer-product-lines`);

export const createCustomerMarket = withoutUserGroup(
  marketsApi.createMarket.bind(marketsApi)
);

export const updateCustomerMarket = withoutUserGroup(
  marketsApi.updateMarket.bind(marketsApi)
);

export const updateCustomerSegment = withoutUserGroup(
  customerSegmentsApi.updateCustomerSegment.bind(customerSegmentsApi)
);

export const updateCustomerObjectiveType = (objectiveTypeId, requestBody) =>
  configProxyApiInstance.put(
    `customer-objective-types/${objectiveTypeId}`,
    requestBody
  );

export const updateCustomerDomains = (requestBody) =>
  configProxyApiInstance.put('customer-domains', requestBody);

export const addCustomerObjectiveType = (requestBody) =>
  configProxyApiInstance.post('customer-objective-types', requestBody);

export const getCustomerProductTrees = () =>
  configProxyApiInstance.get('customer-product-trees');

export function getProducts() {
  return maptualApiInstance.get(`core/products`);
}

export const createProductTree = (requestBody) =>
  configProxyApiInstance.post('customer-product-trees', requestBody);

export const updateProductTree = (requestBody) =>
  configProxyApiInstance.put('customer-product-trees', requestBody);

export const cloneProductTree = (requestBody) =>
  configProxyApiInstance.post('customer-product-trees/clone', requestBody);

export const attachProductTree = withoutUserGroup(
  productLinesApi.attachProductTreeToMarket.bind(productLinesApi)
);

export const deleteProductTree = (productLineId: string) =>
  configProxyApiInstance.delete(`customer-product-trees/${productLineId}`);
