import {
  Typography,
  styled,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { AutocompleteCombobox } from "@odaia/ui/src/components/autocompleteCombobox";
import { PowerScore } from "../../powerScore";

const CheckboxContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "showIcon",
})(({ showIcon }) => ({
  display: "flex",
  gap: showIcon ? 32 : 9,
  columnGap: showIcon ? 84 : 0,
  alignItems: "flex-start",
  flexWrap: "wrap",

  "& .MuiFormControlLabel-label": {
    paddingTop: 1.5,
  },
}));

const SectionTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const SegmentFilterDescription = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.primaryMaptualListFilteringColor,
    marginBottom: "32px",
    marginTop: "4px",
  })
);

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== "showIcon",
})(({ theme: { themeColors }, showIcon }) => ({
  marginLeft: 0,
  color: themeColors.segmentSelectionCaptionColor,
  ".MuiCheckbox-root": {
    padding: 0,
    marginRight: 9,
    marginTop: showIcon ? 8 : 0,
    alignSelf: "start",
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme: { themeColors } }) => ({
  "&.Mui-checked": {
    color: themeColors.checkboxSelected,
  },
}));

const OptionContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 12,
}));

const COMBOBOX_CUTOFF: number = 12;

export interface filterOption {
  id: string;
  label: string;
}

interface MultiSelectFilteringProps {
  title: string;
  description: string;
  filterType: string;
  filterOptions: filterOption[];
  selectedFilters: filterOption[];
  updateFilters: (arg0: string, arg1: string, arg2: boolean) => void;
  showIcon?: boolean;
}

export const MultiSelectFiltering = ({
  title,
  description,
  filterType,
  filterOptions,
  selectedFilters,
  updateFilters,
  showIcon,
}: MultiSelectFilteringProps) => {
  return (
    filterOptions.length > 0 && (
      <>
        <SectionTitle variant="h4">{title}</SectionTitle>
        <SegmentFilterDescription variant="body2">
          {description}
        </SegmentFilterDescription>
        {filterOptions?.length > COMBOBOX_CUTOFF && (
          <AutocompleteCombobox
            options={filterOptions}
            selection={selectedFilters || {}}
            onOptionChange={(item, checked) => {
              updateFilters(item.id, item.label, checked);
            }}
            type={filterType}
          />
        )}
        {filterOptions?.length <= COMBOBOX_CUTOFF && (
          <CheckboxContainer showIcon={showIcon}>
            {filterOptions.map((item) => (
              <StyledFormControlLabel
                key={item.id}
                control={
                  <StyledCheckbox
                    name={item.id}
                    onChange={(event) =>
                      updateFilters(item.id, item.label, event.target.checked)
                    }
                    checked={!!selectedFilters?.[item.id]}
                  />
                }
                showIcon={showIcon}
                label={
                  showIcon ? (
                    <OptionContainer>
                      <PowerScore tokenConfig={item?.tokenConfig} />
                      <Typography>{item.label}</Typography>
                    </OptionContainer>
                  ) : (
                    item.label
                  )
                }
              />
            ))}
          </CheckboxContainer>
        )}
      </>
    )
  );
};
