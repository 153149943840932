import useFeatureToggles from '~/hooks/useFeatureToggles';
import { InsightsWarningCard } from '../entityInsights/insightsWarningCard';
import { CategoricalDataWrapper } from '../entityCategoricalData/categoricalDataWrapper';
import { InsightsPrecall } from '../entityInsights/insightsPrecall';
import { EntityCharts } from '../entityCharts';

export const NonDldSection = ({
  userGroup,
  entity,
  entityId,
  snowflakeMaptualListId,
  projectId,
  activeObjective,
  marketId,
}: {
  userGroup: string;
  entity: any;
  entityId: string;
  snowflakeMaptualListId: string;
  projectId: string;
  activeObjective: any;
  marketId: string;
}) => {
  const featureToggles = useFeatureToggles();

  const metadata = entity?.metadata || {};
  const isNoSee = entity?.metadata?.listMemberships?.noSee || false;
  const isLongTermLeave =
    entity?.metadata?.listMemberships?.longTermLeave || false;

  return (
    <>
      {isNoSee && (
        <InsightsWarningCard
          name={metadata.entityName}
          userCreatedListType="No-See"
        />
      )}
      {isLongTermLeave && (
        <InsightsWarningCard
          name={metadata.entityName}
          userCreatedListType="Long-term Leave"
        />
      )}

      {featureToggles(userGroup, 'showPrecallInsights') && (
        <InsightsPrecall
          entityId={entityId}
          objective={activeObjective}
          projectId={projectId}
          regionId={snowflakeMaptualListId}
        />
      )}

      <CategoricalDataWrapper
        entityId={entityId}
        projectId={projectId}
        objectiveId={activeObjective?.objectiveId}
        showLoading
      />

      <EntityCharts
        userGroup={userGroup}
        entityId={entityId}
        snowflakeMaptualListId={snowflakeMaptualListId}
        projectId={projectId}
        activeObjective={activeObjective}
        marketId={marketId}
      />
    </>
  );
};
