import { useState } from 'react';

import { Box, Fade, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { isEmpty } from 'lodash';
import useSphereContext from '../useSphereContext';
import { getHiddenRowTitles, getShowingRowTitles } from '../helpers';
import { trackSphereAttributionChannelSelection } from '../../../trackers/mixpanel';
import toKebabCase from '../../../utils/toKebabCase';
import DataMultilineSeries from './dataMultilineSeries';
import FullDataListTable from './fullDataListTable';

const Root = styled(Box)(({ theme: { insightContentCardStyle, palette } }) => ({
  ...insightContentCardStyle(`${palette.mode}Theme`),
}));

const DashBoardChart = styled(Box)({
  width: '100%',
  marginBottom: 16,
});

const TitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 24,
  fontWeight: 600,
  color: themeColors.primaryTextColor,
  marginTop: 20,
  marginLeft: 15,
  whiteSpace: 'normal',
}));

const SubTitleText = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 18,
  fontWeight: 500,
  color: themeColors.secondaryTextColor,
  marginTop: 5,
  marginLeft: 15,
}));

const componentTypeMap = {
  ATTRIBUTION_DATA: 'attributionData',
  attributionData: 'attributionData',
};

export function RawDataDisplay({ dataKey, data, style = {} }) {
  const sphereContext = useSphereContext();
  const { themeColors } = useTheme();
  const componentType = componentTypeMap[dataKey];
  const [rowSelected, setRowSelected] = useState(0);
  const [rowsHidden, setRowHidden] = useState(
    componentType === 'attributionData' ? {} : null
  );

  if (isEmpty(data)) return null;

  const handleRowSelect = (event, index) => {
    if (index !== rowSelected) {
      setRowSelected(index);
    }
  };

  const handleRowsHidden = (index) => {
    if (index) {
      const { listItems } = data.dataList;

      let trackRowsHidden = {};

      if (rowsHidden[index]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [index]: remove, ...newRowsHidden } = rowsHidden;
        setRowHidden(newRowsHidden);
        trackRowsHidden = { ...newRowsHidden };
      } else {
        const newRowsHidden = { ...rowsHidden, [index]: true };
        setRowHidden(newRowsHidden);
        trackRowsHidden = { ...newRowsHidden };
      }

      const disengagedChannels = getHiddenRowTitles(listItems, trackRowsHidden);
      const engagedChannels = getShowingRowTitles(listItems, trackRowsHidden);

      trackSphereAttributionChannelSelection({
        segmentName: sphereContext.segmentName,
        maptualListSelectionLabel: sphereContext.maptualListSelectionLabel,
        dropdownSelection: data?.title,
        engagedChannels,
        disengagedChannels,
      });
    }
  };

  function shouldRender(selectedData) {
    return !!selectedData;
  }

  return (
    <Fade in timeout={1200} key={dataKey}>
      <DashBoardChart style={style}>
        <Root
          className={`intercom-${toKebabCase(componentType)}-graph`}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          {data ? (
            <>
              <TitleText noWrap align="left">
                {data.title}
              </TitleText>
              {data.subTitle ? (
                <SubTitleText noWrap align="left">
                  {data.subTitle}
                </SubTitleText>
              ) : null}
            </>
          ) : null}

          {data?.dataList?.multiTimeSeries ? (
            <DataMultilineSeries
              data={data}
              color={themeColors.graphLegendColors[rowSelected]}
              rowSelected={rowSelected}
              rowsHidden={rowsHidden}
              customHeight={style?.customHeight}
            />
          ) : null}
          {shouldRender(data) ? (
            <FullDataListTable
              selectedData={data}
              rowSelected={rowSelected}
              onRowSelect={handleRowSelect}
              rowsHidden={rowsHidden}
              onRowsHidden={handleRowsHidden}
            />
          ) : null}
        </Root>
      </DashBoardChart>
    </Fade>
  );
}
