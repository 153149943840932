import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ReactNode, useContext } from 'react';
import { PowerScoreExplainability } from '../../../../components/fieldView/PowerScoreExplainability';
import { FieldContext } from '../../../application/appViews/field/fieldContext';
import { EntityOverviewContext } from '../../data/entityOverviewContext';
import { findRowDataInfo } from '../drawer/drawerHelpers';
import { addSpacesBetweenSeparators } from '../../../../utils/formatEntityName';

interface EntityTableScoreCellProps {
  row: any;
  maptualDisplayScore: number;
}
export const EntityTableScoreCell = ({
  row,
  maptualDisplayScore,
}: EntityTableScoreCellProps): ReactNode => {
  const theme = useTheme();

  const { maptualListMetadata } = useContext(FieldContext);
  const { objectiveId, entityType } = useContext(EntityOverviewContext);

  const sfMaptualListId = maptualListMetadata?.sfMaptualListId;
  const locality = maptualListMetadata?.locality || 'N/A';

  const entityName = findRowDataInfo(Object.entries(row), 'name');
  const formattedEntityName = addSpacesBetweenSeparators(
    entityName.toUpperCase()
  );
  const entityId = findRowDataInfo(Object.entries(row), 'id');

  return (
    <Box ml={theme.spacing(2)}>
      <PowerScoreExplainability
        powerscore={maptualDisplayScore}
        locality={locality}
        regionId={sfMaptualListId}
        objectiveId={objectiveId}
        entityType={entityType}
        entityId={entityId}
        entityName={formattedEntityName}
        shouldPreloadData={false}
      />
    </Box>
  );
};
