import { createContext } from "react";
import { EnqueueSnackbar } from "@odaia/ui/src/components/snackbar";
import {
  MaptualList,
  MaptualListObjective,
  EntityRuleInsight,
  EntityCoordinate,
  UserCreatedLists,
} from "@odaia/domain/src/types";
import { EntityLlmInsights } from "@odaia/clients/maptualAPI/api";
import { PowerScoreDisplay } from "../powerScoreDisplay";

export type MapViewCoreContextType = {
  projectId: string | undefined;
  productLineId: string | undefined;
  maptualListMetadata:
    | {
        listName: string;
        maptualListId: string;
        locality: string;
      }
    | undefined;
  maptualList: MaptualList;
  isMaptualListLoading: boolean;
  isMaptualListsLoading: boolean;
  maptualListObjectives: MaptualListObjective[];
  entityCoordinatesData: EntityCoordinate[] | undefined;
  isLoadingEntityCoords: boolean;
  isErrorEntityCoords: boolean;
  objective: MaptualListObjective | null;
  objectiveIndex: number | null;
  entityPrecallInsights: EntityLlmInsights[] | undefined;
  isEntityPrecallInsightsLoading: boolean;
  entityRuleInsights: Record<string, EntityRuleInsight> | undefined;
  isEntityRuleInsightsLoading: boolean;
  enqueueSnackbar: EnqueueSnackbar;
  canUserFilterMapView: boolean;
  relevantUserSpecialties: string[] | undefined;
  productLineUserCreatedLists: UserCreatedLists | undefined;
  entityCuration: { [key: string]: number } | undefined;
  isEntityCurationLoading: boolean;
  voiceEnabled?: boolean;
  anonymize?: boolean;
  powerScoreDisplay?: PowerScoreDisplay;
};

export const MapViewCoreContext = createContext<
  MapViewCoreContextType | undefined
>(undefined);
