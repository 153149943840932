export const TREND_TYPES = {
  VALUE: "VALUE",
  TREND: "TREND",
  TREND_ONLY: "TREND_ONLY",
  TREND_CHANGE: "TREND_CHANGE",
  DIRECTION_ONLY: "DIRECTION_ONLY",
} as const;

export type TREND_TYPES = typeof TREND_TYPES[keyof typeof TREND_TYPES];

export const COLOR_STATES = {
  NEGATIVE: "NEGATIVE",
  POSITIVE: "POSITIVE",
  NEUTRAL: "NEUTRAL",
  UNKNOWN: undefined,
};

export type COLOR_STATES = typeof COLOR_STATES[keyof typeof COLOR_STATES];

export const PREDICTION_PRECISION = {
  INCREASING_WITHIN_RANGE: 2,
  NO_CHANGE: 1,
  DECREASING_WITHIN_RANGE: 0,
  CANNOT_PREDICT: -1,
  INCREASING_OUTSIDE_RANGE: -2,
  DECREASING_OUTSIDE_RANGE: -3,
};

export type PREDICTION_PRECISION = typeof PREDICTION_PRECISION[keyof typeof PREDICTION_PRECISION];