import { GRAPH_VALUE_TYPE } from "./constants";

export const formatGraphValue = (
  value: number,
  type?: GRAPH_VALUE_TYPE | undefined,
  decimalPlaces: number = 0
) => {
  const dollarFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  const multiplier = decimalPlaces ? 10 ** decimalPlaces : 1;

  switch (type) {
    case GRAPH_VALUE_TYPE.PERCENT:
      return `${Math.round(value * 100 * multiplier) / multiplier}%`;
    case GRAPH_VALUE_TYPE.PERCENT_NOT_MULTIPLIED:
    case GRAPH_VALUE_TYPE.RATE:
      return `${Math.round(value * multiplier) / multiplier}%`;
    case GRAPH_VALUE_TYPE.PERCENT_NO_SYMBOL:
      return Math.round(value * 100 * multiplier) / multiplier;
    case GRAPH_VALUE_TYPE.DOLLAR:
    case GRAPH_VALUE_TYPE.DOLLARS:
      return dollarFormat.format(Math.round(value));
    case GRAPH_VALUE_TYPE.NUMBER:
    case GRAPH_VALUE_TYPE.UNIT:
      return Math.round(value * multiplier) / multiplier;
    default:
      return value;
  }
};

const MAX_Y_AXIS_LABEL_LENGTH = 18;

export const formatYAxisTitle = (title: string): string =>
  !!title && title.length > MAX_Y_AXIS_LABEL_LENGTH
    ? `${title.substring(0, MAX_Y_AXIS_LABEL_LENGTH)}…`
    : title;

    /* eslint-disable no-nested-ternary */