import { useQuery } from 'react-query';
import { maptualApiInstance } from '../../../../../../request/config';

export const useEntityCoordinates = ({ maptualListMetadata, project }) =>
  useQuery({
    queryKey: ['entity-coordinates', maptualListMetadata.maptualListId],
    queryFn: () =>
      getEntityCoordinates(
        project?.metadata?.productLineId,
        maptualListMetadata.sfMaptualListId
      ),
    enabled:
      !!maptualListMetadata.maptualListId &&
      !maptualListMetadata?.listName?.toLowerCase().includes('national'),
  });

export const getEntityCoordinates = async (
  productLineId: string,
  regionId: string
) => {
  const response = await maptualApiInstance.get(
    `core/product-lines/${productLineId}/regions/${regionId}/geocodes`,
    {
      timeout: 60000,
    }
  );
  return response.data;
};
