import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { useSnackbar } from '@odaia/ui/src/components/snackbar';
import { ActionableDataListItem } from '../actionableDataListItem';
import { ROUTES } from '../../../constants';
import { SchemaSummary } from '../../../request/admin/moduleConfigRequests';
import { useDeleteModuleConfigSchemaMutation } from '../../../request/admin/mutations';
import { useDialogContext } from '../../../contexts/dialogContext';

export const SchemaList = ({ data }: { data?: SchemaSummary[] }) => {
  const history = useHistory();
  const showDialog = useDialogContext();
  const deleteSchemaMutation = useDeleteModuleConfigSchemaMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteSchema = (schemaName: string) => {
    showDialog({
      title: 'Delete Config Schema',
      content: `Are you sure you want to delete ${schemaName} schema? All instances of this config will also be removed.`,
      confirmText: 'Confirm and Delete',
      loadingText: 'Deleting Schema',
      onConfirm: async () => {
        await deleteSchemaMutation.mutateAsync(schemaName);
        enqueueSnackbar(`Successfully deleted ${schemaName} schema`, {
          variant: 'info',
        });
      },
    });
  };
  if (!data) return null;
  return (
    <>
      {data.length === 0 && (
        <Typography
          color={(theme) => theme.themeColors.neutral60}
          variant="body2"
        >
          No schemas in inventory. Add new config to get started
        </Typography>
      )}
      <Box component="ul" width="100%" padding={0}>
        {data.sort(compare_schemas).map((d) => (
          <ActionableDataListItem
            key={d.name}
            name={d.name}
            description={d.description}
            actions={[
              {
                text: 'Edit Config',
                handler: () => {
                  history.push(
                    `${ROUTES.ADMIN}${ROUTES.APP_CONFIG}${ROUTES.SCHEMAS}/${d.name}`
                  );
                },
              },
              {
                text: 'Delete',
                handler: () => {
                  handleDeleteSchema(d.name);
                },
              },
            ]}
          />
        ))}
      </Box>
    </>
  );
};

const compare_schemas = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};
