import { useContext } from 'react';
import { useTheme } from '@mui/styles';
import { Box, styled } from '@mui/material';
import isValidProp from '@emotion/is-prop-valid';
import {
  TrendAreaCell,
  TrendBarCell,
} from '@odaia/shared-components/src/table/cells';
import { formatCellValue } from '../../../shared/graphs/utils';
import { CADENCES_ENUM, TABLE_HEADER_TYPES_ENUM } from '../../constants';
import { CHART_TYPES_ENUM } from '../../../shared/graphs/constants';
import { EntityOverviewContext } from '../../data/entityOverviewContext';

export const getEntityCellValue = (
  rowData: number[],
  type: TABLE_HEADER_TYPES_ENUM
) => {
  if (!rowData || rowData.length === 0) return '-';

  const value = Number(rowData[rowData.length - 1]);
  const valueType = type === TABLE_HEADER_TYPES_ENUM.RATE ? 'percent' : type;
  return formatCellValue(value, valueType);
};

const BarGraphBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  height: '100%',
}));

const LineGraphBox = styled(Box, {
  shouldForwardProp: isValidProp,
})(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  height: '100%',
}));

export const EntityTableTableDefaultCell = ({
  graph,
  data,
  chartLabels,
  trend,
  type,
  label,
  chartTitlePrefix,
}) => {
  const { themeColors } = useTheme();
  const { cadence } = useContext(EntityOverviewContext);

  const graphTitle = chartTitlePrefix
    ? `${chartTitlePrefix} ${label}, ${
        CADENCES_ENUM[cadence as keyof typeof CADENCES_ENUM]
      }`
    : `${label}, ${CADENCES_ENUM[cadence as keyof typeof CADENCES_ENUM]}`;

  return (
    <>
      {graph === CHART_TYPES_ENUM.BAR && (
        <BarGraphBox data-testid={`entityTableBarGraph-${data.join('-')}`}>
          <TrendBarCell
            data={data}
            color={themeColors.dataGeneralColor}
            xLabels={chartLabels}
            trend={trend}
            type={type}
            yTitle={label}
            graphTitle={graphTitle}
          />
        </BarGraphBox>
      )}

      {graph === CHART_TYPES_ENUM.LINE && (
        <LineGraphBox data-testid={`entityTableLineGraph-${data.join('-')}`}>
          <TrendAreaCell
            data={data}
            color={themeColors.dataGeneralColor}
            xLabels={chartLabels}
            trend={trend}
            type={type}
            yTitle={label}
            graphTitle={graphTitle}
          />
        </LineGraphBox>
      )}
    </>
  );
};
