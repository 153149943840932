import { Box, styled } from "@mui/material";
import { ModalDisplay, ModalHeader, ModalBody } from "../../../modal";
import { BarGraph, LineGraph } from "../../../graphs";
import { ChartType, GRAPH_VALUE_TYPE } from "../../utils/constants";

const GraphBox = styled(Box)(() => ({
  height: 200,
  display: "flex",
  flexWrap: "wrap",
  alignContent: "center",
  padding: "0px 16px 32px 16px",
}));

export interface GraphModalProps {
  isOpen: boolean;
  handleClose: () => void;
  graphType: ChartType;
  valueType: GRAPH_VALUE_TYPE;
  data: number[];
  chartLabels: string[];
  graphTitle: string;
  yTitle: string;
  tooltipDataPointTitle?: string;
  graphColor?: string;
}

export const GraphModal = ({
  isOpen,
  handleClose,
  graphType,
  valueType,
  data,
  chartLabels,
  graphTitle,
  yTitle,
  tooltipDataPointTitle,
  graphColor = undefined,
}: GraphModalProps) => {
  return (
    <ModalDisplay onClose={() => handleClose()} isOpen={isOpen}>
      {graphTitle && (
        <ModalHeader
          onClose={() => handleClose()}
          shouldShowBottomBorder={false}
        >
          {graphTitle}
        </ModalHeader>
      )}
      <ModalBody>
        <GraphBox>
          {graphType === ChartType.BAR && (
            <BarGraph
              datasets={[{ data, color: graphColor }]}
              labels={chartLabels}
              yTitle={yTitle}
              tooltipDataPointTitle={tooltipDataPointTitle}
              valueType={valueType}
              expanded
            />
          )}
          {graphType === ChartType.LINE && (
            <LineGraph
              datasets={[
                {
                  data,
                  color: graphColor,
                },
              ]}
              valueType={valueType}
              labels={chartLabels}
              yTitle={yTitle}
              tooltipDataPointTitle={tooltipDataPointTitle}
              expanded
            />
          )}
        </GraphBox>
      </ModalBody>
    </ModalDisplay>
  );
};
