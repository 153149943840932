import { styled, Box, Typography } from "@mui/material";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

export const CurationCell = ({ rank, showRank = false}: CurationCellProps) => {
  if (!rank) {
    return null;
  }

  return (
    <CellContainer>
      {showRank && <Rank variant="title3">{rank}</Rank>}
      <SparklesIcon />
    </CellContainer>
  );
};

interface CurationCellProps {
  rank: number | undefined;
  showRank?: boolean;
}

const CellContainer = styled(Box)({
  display: "flex",
  justifyContent: "end",
  gap: 8,
  width: "100%",
});

const Rank = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tableCellDefaultContent,
}));

const SparklesIcon = styled(AutoAwesomeOutlinedIcon)(
  ({ theme: { themeColors } }) => ({
    height: 19.2,
    width: 19.2,
    color: themeColors.generalSecondaryColor,
  })
);
