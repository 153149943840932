import { useQuery } from 'react-query';
import { fieldApi } from '../../request/maptualApiClient';

const getSegmentFiltersFromApi = ({
  objectiveId,
}: {
  objectiveId: string | undefined;
}) => {
  if (!objectiveId) {
    return Promise.reject();
  }

  return fieldApi.getSegmentFiltersFieldMaptualListSegmentFiltersGet({
    objectiveId,
  });
};

export const useSegmentFilters = ({
  objectiveId,
}: {
  objectiveId: string | undefined;
}) => {
  const {
    data: segmentFilters,
    isLoading: isSegmentFiltersBusy,
    isError: isSegmentFiltersError,
    refetch: refetchSegmentFilters,
  } = useQuery([objectiveId, 'segmentFilters'], async () => {
    try {
      const response = await getSegmentFiltersFromApi({
        objectiveId,
      });
      return response.data;
    } catch {
      return null;
    }
  });

  return {
    segmentFilters,
    isSegmentFiltersBusy,
    isSegmentFiltersError,
    refetchSegmentFilters,
  };
};
