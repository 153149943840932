import { Box, styled, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const InsightCell = ({ insight }: InsightCellProps) => (
  <Container>
    <IconWrapper>
      <StyledAddIcon />
    </IconWrapper>
    <Insight variant="body2">{insight}</Insight>
  </Container>
);

interface InsightCellProps {
  insight: string;
}

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

const IconWrapper = styled(Box)(({ theme: { themeColors } }) => ({
  minWidth: 26,
  maxWidth: 26,
  height: 26,
  borderRadius: "50%",
  border: `1px dashed ${themeColors.anchorIconInsightGeneralSecondary}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: themeColors.anchorIconInsightGeneralPrimary,
}));

const StyledAddIcon = styled(AddIcon)(({ theme: { themeColors } }) => ({
  color: themeColors.anchorIconInsightGeneralSecondary,
  width: 16,
  height: 16,
}));

const Insight = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.menuTextColor,
}));
