import { useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Plugin, ScriptableChartContext } from "chart.js";
import { Tooltip, TooltipData, createTooltip } from "../Tooltip";
import { formatGraphValue, formatYAxisTitle } from "../utils/formatters";
import { GRAPH_VALUE_TYPE } from "../utils/constants";
import { GraphDataset } from "../interfaces";
import { Box, styled, useTheme } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignContent: "center",
  height: "100%",
  marginRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
  maxWidth: 1000,
}));

const verticalCategoryBackgroundPlugin: Plugin = {
  id: "verticalCategoryBackground",
  afterTooltipDraw: (chart, args, options) => {
    const { ctx } = chart;
    const { left, top, height, width } = chart.chartArea;

    ctx.save();

    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = options.color;

    const numberOfCategoryColumns: number = chart.scales.x.ticks.length;
    const overlayWidth: number = width / numberOfCategoryColumns;
    const activeColumnIndex: number = args.tooltip.dataPoints[0].dataIndex;

    ctx.fillRect(
      left + overlayWidth * activeColumnIndex,
      top,
      overlayWidth,
      height
    );

    ctx.restore();
  },
};

const DECIMAL_PLACES = 1;

export interface BarGraphProps {
  datasets: GraphDataset[];
  labels: string[];
  expanded?: boolean;
  yTitle?: string;
  tooltipDataPointTitle?: string;
  valueType?: GRAPH_VALUE_TYPE;
  width?: number | string;
}

export const BarGraph = ({
  datasets,
  labels,
  expanded = false,
  yTitle = "",
  tooltipDataPointTitle = "",
  valueType,
  width = "100%",
}: BarGraphProps) => {
  const [tooltip, setTooltip] = useState<TooltipData>({
    trend: undefined,
    opacity: 0,
    top: 0,
    left: 0,
    value: 0,
    display: "none",
    label: "",
  });
  const { themeColors } = useTheme();
  const chartRef = useRef(null);

  const isPercentage =
    valueType === GRAPH_VALUE_TYPE.RATE ||
    (valueType ?? "").includes("percent");

  const chartData = {
    labels,
    datasets: datasets.map(({ data, color }: GraphDataset) => ({
      tick: false,
      scale: false,
      label: "",
      data: data,
      fill: false,
      backgroundColor: color,
      hoverBackgroundColor: color,
    })),
  };

  const graphMinValue = datasets.reduce((acc, curr) => {
    const min = Math.min(...curr.data);
    return min < acc ? min : acc;
  }, 0);

  const options = {
    maintainAspectRatio: false,
    animation: expanded,
    scales: {
      x: {
        display: expanded,
        ticks: {
          color: themeColors.chartDataLabel,
        },
        grid: {
          display: false,
        },
      },
      y: {
        min: isPercentage ? 0 : graphMinValue,
        max: isPercentage ? 1 : undefined,
        title: {
          display: !!yTitle,
          text: formatYAxisTitle(yTitle),
          font: 12,
          color: themeColors.chartAxisLabel,
        },
        ticks: {
          color: themeColors.chartDataLabel,
          padding: 8,
          callback: (value: number) =>
            formatGraphValue(value, valueType, DECIMAL_PLACES),
        },
        grid: {
          display: expanded,
          color: themeColors.chartLines,
        },
        display: expanded,
        border: { display: false },
      },
    },
    legend: {
      display: false,
    },
    interaction: {
      mode: "nearest",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context: ScriptableChartContext) => {
          if (!expanded) return;
          createTooltip({
            context,
            tooltip,
            updateTooltip: (newTooltipData: TooltipData) =>
              setTooltip(newTooltipData),
          });
        },
      },
      verticalCategoryBackground: {
        color: themeColors.popoverBorderColor,
      },
    },
  };

  const plugins = expanded ? [verticalCategoryBackgroundPlugin] : [];
  return (
    <Wrapper width={width}>
      <Bar
        options={options}
        data={chartData}
        ref={chartRef}
        plugins={plugins}
      />
      {expanded && (
        <Tooltip
          tooltip={tooltip}
          tooltipDataPointTitle={tooltipDataPointTitle}
          valueType={valueType}
        />
      )}
    </Wrapper>
  );
};
