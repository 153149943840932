import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

interface ClickableTooltipProps {
  children: React.ReactNode;
  title: string;
}

export const ClickableTooltip: React.FC<ClickableTooltipProps> = ({
  children,
  title,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Tooltip
      title={title}
      open={open}
      onClose={handleClose}
      disableFocusListener
      disableTouchListener
      {...props}
    >
      <span
        onClick={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        {children}
      </span>
    </Tooltip>
  );
};
