import { 
    Add,
    QueryStats,
    ThermostatRounded,
    Close,
    Remove
 } from '@mui/icons-material';
import { Box, SvgIconTypeMap, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { LlmInsightsTypeEnum } from '@odaia/clients/maptualAPI/api';

export type PrecallInsightType = LlmInsightsTypeEnum | "None"

type Props = {
    insightType: PrecallInsightType
}
 
export const PreCallInsightIcon = ({insightType }: Props) => {
const { themeColors : { anchorIconInsight }} = useTheme()

const iconPropsMap: Record<PrecallInsightType,{
    Icon: OverridableComponent<SvgIconTypeMap<object, "svg">>
    colors: { primary: string, secondary: string }, outline?: string}> = {
        PredictingNewMetricEvent: {Icon: Add, colors: anchorIconInsight.general, outline: "dotted"},
        ProductInterest: {Icon: ThermostatRounded, colors: anchorIconInsight.precursor},
        TargetVsCompetitor: {Icon: QueryStats, colors: anchorIconInsight.general},
        NewWriter: {Icon: Add, colors: anchorIconInsight.general},
        ChurnedWriter: {Icon: Close, colors: anchorIconInsight.targetCompetitorNegative},
        None: {Icon: Remove, colors: anchorIconInsight.generalNegative},
}

const { colors, Icon, outline } = iconPropsMap[insightType] ?? iconPropsMap["TargetVsCompetitor"]

return <Box sx={{
    color: colors.secondary,
    border: `1px ${outline ?? "solid"} ${colors.secondary}`,
    backgroundColor: colors.primary,
    borderRadius: "50%",
    display: "flex",
    width: "26px",
    height: "26px",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px"
}}><Icon fontSize='inherit' /></Box>}