import { useMemo } from "react";
import { styled } from "@mui/material";
import { CADENCE } from "@odaia/domain/src";
import { useCallPlanContext } from "./CallPlanContext";
import { PowerScoreDisplay } from "../powerScoreDisplay";

const TableWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
}));

const staticNonStickyColumns = [
  {
    id: "insight",
    subLabel: "Top HCP Insight",
    message: "The top hcp insight.",
    visible: true,
    width: 400,
  },
  {
    id: "totalCalls",
    subLabel: "Total Calls (QTD)",
    visible: true,
    width: 110,
  },
];

const powerScoreColumn = {
  id: "powerscore",
  label: "Power Score",
  visible: true,
  width: 40,
};

const targetBandColumn = {
  id: "target_band",
  label: "Target Band",
  visible: true,
  width: 40,
};

const customerSegmentColumn = {
  id: "segmentsCustomer",
  subLabel: "Customer Segments",
  visible: true,
  width: 150,
};

const segmentColumn = {
  id: "segments",
  subLabel: "Segments",
  visible: true,
  width: 150,
};

export type TableComponentProps = {
  regionId: string;
  hcpView: boolean;
  curatedType: string[];
  pageSize: number;
  showPowerscore: boolean;
  productLineId: string;
  projectId: string;
  objectiveId: string;
  cadence: string;
  timestamp: string;
  activitySubtitle: string;
  activityCadence: string;
  projectTargetProducts: unknown[];
  stickyColumns: unknown[];
  nonStickyColumns: unknown[];
  metricColumns: unknown[];
  activityColumns: unknown[];
  showProduct: boolean;
  fullHeight: boolean;
  onRowClick: (entityId: string) => void;
  RowContentComponent: (props: RowComponentProps) => JSX.Element;
};

export type RowComponentProps = {
  objectiveId: string;
  cadence: string;
  row: Record<string, unknown>;
  rank: number;
  onRowClick: (entityId: string) => void;
  isLoading: boolean;
};

type CallPlanTableProviderProps = {
  onRowClick: (entityId: string) => void;
  RowComponent: (props: RowComponentProps) => JSX.Element;
  TableComponent: (props: TableComponentProps) => JSX.Element;
};

export const CallPlanTableProvider = ({
  onRowClick,
  RowComponent,
  TableComponent,
}: CallPlanTableProviderProps) => {
  const {
    latestCurationTimestamp,
    isTimestampLoading,
    isProductsLoading,
    projectTargetProducts,
    productLineId,
    projectId,
    regionId,
    rows,
    showCurationData,
    isMultiObjective,
    powerScoreDisplay,
    behaviouralSegmentsEnabled,
  } = useCallPlanContext();

  const memoizedStickyColumns = useMemo(() => {
    let stickyColumns = [];
    if (showCurationData) {
      stickyColumns = [
        {
          id: "rank",
          label: "Rank",
          message: "HCPs are ranked by importance to call.",
          visible: true,
          width: 60,
        },
      ];
    }
    if (!isMultiObjective) {
      switch (powerScoreDisplay) {
        case PowerScoreDisplay.POWER_SCORE:
          stickyColumns.push(powerScoreColumn);
          break;
        case PowerScoreDisplay.CUSTOMER_TARGET:
          stickyColumns.push(targetBandColumn);
          break;
        case PowerScoreDisplay.HYBRID:
          stickyColumns.push(powerScoreColumn, targetBandColumn);
      }
    }
    stickyColumns.push({
      id: "name",
      label: "Name",
      visible: true,
      width: 150,
    });
    return stickyColumns;
  }, [showCurationData]);

  const memoizedNonStickyColumns = useMemo(() => {
    let nonStickyColumns = [];
    if (isMultiObjective) {
      nonStickyColumns.push({
        id: "productScore",
        subLabel: "Suggested Product",
        message: "The product we recommend focusing on with the HCP.",
        visible: true,
        width: 150,
      });
    }
    nonStickyColumns = [...nonStickyColumns, ...staticNonStickyColumns];
    if (behaviouralSegmentsEnabled) {
      nonStickyColumns.push(segmentColumn);
    }
    nonStickyColumns.push(customerSegmentColumn);
    return nonStickyColumns;
  }, [isMultiObjective, powerScoreDisplay]);

  return (
    <TableWrapper>
      <TableComponent
        isLoading={isTimestampLoading || isProductsLoading}
        regionId={regionId}
        objectiveId=""
        timestamp={latestCurationTimestamp}
        curatedType={["curated"]}
        cadence={CADENCE.WEEK}
        hcpView
        pageSize={10}
        RowContentComponent={RowComponent}
        stickyColumns={memoizedStickyColumns}
        nonStickyColumns={memoizedNonStickyColumns}
        metricColumns={[]}
        activityColumns={[]}
        activitySubtitle="Quarter to Date"
        activityCadence={CADENCE.DAY}
        showPowerscore
        productLineId={productLineId}
        projectTargetProducts={projectTargetProducts}
        projectId={projectId}
        showProduct
        fullHeight
        onRowClick={onRowClick}
        rows={rows}
      />
    </TableWrapper>
  );
};
