import { ComponentProps, useMemo } from "react";
import { Box, IconButton, Stack, Typography, styled } from "@mui/material";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import { formatLastContactedDateRelative } from "@odaia/domain/src";
import { PowerScore } from "../../powerScore";
import {
  EntityCoordinate,
  MaptualList,
  MaptualListObjective,
  PrecallInsightWithNone,
  UserCreatedLists,
} from "@odaia/domain/src/types";
import { SetState } from "@odaia/domain/src/utilityTypes";
import { EntityAttributes } from "./entityAttrbutes";
import { AutoAwesomeOutlined } from "@mui/icons-material";
import { PreCallInsight } from "../../preCallInsight";
import { PowerScoreDisplay } from "../../powerScoreDisplay";
import { useMapViewContext } from "../../contexts/mapViewContext";

const StyledMapListItem = styled("div")<{ active: boolean }>(
  ({ theme: { themeColors, spacing }, active }) => ({
    display: "flex",
    flexDirection: "column",
    padding: spacing(3),
    paddingBottom: spacing(2),
    gap: spacing(2),
    borderBottom: `1px solid ${themeColors.borderPrimaryColor}`,
    textAlign: "left",
    cursor: "pointer",
    width: 400,
    backgroundColor: active
      ? themeColors.HCPListItemBackgroundActive
      : themeColors.HCPListItemBackground,
    "&:hover": {
      backgroundColor: active
        ? themeColors.HCPListItemBackgroundActive
        : themeColors.HCPListItemBackgroundHover,
    },
    "&:first-of-type": {
      borderTop: `1px solid ${themeColors.borderPrimaryColor}`,
    },
  })
);

const StyledMapListItemActions = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row-reverse",
}));

const StyledAutoAwesomeOutlined = styled(AutoAwesomeOutlined)(
  ({ theme: { themeColors, spacing } }) => ({
    color: themeColors.anchorIconsGeneralSecondary,
    marginRight: spacing(0.5),
    alignSelf: "center",
  })
);

const StyledIconButton = styled(IconButton)<{ active: boolean }>(
  ({ active, theme: { themeColors } }) => ({
    border: active
      ? `1px solid ${themeColors.buttonActiveBorderColor}`
      : "1px solid transparent",
    borderRadius: "5px",
    color: themeColors.maptualListIconColor,
    buttonActiveBackgroundColor: "none",
  })
);

export type EntityListItem = Pick<
  MaptualList["list"]["items"][number],
  | "primaryText"
  | "secondaryText"
  | "maptualDisplayScore"
  | "maptualScoreChange"
  | "entityId"
  | "segments"
  | "emailOptOut"
> & {
  objectiveScores: number[];
  insight: PrecallInsightWithNone | null;
  customListMembership: Record<keyof UserCreatedLists, boolean>;
  address: string | undefined;
  curationRank?: number;
  coordinates: EntityCoordinate[];
};

export type MapListItemProps = {
  item: EntityListItem;
  isSelected?: boolean;
  onSelectMaptualListItem: (
    selectedEntity: unknown,
    zoomToEntity?: boolean
  ) => void;
  selectedObjectiveIndex: number | null;
  routeEntities: Record<string, unknown>[];
  setRoute: (entities: unknown) => void;
  setIsRouteLoading: SetState<boolean>;
  objective: MaptualListObjective | null;
  anonymize?: boolean;
  showRank?: boolean;
};

export const MapListItem = ({
  item,
  onSelectMaptualListItem,
  isSelected,
  selectedObjectiveIndex,
  routeEntities,
  setRoute,
  setIsRouteLoading,
  objective,
  anonymize,
  showRank,
  customerTarget,
}: MapListItemProps) => {
  const { powerScoreDisplay, behaviouralSegmentsEnabled } = useMapViewContext();
  const isInRoute =
    routeEntities.length > 0 &&
    routeEntities.some((e) => e.entityId === item.entityId);

  const displayPowerScore =
    powerScoreDisplay === PowerScoreDisplay.POWER_SCORE ||
    powerScoreDisplay === PowerScoreDisplay.HYBRID;

  const entitySegments = useMemo(
    () =>
      behaviouralSegmentsEnabled
        ? item.segments.map((val, idx) => {
            return {
              id: idx,
              title: val.segmentName,
              isHistoricalMember: val.isHistoricalMember,
              isPredictedMember: val.isPredictedMember,
            };
          })
        : [],
    [item]
  );

  const handleRouteClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setIsRouteLoading(true);
    if (isInRoute) {
      setRoute(routeEntities.filter((e) => e.entityId !== item.entityId));
    } else {
      setRoute([...routeEntities, item]);
    }
    e.stopPropagation();
  };

  const lastContactedDate = formatLastContactedDateRelative(
    item.secondaryText?.split("-")[0]?.trim()
  );
  const lastContactedMethod = item.secondaryText?.split("-")[1]?.trim();

  const isMultiObjective = item.objectiveScores.length > 1;

  const rank = item.curationRank;

  const powerScoreProps: ComponentProps<typeof PowerScore> = {
    score:
      selectedObjectiveIndex !== null && displayPowerScore
        ? item.objectiveScores[selectedObjectiveIndex]
        : undefined,
    tokenConfig: customerTarget?.tokenConfig,
    scoreChangeValue: item.maptualScoreChange,
    objectiveScoreList: item.objectiveScores,
    isSelected: false,
    style: { alignSelf: "start" },
    scoreFont: "small",
    powerScoreDisplay,
  };

  return (
    <StyledMapListItem
      id={`map-list-item-${item.entityId}`}
      onClick={() => {
        onSelectMaptualListItem(item);
      }}
      active={!!isSelected}
      key={item.entityId}
    >
      <Stack
        direction="row"
        gap={1}
        alignItems={isMultiObjective ? "start" : "center"}
      >
        {(rank || !isMultiObjective) && (
          <Stack
            direction={"row"}
            alignItems={isMultiObjective ? "start" : "center"}
            gap={(theme) => theme.spacing(0.25)}
          >
            {(showRank || showRank === undefined) && rank ? (
              <Typography variant="h5" width={21} textAlign="center">
                {rank}
              </Typography>
            ) : null}
            {!isMultiObjective && <PowerScore {...powerScoreProps} />}
          </Stack>
        )}
        <Stack sx={{ flexGrow: 1, gap: 0.75 }}>
          <Stack>
            <Typography variant="h5">
              {anonymize
                ? `Entity Name ${item.entityId.toString().slice(-5)}`
                : item.primaryText}
            </Typography>
            <Typography variant="label">
              {lastContactedMethod} {lastContactedDate}
            </Typography>
          </Stack>
          <EntityAttributes
            isEmailOptOut={item.emailOptOut === 1}
            customListMembership={item.customListMembership}
            entitySegments={entitySegments}
            isSelected={isSelected}
          />
        </Stack>
        {typeof rank === "number" && (
          <StyledAutoAwesomeOutlined fontSize="small" />
        )}
      </Stack>
      {isMultiObjective && (
        <Stack direction="row" alignItems="center" gap={0.5}>
          <PowerScore
            {...powerScoreProps}
            style={{ ...powerScoreProps.style, flexDirection: "row" }}
          />
          <Typography variant="title3">{objective?.name}</Typography>
        </Stack>
      )}
      <Box>
        {item.insight && (
          <PreCallInsight
            insightType={item.insight.type}
            title={item.insight.title}
          />
        )}
        <StyledMapListItemActions>
          <StyledIconButton active={isInRoute} onClick={handleRouteClick}>
            <RouteOutlinedIcon sx={{ fontSize: 16 }} />
          </StyledIconButton>
        </StyledMapListItemActions>
      </Box>
    </StyledMapListItem>
  );
};
