import { useContext } from 'react';
import { styled, Select, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { EntityOverviewContext } from '../../data/entityOverviewContext';
import { getComparisonTimeFrames } from '../../data/useEntityOverviewContext';

const TimeFrameSelect = styled(Select)(() => ({
  width: 304,
}));

export const TimeFrameSelector = () => {
  const { cadence, comparisonTimeFrameId, setComparisonTimeFrameId } =
    useContext(EntityOverviewContext);

  const handleTimeFrameChange = (comparisonTimeFrameId) => {
    setComparisonTimeFrameId(comparisonTimeFrameId);
  };

  const comparisonTimeFrames = getComparisonTimeFrames(cadence);
  return (
    <TimeFrameSelect
      data-testid="time-frame-selector"
      key={comparisonTimeFrameId}
      value={comparisonTimeFrameId}
      IconComponent={KeyboardArrowDownIcon}
      disabled={comparisonTimeFrames.length === 1}
      variant="outlined"
    >
      {comparisonTimeFrames.map((comparisonTimeFrame) => (
        <MenuItem
          key={comparisonTimeFrame.id}
          value={comparisonTimeFrame.id}
          onClick={() => handleTimeFrameChange(comparisonTimeFrame.id)}
          variant="outlined"
        >
          {comparisonTimeFrame.label}
        </MenuItem>
      ))}
    </TimeFrameSelect>
  );
};
