import { styled, Box, SxProps, Theme } from "@mui/material";
import ChangeChip from "./changeChip";
import { PowerScoreDisplay } from "./powerScoreDisplay";

const ScoreStyleToValueMapping = {
  RANK_1: 10,
  RANK_2: 9,
  RANK_3: 7,
  RANK_4: 0,
};

export const getScoreColours = (score, themeColors) => {
  if (score === null || score === undefined || score < 0) {
    return themeColors.powerscore["no-score"];
  }

  const roundedScore = Math.round(score);
  return themeColors.powerscore[roundedScore];
};

const PrimaryScore = styled(Box, {
  shouldForwardProp: (prop) => !["score", "scoreFont"].includes(prop),
})<{ score: number; scoreFont: Props["scoreFont"] }>(
  ({ theme: { themeColors }, score, scoreFont }) => ({
    borderRadius: "50%",
    borderWidth: 3,
    borderStyle: "solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    fontSize: scoreFont === "large" ? 22 : 18,
    paddingTop: 1,
    color: "black",
    gridColumnStart: 1,
    letterSpacing: -0.5,
    gridRowStart: 1,
    ...getScoreColours(score, themeColors),
  })
);

const ObjectiveScore = styled(Box, {
  shouldForwardProp: (prop) =>
    !["score", "zIndex", "selected", "scoreFont"].includes(prop),
})<{ score: number; scoreFont: Props["scoreFont"]; zIndex: number }>(
  ({ theme: { themeColors }, score, scoreFont, zIndex = 0 }) => ({
    width: scoreFont === "large" ? 12 : 10,
    height: scoreFont === "large" ? 12 : 10,
    borderRadius: "50%",
    borderWidth: 2,
    borderStyle: "solid",
    alignItems: "center",
    justifyContent: "center",
    borderColor: themeColors.mainBackground,
    background: getScoreColours(score, themeColors).borderColor,
    marginLeft: -6,
    zIndex,
  })
);

const PrimaryScoreWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "scoreSize",
})<{ scoreSize: number }>(({ scoreSize }) => ({
  display: "grid",
  gridTemplateRows: "100%",
  gridTemplateColumns: "100%",
  height: scoreSize,
  width: scoreSize,
}));

const ObjectiveScoresWrapper = styled(Box)(() => ({
  display: "flex",
  gridColumnStart: 1,
  gridRowStart: 1,
  alignItems: "end",
  justifyContent: "end",
  marginBottom: -2,
  marginRight: -2,
  gap: "3px",
}));

const ScoreWrapper = styled(Box)(({ theme: { spacing } }) => ({
  width: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing(0.5),
}));

type tokenConfig = {
  displayValue: string;
  styleValue: string;
};

export type PowerScoreProps = {
  score?: number;
  scoreChangeValue?: number;
  isSelected?: boolean;
  objectiveScoreList?: number[];
  style?: SxProps<Theme>;
  isVeeva?: boolean;
  scoreFont?: "large" | "small";
  tokenConfig?: tokenConfig | Record<string, never>;
  powerScoreDisplay?: PowerScoreDisplay;
};

const getScoreDisplayValue = (
  score: number,
  tokenConfig: tokenConfig | Record<string, never>,
  powerScoreDisplay: PowerScoreDisplay
) => {
  const value = tokenConfig?.displayValue ?? score;
  if (powerScoreDisplay === PowerScoreDisplay.CUSTOMER_TARGET && !value) {
    return "—";
  }
  return value;
};

export const PowerScore = ({
  score = 0,
  scoreChangeValue = 0,
  isSelected = false,
  objectiveScoreList = [],
  style = {},
  isVeeva = false,
  scoreFont = "large",
  tokenConfig = {},
  powerScoreDisplay = PowerScoreDisplay.POWER_SCORE,
}: Props) => {
  const showObjectiveScores = objectiveScoreList.length > 1 || isVeeva;

  const scoreStyle = ScoreStyleToValueMapping[tokenConfig.styleValue] ?? score;
  const displayValue = getScoreDisplayValue(
    score,
    tokenConfig,
    powerScoreDisplay
  );

  return (
    <ScoreWrapper sx={style}>
      <PrimaryScoreWrapper scoreSize={scoreFont === "large" ? 40 : 32}>
        <PrimaryScore score={scoreStyle} scoreFont={scoreFont}>
          <p data-testid="test-maptual-score">{displayValue}</p>
        </PrimaryScore>
        <ObjectiveScoresWrapper>
          {showObjectiveScores &&
            objectiveScoreList.map((objectiveScore, i) => (
              <ObjectiveScore
                score={objectiveScore}
                zIndex={objectiveScoreList.length - i}
                key={`objective-${objectiveScore}-${i}`}
                selected={isSelected}
                scoreFont={scoreFont}
              />
            ))}
        </ObjectiveScoresWrapper>
      </PrimaryScoreWrapper>
      {scoreChangeValue !== 0 ? (
        <ChangeChip change={scoreChangeValue} selected={isSelected} />
      ) : null}
    </ScoreWrapper>
  );
};
