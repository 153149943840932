import reduceReducers from 'reduce-reducers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { flatMaptualLists } from '../utils/maptualListParsingModule';
import { searchOptions } from '../components/fieldView/constants';
import headOfficeReducer, { headOfficeInitState } from './headOfficeReducer';
import downloadableUrlReducer, {
  downloadableUrlInitialState,
  name as downloadableUrlSliceName,
} from '../slices/downloadableUrl';
import { isCancelled } from '../modules/requestHelpers';
import { flatSegments } from '../components/headoffice/helpers';
import projectReducer, { projectInitialState } from '../slices/project';
import { putCurrentUserCreatedLists } from '../request/requests';
import {
  addUserCreatedListPropertiesToMaptualList,
  deserializeMaptualList,
} from '../utils/projectViewReducerUtils';

const initState = {
  analysisList: [],
  fullAnalysisItem: null,
  errorMessage: '',
  isBusy: false,
  isAnalysisBusy: false,
  isAnalysisExpanded: false,
  isMaptualListsBusy: false,
  isMaptualListBusy: false,
  isEntityBusy: false,
  isProjectSegmentsBusy: false,

  projects: [],
  projectItem: null,
  projectSegments: null,
  projectRegions: null,
  selectedRegion: null,

  maptualLists: null,
  maptualListsFlat: null,
  maptualList: null,
  maptualListMetadata: null,
  maptualListSegment: null,
  customList: null,
  sortingOption: searchOptions.byPowerScore,
  entity: null,
  entityMetadata: null,
  entityRingBuffer: [],

  featureIdMap: null,
  analysisIdMap: null,

  userCreatedLists: {},
  isListButtonBusy: false,
  isStandalonePage: false,
};

export const updateUserCreatedListPreferences = createAsyncThunk(
  'updateUserCreatedListPreferences',
  async ({ newUserCreatedLists, userId }) => {
    const response = await putCurrentUserCreatedLists(
      newUserCreatedLists,
      userId
    );
    const res = response.data;

    return res;
  }
);

function reducer(state = initState, action) {
  switch (action.type) {
    case 'updateUserCreatedListPreferences/fulfilled':
      return {
        ...state,
        isListButtonBusy: false,
        userCreatedLists: action.payload,
      };
    case 'updateUserCreatedListPreferences/rejected':
      return { ...state, isListButtonBusy: false };
    case 'updateUserCreatedListPreferences/pending':
      return { ...state, isListButtonBusy: true };
    case 'PROJECT_SET_MAPTUAL_LIST_FILTER_PREF':
      return {
        ...state,
        maptualListFilterPreferences: action.payload,
      };
    case 'PROJECT_SET_ANALYSIS_ID_MAPPING':
      return { ...state, analysisIdMap: action.payload };
    case 'PROJECT_SET_FEATURE_ID_MAPPING':
      return { ...state, featureIdMap: action.payload };
    case 'PROJECT_SET_FULL_ANALYSIS_ITEM':
      return {
        ...state,
        fullAnalysisItem: action.payload,
        isAnalysisBusy: false,
        errorMessage: '',
      };
    case 'PROJECT_SET_REGION_SELECTION':
      return {
        ...state,
        selectedRegion: action.payload,
      };
    case 'PROJECT_SET_PROJECT_ITEM':
      return {
        ...state,
        projectItem: action.payload,
        maptualList: null,
        entityMetadata: null,
        entity: null,
        maptualLists: null,
        maptualListsFlat: null,
        maptualListMetadata: null,
      };
    case 'PROJECT_SET_ANALYSIS_EXPANDED':
      return { ...state, isAnalysisExpanded: action.payload };
    case 'PROJECT_SELECT_MAPTUAL_LIST_METADATA':
      return {
        ...state,
        maptualListMetadata: action.payload,
        maptualList: null,
        entityMetadata: null,
        entity: null,
      };

    case 'PROJECT_SELECT_MAPTUAL_LIST_SEGMENT':
      return {
        ...state,
        maptualListSegment: action.payload,
      };
    case 'PROJECT_SELECT_CUSTOM_LIST':
      return {
        ...state,
        customList: action.payload,
      };
    case 'PROJECT_SELECT_SORTING_OPTION':
      return {
        ...state,
        sortingOption: action.payload,
      };
    case 'PROJECT_INITIALIZE_STANDALONE_ENTITY_PAGE':
      return {
        ...state,
        entityMetadata: {
          entityId: action.payload.entityId,
        },
        maptualListMetadata: {
          maptualListId: action.payload.maptualListId,
        },
        projectItem: { metadata: { projectId: action.payload.projectId } },
        isStandalonePage: action.payload.isStandalonePage,
      };
    case 'PROJECT_SELECT_ENTITY_METADATA':
      return {
        ...state,
        entityMetadata: {
          ...action.payload,
          entityId: action.payload?.entityId?.toString(),
        },
        entity: {},
      };
    case 'PROJECT_GET_ANALYSIS_LIST_PENDING':
      return { ...initState, errorMessage: '', isBusy: true };
    case 'PROJECT_GET_PROJECT_SEGMENTS_PENDING':
      return {
        ...state,
        isProjectSegmentsBusy: true,
        errorMessage: '',
      };
    case 'PROJECT_GET_MAPTUAL_LISTS_PENDING':
      return { ...state, isMaptualListsBusy: true, errorMessage: '' };
    case 'PROJECT_GET_MAPTUAL_LIST_PENDING':
      return { ...state, isMaptualListBusy: true, errorMessage: '' };
    case 'PROJECT_GET_ANALYSIS_ITEM_PENDING':
      return { ...state, isAnalysisBusy: true, errorMessage: '' };
    case 'PROJECT_GET_ENTITY_BY_MAPTUALLIST_ID_PENDING':
      return { ...state, isEntityBusy: true, errorMessage: '' };
    case 'PROJECT_GET_INSIGHTS_PENDING':
      return { ...state, isEntityInsightsBusy: true };

    case 'PROJECT_GET_ANALYSIS_LIST_FULFILLED':
      return {
        ...state,
        analysis: action.payload,
        errorMessage: '',
        isBusy: false,
      };
    case 'PROJECT_GET_ANALYSIS_ITEM_FULFILLED':
      return {
        ...state,
        fullAnalysisItem: action.payload,
        errorMessage: '',
        isAnalysisBusy: false,
      };
    case 'PROJECT_GET_PROJECT_SEGMENTS_FULFILLED': {
      const { regions: projectRegions, segments: projectSegments } =
        action.payload;
      return {
        ...state,
        projectSegments,
        flatSegments: flatSegments(projectSegments),
        projectRegions,
        isProjectSegmentsBusy: false,
        errorMessage: '',
      };
    }
    case 'PROJECT_GET_MAPTUAL_LISTS_FULFILLED': {
      const maptualLists = action.payload;
      const flatList = flatMaptualLists(maptualLists);

      return {
        ...state,
        maptualLists,
        maptualListsFlat: flatList,
        isMaptualListsBusy: false,
        errorMessage: '',
      };
    }

    case 'PROJECT_GET_MAPTUAL_LIST_FULFILLED': {
      const maptualList = deserializeMaptualList(action.payload);

      const productLineUserCreatedLists = state.userCreatedLists
        ? state.userCreatedLists[state.projectItem?.metadata?.productLineId]
        : undefined;

      const maptualListWithUserCreatedListProperties =
        productLineUserCreatedLists
          ? addUserCreatedListPropertiesToMaptualList(
              maptualList,
              productLineUserCreatedLists
            )
          : undefined;

      return {
        ...state,
        maptualList: maptualListWithUserCreatedListProperties ?? maptualList,
        isMaptualListBusy: false,
        errorMessage: '',
      };
    }

    case 'PROJECT_GET_ENTITY_BY_MAPTUALLIST_ID_FULFILLED': {
      if (isCancelled(action)) return state;
      const itemToLoad = state.entityMetadata;
      const newItem = action.payload;

      if (itemToLoad?.entityId && newItem?.metadata) {
        if (itemToLoad.entityId === newItem.metadata.entityId) {
          return {
            ...state,
            isEntityBusy: false,
            entity: {
              ...state.entity,
              ...action.payload,
            },
            errorMessage: '',
          };
        }
        return { ...state, isEntityBusy: false, errorMessage: '' };
      }
      return {
        ...state,
        isEntityBusy: false,
        entity: {
          ...state.entity,
          ...action.payload,
        },
        errorMessage: '',
      };
    }
    case 'PROJECT_GET_INSIGHTS_FULFILLED': {
      if (isCancelled(action)) return state;
      return {
        ...state,
        insights: {
          insightsId: action.payload.insightsId,
          ...action.payload.insights,
          objectives: action.payload.insights?.objectives
            ? action.payload.insights.objectives.filter(
                (obj) => obj.objectiveScore > -1
              )
            : [],
        },
        isEntityInsightsBusy: false,
      };
    }

    case 'PROJECT_GET_PROJECT_SEGMENTS_REJECTED':
      return {
        ...state,
        projectSegments: null,
        projectRegions: null,
        errorMessage: action.payload.Error,
        isProjectSegmentsBusy: false,
      };
    case 'PROJECT_GET_ANALYSIS_LIST_REJECTED':
      return { ...state, isBusy: false, errorMessage: action.payload.Error };
    case 'PROJECT_GET_ANALYSIS_ITEM_REJECTED':
      return {
        ...state,
        isAnalysisBusy: false,
        errorMessage: action.payload.Error,
      };
    case 'PROJECT_GET_MAPTUAL_LISTS_REJECTED':
      return { ...state, isMaptualListsBusy: false, errorMessage: '' };
    case 'PROJECT_GET_MAPTUAL_LIST_REJECTED':
      return { ...state, isMaptualListBusy: false, errorMessage: '' };
    case 'PROJECT_GET_ENTITY_BY_MAPTUALLIST_ID_REJECTED': {
      if (isCancelled(action)) return state;
      return { ...state, isEntityBusy: false, entity: null, errorMessage: '' };
    }

    case 'PROJECT_GET_INSIGHTS_REJECTED': {
      if (isCancelled(action)) return state;
      return { ...state, insights: {}, isEntityInsightsBusy: false };
    }
    case 'PROJECT_DELETE_BY_ID': {
      const projectsWithoutDeletedOne = state.projects.filter(
        (project) => project.projectId !== action.payload.projectId
      );
      return { ...state, projects: projectsWithoutDeletedOne };
    }
    case 'PROJECT_DELETE_PROJECT_ITEM_PENDING': {
      const modifiedProjectList = state.projects.map((project) =>
        project.projectId === action.payload.projectId
          ? { ...project, isBusy: true }
          : { ...project }
      );
      return { ...state, errorMessage: '', projects: modifiedProjectList };
    }
    case 'PROJECT_DELETE_PROJECT_ITEM_FULFILLED':
    case 'PROJECT_DELETE_PROJECT_ITEM_REJECTED': {
      const newProjectList = state.projects.map((project) =>
        project.projectId === action.payload.projectId
          ? { ...project, isBusy: false }
          : { ...project }
      );
      return { ...state, errorMessage: '', projects: newProjectList };
    }
    case 'PROJECT_UPDATE_METADATA_FULFILLED': {
      const newProjectList = state.projects.map((project) =>
        project.projectId === action.payload.projectId
          ? { ...action.payload }
          : { ...project }
      );

      return { ...state, projects: newProjectList };
    }

    default:
      return state;
  }
}

export default reduceReducers(
  {
    ...initState,
    ...headOfficeInitState,
    ...projectInitialState,
    [downloadableUrlSliceName]: downloadableUrlInitialState,
  },
  reducer,
  headOfficeReducer,
  projectReducer,
  downloadableUrlReducer
);
