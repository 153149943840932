export const TABLE_HEADER_TYPE = {
  LABEL: "label",
  SCORE: "score",
  RATE: "rate",
  CUSTOMER_SEGMENT: "customer_segment",
  BEHAVIOURAL_SEGMENT: "behavioural_segment",
  NUMERICAL_BADGE: "numerical_badge",
  DRAWER: "drawer",
} as const;

export type TABLE_HEADER_TYPE = typeof TABLE_HEADER_TYPE[keyof typeof TABLE_HEADER_TYPE];

export const GRAPH_VALUE_TYPE = {
  PERCENT: "percent",
  PERCENT_NOT_MULTIPLIED: "percentNotMultiplied",
  PERCENT_NO_SYMBOL: "percentNoSymbol",
  RATE: "rate",
  DOLLAR: "dollar",
  DOLLARS: "dollars",
  NUMBER: "number",
  UNIT: "unit",
} as const;

export type GRAPH_VALUE_TYPE = typeof GRAPH_VALUE_TYPE[keyof typeof GRAPH_VALUE_TYPE];

export enum ChartType {
  BAR,
  LINE
}