import { useContext } from 'react';
import { Box, styled } from '@mui/material';
import { BadgeCell } from '@odaia/shared-components/src/table/cells';
import { EM_DASH, SEGMENT_COLUMN_WIDTH } from '../../constants';
import { EntityOverviewContext } from '../../data/entityOverviewContext';

const Wrapper = styled(Box)({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  width: SEGMENT_COLUMN_WIDTH,
  maxHeight: 40,
  overflow: 'hidden',
});

export const EntityTableBehaviouralSegmentCell = ({ data }) => {
  const { filters } = useContext(EntityOverviewContext);

  const showIcons = Boolean(
    Object.keys(filters?.behaviouralSegments ?? {}).length ||
      Object.keys(filters?.behaviouralSegmentTypes ?? {}).length
  );

  const badgeData = (data ?? []).map(
    ({ displayName, isPredicted, isHistorical }, index: number) => ({
      title: displayName,
      id: index,
      isPredictedMember: isPredicted,
      isHistoricalMember: isHistorical,
    })
  );

  return (
    <Wrapper data-testid="behavioural-segment-wrapper">
      {data && data.length > 0 ? (
        <BadgeCell
          content={badgeData}
          showIcons={showIcons}
          showTimeframeTooltips
        />
      ) : (
        EM_DASH
      )}
    </Wrapper>
  );
};

export const EntityTableCustomerSegmentCell = ({ data }) => {
  const badgeData = (data ?? []).map((name, index: number) => ({
    title: name,
    id: index,
  }));
  return (
    <Wrapper>
      {badgeData && badgeData.length > 0 ? (
        <BadgeCell content={badgeData} />
      ) : (
        EM_DASH
      )}
    </Wrapper>
  );
};
