import { useContext } from 'react';
import {
  styled,
  Box,
  Tabs,
  Tab,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';

import { getTimeframeName, TIMEFRAME_TABS } from '../utils';
import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { trackTerritoryOverviewOptionSet } from '../../../trackers/mixpanel';

export const TimeframeTabs = ({ changeDescription }) => {
  const {
    data,
    selectedTimeframeID,
    setSelectedTimeframeID,
    objectiveOptions,
    selectedObjective,
    metricOptions,
    selectedMetric,
    cadenceOptions,
    handleComparisonTimeFrameChange,
  } = useContext(TerritoryOverviewContext);

  const onTabClick = (tabId) => {
    changeDescription(data?.description);

    const newTimeframe = getTimeframeName(tabId);

    const oldTimeframe = getTimeframeName(selectedTimeframeID);

    trackTerritoryOverviewOptionSet(
      'Time Range',
      newTimeframe,
      oldTimeframe,
      [
        newTimeframe,
        objectiveOptions[selectedObjective]?.label,
        metricOptions[selectedMetric]?.label,
      ],
      false
    );

    setSelectedTimeframeID(tabId);
    handleComparisonTimeFrameChange(tabId);
  };

  return (
    <Box>
      <Tabs
        value={selectedTimeframeID || 0}
        variant="filled"
        data-testid="timeframe-tabs"
      >
        {Object.values(TIMEFRAME_TABS).map((tab) => {
          const { id, name } = tab;

          const optionAvailable =
            cadenceOptions?.length > 0 && cadenceOptions?.includes(id);

          return optionAvailable ? (
            <Tab
              key={id}
              label={name}
              value={id}
              iconPosition="start"
              onClick={() => {
                onTabClick(id);
              }}
            />
          ) : (
            <CustomTooltip
              title="We do not have the data to generate this report."
              arrow
            >
              <span>
                <Tab
                  key={`${id}-key`}
                  label={name}
                  value={id}
                  iconPosition="start"
                  disabled
                />
              </span>
            </CustomTooltip>
          );
        })}
      </Tabs>
    </Box>
  );
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...(props as TooltipProps)} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.themeColors.tooltipContentColor,
    backgroundColor: theme.themeColors.tooltipSurfaceColor,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.themeColors.tooltipSurfaceColor,
  },
}));
