import { useContext } from 'react';
import { Tab, Tabs } from '@mui/material';
import { trackEntityOverviewDrilldownGraphSelector } from '../../../../trackers/mixpanel';
import { EntityDrawerContext } from './data/entityDrawerContext';

export const TabGroup = ({
  availableTabs,
  tab,
  setTab,
  sectionTitle,
  graphType,
}) => {
  const { entityType } = useContext(EntityDrawerContext);
  const onTabClick = (tabId, tabName) => {
    trackEntityOverviewDrilldownGraphSelector(
      tabName,
      entityType,
      sectionTitle,
      graphType
    );
    setTab(tabId);
  };

  return (
    availableTabs.length > 1 && (
      <Tabs value={tab || 0} variant="filled">
        {availableTabs.map((availableTab) => {
          const { id, name } = availableTab;

          return (
            <Tab
              key={`${id}-key`}
              label={name}
              value={id}
              onClick={() => {
                onTabClick(id, name);
              }}
            />
          );
        })}
      </Tabs>
    )
  );
};
