import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EntityType } from '@odaia/clients/maptualAPI';
import { PowerScorePopover } from '@odaia/shared-components/src/powerscore/PowerScorePopover';
import { trackPowerscoreFeedback } from '../../trackers/mixpanel';
import { useEntityPowerscoreContribution } from './entityPowerscore/hooks/useEntityPowerscoreContribution';
import { AppContext } from '../../containers/application/appContext';
import { useEntityPowerscoreInsights } from './entityPowerscore/hooks/useEntityPowerscoreInsights';

interface PowerScorePopoverProps {
  powerscore: number;
  objectiveScores?: number[];
  locality: string;
  regionId: string;
  objectiveId?: string;
  entityType: EntityType;
  entityId: string;
  entityName: string;
  shouldPreloadData: boolean;
  isAmaOptOut: boolean;
  isNonDld: boolean;
}
const POWERSCORE_ARTICLE_ID = 6576953;

export const PowerScoreExplainability = ({
  powerscore,
  objectiveScores = [],
  locality,
  regionId,
  objectiveId,
  entityType,
  entityId,
  entityName,
  isNonDld = false,
  isAmaOptOut,
  shouldPreloadData = true,
}: PowerScorePopoverProps) => {
  const { projectList } = useContext(AppContext);
  const { projectId } = useParams();
  const [shouldFetch, setShouldFetch] = useState(shouldPreloadData);
  const project = projectList.find((proj) => proj.projectId === projectId);
  const projectName = project?.projectName;

  const isOverallPowerscore = objectiveScores.length > 1;

  const handleFeedbackButtonClick = (selection: string) => {
    trackPowerscoreFeedback({
      userInput: selection,
      numberObjectives: isOverallPowerscore ? 'Multiple' : 'Single',
      scoreType: isOverallPowerscore ? 'Overall' : 'Objective',
      score: powerscore,
      projectName,
      entityName,
      entityType,
      region: locality,
    });
  };

  const hidePowerScoreExplainability = () =>
    isOverallPowerscore || isAmaOptOut || isNonDld;

  const {
    data: contributionData,
    isLoading: isContributionDataLoading,
    isError: isContributionDataError,
  } = useEntityPowerscoreContribution({
    entityId,
    entityType: entityType?.toLowerCase(),
    projectId,
    regionId,
    objectiveId,
    shouldFetch,
  });

  const { data: insightsData, isLoading: isPowerscoreInsightsLoading } =
    useEntityPowerscoreInsights({
      entityId,
      projectId,
      regionId,
      objectiveId,
      shouldFetch,
    });

  return (
    <PowerScorePopover
      powerScore={powerscore}
      objectiveScores={objectiveScores}
      entityType={entityType}
      entityId={entityId}
      isBreakdownLoading={isContributionDataLoading}
      isInsightsLoading={
        isPowerscoreInsightsLoading || isContributionDataLoading
      }
      insights={insightsData}
      breakdown={contributionData}
      entityName={entityName}
      onIntercomClick={() => handleIntercomClick(POWERSCORE_ARTICLE_ID)}
      hideExplainability={hidePowerScoreExplainability()}
      isError={isContributionDataError}
      onFeedback={(selection) => handleFeedbackButtonClick(selection)}
      onPowerScoreClick={() => !shouldPreloadData && setShouldFetch(true)}
    />
  );
};

const handleIntercomClick = (articleId) => {
  window.Intercom('showArticle', articleId);
};
