export const GRAPH_VALUE_TYPE = {
  PERCENT: "percent",
  PERCENT_NOT_MULTIPLIED: "percentNotMultiplied",
  PERCENT_NO_SYMBOL: "percentNoSymbol",
  RATE: "rate",
  DOLLAR: "dollar",
  DOLLARS: "dollars",
  NUMBER: "number",
  UNIT: "unit",
} as const;

export type GRAPH_VALUE_TYPE = typeof GRAPH_VALUE_TYPE[keyof typeof GRAPH_VALUE_TYPE];