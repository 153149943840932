/* eslint-disable no-restricted-syntax */
import { lastContactedIds } from "./notContactedFilter";
import { PowerScoreDisplay } from "../../powerScoreDisplay";
import moment from "moment";

export interface userCreatedListFilters {
  noSee: boolean;
  longTermLeave: boolean;
  starred: boolean;
}

export interface Filters {
  specialtyFilter: string[];
  userCreatedListFilter: userCreatedListFilters;
  powerscoreFilter?: {
    min: number;
    max: number;
  };
  notContactedFilter: number | null;
  neverSeenFilter: boolean;
  segmentFilter: string[];
  customerTargetFilter?: string[];
}

export const searchItems = (options, input) =>
  options.filter(
    (option) =>
      option.primaryText.toLowerCase().includes(input.toLowerCase()) ||
      (option.address &&
        option.address.toLowerCase().includes(input.toLowerCase()))
  );

const convertSecondaryTextToDate = (text: string) => {
  try {
    const splitText = text.split(",").map((item) => item.trim());
    const date = splitText[1];
    const year = splitText[2].split(" ")[0];

    return moment(date.concat(", ", year)).format("YYYY-MM-DD");
  } catch (error) {
    return moment().format("YYYY-MM-DD");
  }
};

export const defaultFiltersOff = {
  userCreatedListFilter: {
    noSee: true,
    longTermLeave: true,
    starred: false,
  },
  specialtyFilter: [],
  notContactedFilter: lastContactedIds.OFF,
  neverSeenFilter: false,
  segmentFilter: [],
};

export const defaultFiltersOn = {
  userCreatedListFilter: {
    noSee: false,
    longTermLeave: false,
    starred: false,
  },
  specialtyFilter: [],
  notContactedFilter: lastContactedIds.OFF,
  neverSeenFilter: true,
  segmentFilter: [],
};

export const saveFilters = (filters: Filters, projectId: string) => {
  sessionStorage.setItem(
    `map-view-filters-${projectId}`,
    JSON.stringify(filters)
  );
};

export const getFilters = (projectId: string) => {
  const sessionFilters = sessionStorage.getItem(
    `map-view-filters-${projectId}`
  );
  if (sessionFilters) {
    const filtersFromSession = JSON.parse(sessionFilters);
    if (
      filtersFromSession.userCreatedListFilter &&
      filtersFromSession.specialtyFilter &&
      filtersFromSession.segmentFilter
    ) {
      return filtersFromSession;
    }
  }
  return null;
};

export const getActiveFilterCount = (
  activeFilters: Filters,
  defaultFiltersArg: Filters
): number => {
  let count = 0;
  for (const [key, value] of Object.entries(activeFilters)) {
    if (typeof value === "object" && value !== null) {
      count += getActiveFilterCount(value, defaultFiltersArg[key]);
    } else if (value !== defaultFiltersArg[key]) {
      count += 1;
    }
  }
  return count;
};

export const applyListFilters = (
  list,
  objectiveIndex,
  filters: Filters,
  isSuggestedEntities: boolean
) => {
  let newList = list;
  if (filters?.powerscoreFilter) {
    newList = newList?.filter((i) => {
      const score = i.objectiveScores
        ? i.objectiveScores[objectiveIndex]
        : i.maptualFractionalScore;

      return (
        score >= filters.powerscoreFilter.min &&
        score <= filters.powerscoreFilter.max
      );
    });
  }
  if (filters?.customerTargetFilter?.length) {
    newList = newList?.filter((i) =>
      filters.customerTargetFilter.includes(i.customerTarget.value)
    );
  }
  if (filters.specialtyFilter.length !== 0) {
    newList = newList?.filter((i) =>
      filters.specialtyFilter.includes(i.specialty.toLowerCase())
    );
  }
  if (filters.userCreatedListFilter.starred) {
    newList = newList?.filter((i) => i.listMemberships.starred);
  }
  if (!filters.userCreatedListFilter.noSee) {
    newList = newList?.filter((i) => !i.listMemberships?.noSee);
  }
  if (!filters.userCreatedListFilter.longTermLeave) {
    newList = newList?.filter((i) => !i.listMemberships?.longTermLeave);
  }
  if (
    filters.notContactedFilter !== lastContactedIds.OFF ||
    filters.neverSeenFilter
  ) {
    newList = newList?.filter((i) => {
      let contactedDaysAgo = 999;
      if (i.secondaryText) {
        contactedDaysAgo = moment().diff(
          convertSecondaryTextToDate(i.secondaryText),
          "days"
        );
      }
      if (
        filters.neverSeenFilter &&
        filters.notContactedFilter !== lastContactedIds.OFF
      ) {
        return (
          contactedDaysAgo > filters.notContactedFilter &&
          contactedDaysAgo < 365
        );
      }
      if (filters.neverSeenFilter) {
        return contactedDaysAgo < 365;
      }
      if (filters.notContactedFilter !== lastContactedIds.OFF) {
        return contactedDaysAgo > filters.notContactedFilter;
      }
      return false;
    });
  }
  if (filters.segmentFilter.length !== 0) {
    const historical = filters.segmentFilter.includes("Recent");
    const predicted = filters.segmentFilter.includes("Predicted");
    const all = (historical && predicted) || (!historical && !predicted);
    newList = newList?.filter((i) =>
      filters.segmentFilter.find((segment) => {
        const matchingSegment = i.segments.find(
          (s) => s.segmentName === segment
        );
        if (matchingSegment) {
          if (all) {
            return true;
          }
          if (historical && matchingSegment.isHistoricalMember) {
            return true;
          }
          if (predicted && matchingSegment.isPredictedMember) {
            return true;
          }
        }
        return false;
      })
    );
  }
  if (isSuggestedEntities) {
    newList = newList?.filter((i) => typeof i.curationRank === "number");
  }

  return newList;
};

export const getConfigurableFilters = (
  appConfig,
  moduleConfig,
  filters,
  defaultFilters
) => {
  let configuredFilters = { ...filters };
  let configuredDefaultFilters = { ...defaultFilters };
  if (
    appConfig?.powerScoreDisplay === PowerScoreDisplay.HYBRID &&
    moduleConfig?.customerTargets
  ) {
    if (!configuredFilters.powerscoreFilter) {
      configuredFilters = {
        ...configuredFilters,
        powerscoreFilter: {
          min: 3,
          max: 10,
        },
      };
    }
    configuredDefaultFilters = {
      ...configuredDefaultFilters,
      powerscoreFilter: {
        min: 0,
        max: 10,
      },
    };
    if (!configuredFilters.customerTargetFilter) {
      configuredFilters = {
        ...configuredFilters,
        ...{ customerTargetFilter: [] },
      };
    }
    configuredDefaultFilters = {
      ...configuredDefaultFilters,
      ...{ customerTargetFilter: [] },
    };
  }
  if (appConfig?.powerScoreDisplay === PowerScoreDisplay.POWER_SCORE) {
    if (!configuredFilters.powerscoreFilter) {
      configuredFilters = {
        ...configuredFilters,
        powerscoreFilter: {
          min: 3,
          max: 10,
        },
      };
    }
    configuredDefaultFilters = {
      ...configuredDefaultFilters,
      powerscoreFilter: {
        min: 0,
        max: 10,
      },
    };
    delete configuredFilters.customerTargetFilter;
    delete configuredDefaultFilters.customerTargetFilter;
  }

  if (
    appConfig?.powerScoreDisplay === PowerScoreDisplay.CUSTOMER_TARGET &&
    moduleConfig?.customerTargets
  ) {
    if (!configuredFilters.customerTargetFilter) {
      configuredFilters = {
        ...configuredFilters,
        ...{ customerTargetFilter: [] },
      };
    }
    configuredDefaultFilters = {
      ...configuredDefaultFilters,
      ...{ customerTargetFilter: [] },
    };
    delete configuredFilters.powerscoreFilter;
    delete configuredDefaultFilters.powerscoreFilter;
  }
  return [configuredFilters, configuredDefaultFilters];
};
