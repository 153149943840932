import React, { useState } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import isValidProp from '@emotion/is-prop-valid';

import { SparklineBarGraph } from '../../shared/graphs/barGraph/barGraph';
import { SparklineLineGraph } from '../../shared/graphs/lineGraph/lineGraph';
import { TrendValue } from '../../shared/trend/TrendValue';
import { GraphModal } from '../../shared/graphs/graphModal';
import {
  GRAPH_VALUE_TYPE,
  formatCellValue,
  getCellValue,
} from '../../shared/graphs/utils';

const BarGraphBox = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  width: 50,
  marginRight: 15,
}));

const LineGraphBox = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  width: 50,
  marginRight: 15,
}));

const ValueTrendBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1,
}));

const Container = styled(Box, {
  shouldForwardProp: isValidProp,
})(({ graphType }) => ({
  height: '35px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: graphType === 'line' ? '140px' : '120px',
  cursor: 'pointer',
}));

export const TableDefaultCell = ({
  row,
  type,
  graph,
  chartLabels,
  metricType,
  modalGraphTitle,
}) => {
  const { themeColors } = useTheme();
  const { data, trend } = row;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const yTitle =
    metricType && metricType.toLowerCase().includes('share')
      ? `${metricType} %`
      : metricType;

  const modalType =
    type && type === 'percent' ? GRAPH_VALUE_TYPE.PERCENT_NO_SYMBOL : type;
  const modalData = {
    data: data?.map((value) => formatCellValue(value, modalType)),
    trend,
  };

  const valueType =
    type && type === 'percent' ? GRAPH_VALUE_TYPE.PERCENT_NOT_MULTIPLIED : type;
  return (
    graph && (
      <>
        <Container
          graphType={graph}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsModalOpen(true);
          }}
        >
          {graph === 'bar' && (
            <BarGraphBox data-testid={`barGraph-${data.join('-')}`}>
              <SparklineBarGraph data={data} labels={chartLabels} />
            </BarGraphBox>
          )}

          {graph === 'line' && (
            <LineGraphBox data-testid={`lineGraph-${data.join('-')}`}>
              <SparklineLineGraph
                data={data}
                labels={chartLabels}
                color={themeColors.dataGeneralColor}
              />
            </LineGraphBox>
          )}
          <ValueTrendBox data-testid={`value-trend-box-${data.join('-')}`}>
            {getCellValue(data, type)}
            <TrendValue
              type={trend.type}
              status={trend.status}
              value={trend?.value}
            />
          </ValueTrendBox>
        </Container>
        <GraphModal
          isOpen={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          graphType={graph}
          valueType={valueType}
          data={modalData}
          chartLabels={chartLabels}
          graphTitle={modalGraphTitle}
          yTitle={yTitle}
          graphColor={themeColors.dataGeneralColor}
        />
      </>
    )
  );
};
