import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
  );

export * from "./barGraph/BarGraph"
export * from "./lineGraph/LineGraph"
