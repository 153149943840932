import { useContext, useEffect, useState } from 'react';
import { styled, Box, Typography, Skeleton } from '@mui/material';

import {
  ObjectiveSelector,
  MetricSelector,
  ComparisonTimeFrameSelector,
} from './SelectorOptions';
import { TerritoryOverviewContext } from '../territoryOverviewContext';
import { TimeframeTabs } from './TimeframeTabs';

const SettingsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
}));

const SettingsSelector = styled(Box)(() => ({
  display: 'flex',
  marginTop: 24,
  gridGap: 8,
  flexWrap: 'wrap',
}));

const DateTextDescription = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'left',
    color: themeColors.neutral60,
    marginTop: 12,
  })
);

const DescriptionSkeleton = styled(Skeleton)(({ theme: { themeColors } }) => ({
  transform: 'scale(1)',
  backgroundColor: themeColors.surfaceEmpty,
  marginTop: 12,
  width: 350,
}));

export const Settings = () => {
  const { data, metadata, isDataLoading } = useContext(
    TerritoryOverviewContext
  );

  const [timeframeDescription, setTimeframeDescription] = useState('');

  useEffect(() => {
    const newDescription = data?.description;

    if (typeof newDescription === 'string' && newDescription.trim() !== '') {
      setTimeframeDescription(newDescription);
    }
  }, [data]);

  return (
    <SettingsBox>
      {metadata && (
        <>
          <SettingsSelector>
            <ObjectiveSelector />
            <MetricSelector />
            <TimeframeTabs changeDescription={setTimeframeDescription} />
            <ComparisonTimeFrameSelector />
          </SettingsSelector>
          {isDataLoading || !timeframeDescription ? (
            <DescriptionSkeleton animation="wave" />
          ) : (
            <DateTextDescription>{timeframeDescription}</DateTextDescription>
          )}
        </>
      )}
    </SettingsBox>
  );
};
